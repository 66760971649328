import { Outlet, BrowserRouter, Route, Routes,Navigate } from "react-router-dom";
import SideNav from "../components/sidebar/Sidebar";
import SignIn from "../pages/SignIn";
import {PageRoute} from "../routes/pageroute";
import { useEffect, useState } from "react";
import './Layout.css'
import Settings from "../pages/settings";
import { useDispatch, useSelector } from "react-redux";

import NotFound from "../pages/error/NotFound";
import PreLoading from "../components/loading/PreLoading";
import companyService from "../service/api/companyService";
import { addStateCode } from "../redux/reducer/company";

function Layout() {    
    
    return (
        <Routes>
            <Route path="/" element={<AuthService />}>
                <Route path="" element={<SignIn />} />
            </Route>
            
            <Route path="/" element={<InnerLayout />}>
                {
                    PageRoute.map((item) => (
                        <Route key={item.path} path={item.path} element={item.Component} />                        
                    ))
                }
                <Route key="Settings" path="settings" element={<Settings/>} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    )

}

function AuthService() {
    const {isLogged}=useSelector((state)=>state.user)

    if(isLogged){
        return <Navigate to='/dashboard' />
    }
    return (
        <div>
            <Outlet />
        </div>
    )
}

function InnerLayout() {
    const [isReady, setIsReady] = useState(false);
    const {isLogged}=useSelector((state)=>state.user);
    const dispatch = useDispatch();
    const [size,setSize] = useState(true)
    const sizeChange = (size)=>{
        setSize(size)
    }

    const getCompany = async () => {            
        return companyService.get().then((res) => {                 
            dispatch(addStateCode(res.data.state.code))   
            setIsReady(true);           
        }).catch((error)=>{
            if(error.response.status===404){               
                // Do Something here
            }
        })
    }

    useEffect(() => {
        getCompany();

        // setIsReady(true)
        // const timer = setTimeout(() => {
         
        // }, 5000);
        // return () => clearTimeout(timer);
      }, []);

    if(!isLogged){
        return <Navigate to='/' />
    }

    return (
        <div>      
            {
                isReady ? (
                    <div>
                        <div className="sidbar">
                            <SideNav sizeChange={sizeChange}  />
                        </div>
                        <div style={{ marginTop:95,paddingRight:25 }} className={size?"contentss":"contents"}>
                            <Outlet />
                        </div>
                    </div>
                ) : (
                    <PreLoading/>
                )
            }   
           
        </div>
    )
}

export default Layout