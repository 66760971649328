import { configureStore } from "@reduxjs/toolkit";

//persistance
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import { reducer } from "./index";
import appConfig from "../../config/appConfig";

const store = configureStore({
  reducer,
  devTools: appConfig.reduxDebug,
});

const purgeAllData = async () => {
  await persistor.purge();
};

const persistor = persistStore(store);

export { store, persistor, storage ,purgeAllData };
