import { Avatar, Box, Button, CardMedia, CircularProgress, Grid, Link, TextField } from "@mui/material";
import TextBox from "../../components/ui/form/TextBox";
import { useForm } from "react-hook-form";
import companyService from "../../service/api/companyService";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import NamedAutoComplete from "../../components/ui/form/select/NamedAutoComplete";
import locationService from "../../service/api/locationService";
import SwitchLabeled from "../../components/ui/form/checkbox/SwitchLabeled";
import ImageCropper from "../../components/common/ImageCropper";
import Model from "../../components/model/Model";
export default function Company(){
    const { register, handleSubmit, resetField, setValue, reset, watch, control, formState: { errors, isSubmitting, isDirty } } = useForm();
    const [logo, setLogo] = useState("");
    const [sign, setSign] = useState("");
    const [cropperImage, setCropperImage] = useState("");    
    const [isLoading, setIsLoading] = useState(true);
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [openLogoCropper, setOpenLogoCropper] = useState(false)
    const [openSignCropper, setOpenSignCropper] = useState(false)
    const checkEnableGst = watch("enableGST", false)

    const getAllCountry = () => {
        locationService.getAllCountry().then((res) => {
          setCountryData(res.data);
        });
      };
    
      const getStateByCountry = (id) => {
        locationService.getStateByCountry(id).then((res) => {
          setStateData(res.data);
        });
      };
    
      const getDistrictByState = (id) => {
        locationService.getDistrictByState(id).then((res) => {
          setDistrictData(res.data);
        });
      };
    
      const handleStateOnChange = (value) => {
        if (value) {
          setDistrictData([]);
          getDistrictByState(value?.id);
        }
      };

    const getCompany = async () => {            
       return companyService.get().then((res) => {          
            reset(res.data)
            setLogo(res.data.logo)
            setSign(res.data.signature)
            getStateByCountry(res.data.country.id)
            getDistrictByState(res.data.state.id)
            setValue(`countryId`,res.data.country.id)            
            setValue(`stateId`,res.data.state.id)
            setValue(`districtId`,res.data.district.id)
            setIsLoading(false)
        }).catch((error)=>{
            if(error.response.status===404){               
                // setBrandData(defaultData);
            }
            // setIsLoading(false)
        })
    }

    const editCompany = async (data) => {
        return companyService.edit(data).then((res) => {
            enqueueSnackbar("Setting Updated Successfully",{variant:'success'})            
            getCompany()
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Something went Wrong",{variant:'warning'});
            }
        })
    }

    const onSubmit = async data => {       
        if(data['id']){
            await editCompany({...data})
        }    
    };

    const handleCloseModel = () =>{
        setOpenSignCropper(false)
        setOpenLogoCropper(false)
    }

    const handelOnChangeLogo = (e) =>{       
        setOpenLogoCropper(true);
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (e) {
                setCropperImage(reader.result);
            };
        }
    }

    const handelOnChangeSign = async (e) =>{
        setOpenSignCropper(true);
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (e) {
                setCropperImage(reader.result);
            };
        }
    }

    const handleSetLogo = (data) => {
        setLogo(data)
        setValue('logo',data)
    }

    const handleSetSign = (data) => {
        setSign(data)
        setValue('signature',data)
    }

    useEffect(()=>{
        register('logo');
        register('signature');
        getCompany();
        getAllCountry();
    },[])

    return (
        <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                <div>
                    <h4 style={{margin:0}}>Company</h4>
                    <span style={{fontSize:14,marginBottom:2}}>Update your company information here</span> 
                </div>
                <div style={{alignSelf:'center'}}>
                    <Button variant="contained" disabled={isSubmitting || !isDirty} onClick={handleSubmit(onSubmit)} size="small" > Save </Button>
                </div>
            </Box>
        </Box>
        {/* Below Progress render based on the isLoading condition */}
        {
            isLoading ? (
                <Box sx={{ display: 'flex', justifyContent:'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <form>
                    <Box sx={{ borderBottom: 1, py: 2, borderColor: 'divider', display:'flex' }}>
                        <p style={{margin:0, width:'30%'}}>Company Name</p>
                        <TextBox error={errors['name']} required={true} errorMessage="Enter company name" control={control}  size='small' name='name' />
                    </Box>
                    <Box sx={{ borderBottom: 1, py: 2, borderColor: 'divider', display:'flex' }}>
                        <p style={{margin:0, width:'30%'}}>Company Logo</p>
                        <img  alt="companyLogo" src={logo} style={{ width: 120, height: 120 }}/>
                        {/* <img  alt="companyLogo" src={`data:image/png;base64, ${logo}`} style={{ width: 120, height: 120 }}/> */}
                        <div style={{marginLeft:'20px', alignSelf:'center'}}>
                            <Link variant="contained" component="label">Change Logo <input hidden type="file" onInput={handelOnChangeLogo} title="Change Logo" /></Link>
                        </div>
                    </Box>
                    <Box sx={{ borderBottom: 1, py: 2, borderColor: 'divider', display:'flex' }}>
                        <p style={{margin:0, width:'30%'}}>GST</p>
                        <SwitchLabeled label='Enable GST' control={control} name="enableGST" />
                        <TextBox error={errors['gstno']} disabled={!checkEnableGst} required={checkEnableGst} errorMessage="Enter the GST number" control={control}  size='small' name='gstno' />
                    </Box>
                    <Box sx={{ borderBottom: 1, py: 2, borderColor: 'divider'}}>
                        <div style={{display:'flex'}}>
                            <p style={{margin:0, width:'30%'}}>Address</p>
                            <div style={{width:'70%'}}>
                                <TextBox  rows={5} required={true} errorMessage="Enter the address" error={errors['address']} multiline={true} control={control}  size='small' name='address' />
                                <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <TextBox error={errors['pincode']} label={'Pincode'} required={true} errorMessage="Enter the pincode" control={control}  size='small' name='pincode' />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <NamedAutoComplete
                                            control={control}
                                            required={true}
                                            error={errors["countryId"]}
                                            options={countryData}
                                            size="small"
                                            label="Country"
                                            name={`countryId`}
                                            errorMessage="Select a country"
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <NamedAutoComplete
                                            control={control}
                                            required={true}
                                            error={errors["stateId"]}
                                            defaultValue={null}
                                            options={stateData}
                                            size="small"
                                            label="State"
                                            name={`stateId`}
                                            handelOnChange={handleStateOnChange}
                                            errorMessage="Select a state"
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <NamedAutoComplete
                                            control={control}
                                            defaultValue={null}
                                            required={true}
                                            error={errors["districtId"]}
                                            options={districtData}
                                            size="small"
                                            label="District"
                                            name={`districtId`}
                                            errorMessage="Select a district"
                                            />
                                        </Grid>  
                                </Grid>
                            </div>
                        </div>           
                    </Box>
                    <Box sx={{ borderBottom: 1, py: 2, borderColor: 'divider'}}> 
                    <div style={{display:'flex'}}>
                            <p style={{margin:0, width:'30%'}}>Contact</p>                        
                                                    
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <TextBox error={errors['mobile']} label={'Mobile'} control={control}  size='small' name='mobile' />
                            </Grid>
                            <Grid item xs={3}>
                                <TextBox error={errors['phone']} label={'Phone'} control={control}  size='small' name='phone' />
                            </Grid>
                            <Grid item xs={3}>
                                <TextBox error={errors['email']} label={'Email'} control={control}  size='small' name='email' />
                            </Grid>
                            <Grid item xs={3}>
                                <TextBox error={errors['website']} label={'Website'} control={control}  size='small' name='website' />
                            </Grid>
                        </Grid>
                        </div> 
                    </Box>
                    <Box sx={{ borderBottom: 1, py: 2, borderColor: 'divider'}}> 
                    <div style={{display:'flex'}}>
                            <p style={{margin:0, width:'30%'}}>Bank Details</p>                        
                                                    
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <TextBox error={errors['backName']} label={'Back Name'} control={control}  size='small' name='backName' />
                            </Grid>
                            <Grid item xs={3}>
                                <TextBox error={errors['bankBranch']} label={'Bank Branch'} control={control}  size='small' name='bankBranch' />
                            </Grid>
                            <Grid item xs={3}>
                                <TextBox error={errors['backAccountNo']} label={'Account No'} control={control}  size='small' name='backAccountNo' />
                            </Grid>
                            <Grid item xs={3}>
                                <TextBox error={errors['ifscCode']} label={'IFSC Code'} control={control}  size='small' name='ifscCode' />
                            </Grid>
                            <Grid item xs={3}>
                                <TextBox error={errors['companyPanNo']} label='Company Pan No (Optional)' control={control}  size='small' name='companyPanNo' />
                            </Grid>
                        </Grid>
                        </div> 
                    </Box>
                    <Box sx={{ borderBottom: 1, py: 2, borderColor: 'divider', display:'flex' }}>
                    <p style={{margin:0, width:'30%'}}>Terms & Condition</p>
                            <TextBox  rows={5} required={true} errorMessage="Write some terms & condition" error={errors['terms']} multiline={true} control={control}  size='small' name='terms' />
                    </Box>
                    <Box sx={{ borderBottom: 1, py: 2, borderColor: 'divider', display:'flex' }}>
                        <p style={{margin:0, width:'30%'}}>Owner Signature</p>
                        <img  alt="owner-signature" src={sign} style={{ width: 180, height: 80 }}/>
                        {/* <img  alt="owner-signature" src={`data:image/png;base64, ${sign}`} style={{ width: 180, height: 80 }}/> */}
                        <div style={{marginLeft:'20px', alignSelf:'center'}}>
                            <Link variant="contained" component="label">Change Sign <input hidden type="file" onInput={handelOnChangeSign} /></Link>
                        </div>
                    </Box>
                </form>
            )
        }  
        <Model title={"Update Logo"} open={openLogoCropper} close={handleCloseModel}>
            <ImageCropper photoURL={cropperImage} setBase64={handleSetLogo} aspect={1} handleClose={handleCloseModel}/>
        </Model>
        <Model title={"Update Signature"} open={openSignCropper} close={handleCloseModel}>
            <ImageCropper photoURL={cropperImage} setBase64={handleSetSign} aspect={25/9} handleClose={handleCloseModel}/>
        </Model>
        </>
    )
}