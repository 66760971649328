import Chart from 'react-apexcharts'
import { Card } from '@mui/material'

function ApexChart(props) {
    return (
        <Card sx={{ padding: 2 }}>
            <Chart options={props.options} series={props.series} type={props.type} height={500}/>
        </Card>
    )
}

export default ApexChart