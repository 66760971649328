import Datatable from "../../components/datatable/Datatable"
import { Box } from "@mui/system"
import { useEffect, useRef, useState } from "react";
import purchaseService from "../../service/api/purchaseService";
import Action from "../../components/datatable/Action";
import Model from "../../components/model/Model";
import { useSnackbar } from "notistack";
import FullScreenDialog from "../../components/model/FullScreenModel";
import PurchaseForm from "../../components/forms/purchase/PurchaseForm";
import { Chip } from "@mui/material";

export default function ListPurchase(){
    const defaultData = { "data": [],  "pageNumber": 0, "pageSize": 0, "totalPages": 0, "totalElements": 0 };
    const [purchaseData, setPurchaseData] = useState(defaultData)
    const [openModel, setOpenModel] = useState(false)
    const [modelTitle, setModelTitle] = useState('')
    const [row, setRow] = useState({})
    const [gridLoading, setGridLoading]  = useState(false);
    const currentPage = useRef(1);
    const currentRows = useRef(5);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', headerName: 'ID', width:"60" },
        {
            field: 'invoiceNo',
            headerName: 'Invoice No',
            editable: false,
            width:"130"
        },
        {
            field: 'invoiceDate',
            headerName: 'Date',
            editable: false,
            type: 'dateTime',
            width:"180",
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'supplier',
            headerName: 'Supplier',
            valueFormatter: ({ value }) => value.companyName,
            editable: false,
            width:"130"
        },
        
        {
            field: 'grandTotal',
            headerName: 'Grand Total',
            editable: false,
        },
        {
            field: 'discountAmount',
            headerName: 'Discount',
            editable: false,
        },
        {
            field: 'paymentStatus',
            headerName: 'Payment',
            editable: false,
            renderCell:({value}) => <Chip size="small" label={value} color={value === 'PAID'?'success':'error'} />
        },
        {
            field: 'amountPaid',
            headerName: 'Paid',
            editable: false,
        },
        {
            field: 'amountPending',
            headerName: 'Pending',
            editable: false,
        },
        {
            field: 'Action',
            headerName: 'Action',
            editable: false,
            renderCell: (params) => (
               <Action handleEdit={handleOpenEditModel} handleDelete={deletePurchase} row={params.row} ></Action>
            )
        }
    ];   

    const getAllPageable = async (page, size) => {  
        setGridLoading(true);      
        return purchaseService.getAllPageable(page, size).then((res) => {
            setGridLoading(false);
            setPurchaseData(res.data) 
        }).catch((error)=>{
            if(error.response.status===404){
                setGridLoading(false);
                setPurchaseData(defaultData);
            }
        })
    }

    const addPurchase = async (data) => {
        return purchaseService.create(data).then((res) => {
            enqueueSnackbar("New Purchase Added",{variant:'success'})
            handleCloseModel();
            currentPage.current=1            
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Purchase Already Exist",{variant:'warning'});
                handleCloseModel();
            }
        })
    }
    
    const updatePurchase = async (data) => {
        return purchaseService.update(data).then((res) => {
            enqueueSnackbar("Purchase Updated",{variant:'success'})
            handleCloseModel();
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'}); 
                currentPage.current=1           
                getAllPageable(currentPage.current,currentRows.current);
            }else if(error.response.status === 409){
                enqueueSnackbar("Purchase Already Exist",{variant:'warning'});
                handleCloseModel();
            }
            
        })
    }

    const deletePurchase = async (data) =>{
        return purchaseService.delete(data.id).then((res)=>{            
            getAllPageable(currentPage.current,currentRows.current);
            enqueueSnackbar("Purchase Deleted",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){
                setPurchaseData(defaultData);
            }else if(error.response.status === 409){
                enqueueSnackbar("Couldn't Delete Purchase",{variant:'warning'});                
            }
        })
    }

    const handleOpenAddModel = () =>{
        setModelTitle('Add Purchase')
        setOpenModel(true)
    }

    const handleOpenEditModel = (data) =>{
        setModelTitle('Edit Purchase')
        setOpenModel(true)
        setRow(data)
    }

    const handleCloseModel = () =>{
        setOpenModel(false);
        setRow({});
    }
   
    useEffect(() => {
        getAllPageable(currentPage.current,currentRows.current)
    }, [])

    return (
        <div>            
            <Box>
                <Datatable rows={purchaseData} columns={columns} loading={gridLoading} options={{"title":"Purchase", "handleAddButtonClick":handleOpenAddModel,"currentpage":currentPage,"currentRows":currentRows}} getApi={getAllPageable} />
            </Box>            
            <FullScreenDialog title={modelTitle} open={openModel} close={handleCloseModel}>
                    <PurchaseForm handleAdd={addPurchase} handleUpdate={updatePurchase} row={row}></PurchaseForm>
            </FullScreenDialog>

        </div>
    )
}