
import Datatable from "../../components/datatable/Datatable"
import { Box } from "@mui/system"
import { useEffect, useRef, useState } from "react";
import supplierService from "../../service/api/supplierService";
import Action from "../../components/datatable/Action";
import Model from "../../components/model/Model";
import { useSnackbar } from "notistack";
import FullScreenDialog from "../../components/model/FullScreenModel";
import SupplierForm from "../../components/forms/SupplierForm";


export default function ListSupplier() {
    const defaultData = { "data": [],  "pageNumber": 0, "pageSize": 0, "totalPages": 0, "totalElements": 0 };
    const [supplierData, setSupplierData] = useState(defaultData)
    const [openModel, setOpenModel] = useState(false)
    const [modelTitle, setModelTitle] = useState('')
    const [row, setRow] = useState({})
    const [gridLoading, setGridLoading]  = useState(false);
    const currentPage = useRef(1);
    const currentRows = useRef(5);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', headerName: 'ID',width:"60" },
        {
            field: 'companyName',
            headerName: 'Company',
            editable: false,
            width:"130"
        },
        {
            field: 'email',
            headerName: 'Email',
            editable: false,
            width:"150"
        },
        {
            field: 'mobile',
            headerName: 'Mobile',
            editable: false,
            width:"130"
        },
        {
            field: 'phone',
            headerName: 'Phone',
            editable: false,
            width:"130"
        },
        {
            field: 'gstNo',
            headerName: 'GST No',
            editable: false,
            width:"150"
        },
        {
            field: 'Action',
            headerName: 'Action',
            editable: false,
            renderCell: (params) => (
               <Action handleEdit={handleOpenEditModel} handleDelete={deleteSupplier} row={params.row} ></Action>
            )
        }
    ];   

    const getAllPageable = async (page, size) => { 
        setGridLoading(true);       
        return supplierService.getAllPageable(page, size).then((res) => {
            setGridLoading(false);
            setSupplierData(res.data) 
        }).catch((error)=>{
            if(error.response.status===404){
                setGridLoading(false);
                setSupplierData(defaultData);
            }
        })
    }

    const addSupplier = async (data) => {
        return supplierService.create(data).then((res) => {
            enqueueSnackbar("New Supplier Added",{variant:'success'})
            handleCloseModel();
            currentPage.current=1            
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Supplier Already Exist",{variant:'warning'});
                handleCloseModel();
            }
        })
    }
    
    const updateSupplier = async (data) => {
        return supplierService.update(data).then((res) => {
            enqueueSnackbar("Supplier Updated",{variant:'success'})
            handleCloseModel();
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'}); 
                currentPage.current=1           
                getAllPageable(currentPage.current,currentRows.current);
            }else if(error.response.status === 409){
                enqueueSnackbar("Supplier Already Exist",{variant:'warning'});
                handleCloseModel();
            }
            
        })
    }

    const deleteSupplier = async (data) =>{
        return supplierService.delete(data.id).then((res)=>{            
            getAllPageable(currentPage.current,currentRows.current);
            enqueueSnackbar("Supplier Deleted",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){
                setSupplierData(defaultData);
            }else if(error.response.status === 409){
                enqueueSnackbar("Couldn't Delete Supplier",{variant:'warning'});                
            }
        })
    }

    const handleOpenAddModel = () =>{
        setModelTitle('Add Supplier')
        setOpenModel(true)
    }

    const handleOpenEditModel = (data) =>{
        setModelTitle('Edit Supplier')
        setOpenModel(true)
        setRow(data)
    }

    const handleCloseModel = () =>{
        setOpenModel(false);
        setRow({});
    }
   
    useEffect(() => {
        getAllPageable(currentPage.current,currentRows.current)
    }, [])

    return (
        <div>            
            <Box>
                <Datatable rows={supplierData} columns={columns} loading={gridLoading} options={{"title":"Supplier", "handleAddButtonClick":handleOpenAddModel,"currentpage":currentPage,"currentRows":currentRows}} getApi={getAllPageable} />
            </Box>            
            <FullScreenDialog title={modelTitle} open={openModel} close={handleCloseModel}>
                    <SupplierForm handleAdd={addSupplier} handleUpdate={updateSupplier} row={row}></SupplierForm>
            </FullScreenDialog>

        </div>
    )
}