import { auto } from "@popperjs/core"
import image from "./../../assets/svg/404.svg"

export default function NotFound() {
    return(
        <div style={{display:'flex', alignContent:'center',height:'80vh'}}>
            <div style={{ margin:'auto', width:'80vh'}}>
            <img style={{width:'90%', display:'block', margin:auto}} src={image} alt="React Logo" />
            <h1 style={{'text-align': 'center'}}>Page Not Found</h1>
            </div>
        </div>
    )
}

