import moment from "moment-timezone";
import { useEffect, useState } from "react";

export default function Clock(){

    const [time, setTime] = useState(moment().format('MMMM Do YYYY, h:mm A'));

    useEffect(()=>{
        const timer = setInterval(()=>{
            setTime(moment().format('MMMM Do YYYY, h:mm A'))
        },2000)
        
        return() =>{
            clearInterval(timer)
        }
    },[])

    return(
        <span style={{color:'gray', marginRight:5}}>{time}</span>
    )
}