import axios from '../../config/axiosConfig'

class saleService {

    getAllPageable(page,size) {
        return axios.get(`/v1/pageable/sale?page=${page}&size=${size}`);
    }    

    create(data) {
        return axios.post('/v1/sale', data);
    }

    createAndGetInvoice(data){
        return axios.post('/v1/sale/save-get-invoice',data,{responseType:'blob'})
    }

    update(data) {
        return axios.put(`/v1/sale/${data.id}`, data);
    }
    
    delete(id) {
        return axios.delete(`/v1/sale/${id}`);
    }
    
    getPdfInvoice(id) {
        return axios.get(`/v1/sale/get-pdf-invoice/${id}`,{responseType:'blob'});
    }
}

export default new saleService();