import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const Menus = {
    "Customers":{
        "color":"#10A5F9",
        "bgcolor":"#DDFAFF"
    },
    "Sales":{
        "color":"#FCBE35",
        "bgcolor":"#FFF4DC"
    },
    "Revanue":{
        "color":"#FA8A65",
        "bgcolor":"#FFF1E3"
    },
    "Stock":{
        "color":"#75BEAD",
        "bgcolor":"#E3F1F1"
    }
}
export default function Dashboardcard(props) {
    
    return (
        <Card >
            <CardHeader sx={{ paddingBottom: 0 }} subheader={props['name']} action={
                <Avatar aria-label="recipe" sx={{ background:(Menus[props['name']]?Menus[props['name']]['bgcolor']: "#DDFAFF"), color: (Menus[props['name']]?Menus[props['name']]['color']:"#10A5F9" )}}>
                    <ProductionQuantityLimitsIcon />
                </Avatar>
            }>

            </CardHeader>
            <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>

                <Typography variant="h6" component="div">
                    {props['Revanue']}
                </Typography>
            </CardContent>
            <CardActions>
                <TrendingUpIcon sx={{ fontSize: 16, marginLeft: 1, marginRight: 2 }} />
                <Typography sx={{ fontSize: 14 }} >
                    Since Last Month
                </Typography>
            </CardActions>
        </Card>
    );
}