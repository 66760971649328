import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { IconButton, List, ListItem, ListItemText, Menu, MenuItem, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedProductData, clearSelectedProductData, closeCreateBatchModel, setSelectedProductData, updateAdditionalCharges, updateIsIgst, updateShippingCharges } from '../../../redux/reducer/sale';
import paymentModeService from '../../../service/api/paymentModeService';
import customerService from '../../../service/api/customerService';
// import SaleSelectProduct from '../../function/SaleSelectProduct';
import DateTimePicker from '../../ui/form/datepicker/DateTimePicker';
import DecimalField from '../../ui/form/number/DecimalField';
import NamedAutoComplete from '../../ui/form/select/NamedAutoComplete';
import { useSnackbar } from 'notistack';
import FullScreenDialog from '../../model/FullScreenModel';
import ProductForm from '../product/ProductForm';
import productService from '../../../service/api/productService';
import ProductBatchForm from '../product/ProductBatchForm';
import Model from '../../model/Model';
import productBatchService from '../../../service/api/productBatchService';
import SaleSelectProduct from '../../function/SaleSelectProduct';
import CustomerForm from '../customer/CustomerForm';
import { Padding } from '@mui/icons-material';
import userEvent from '@testing-library/user-event';
import moment from 'moment-timezone';
import printUtil from '../../../util/printUtil';


export default function SaleForm({handleAdd, handleUpdate, row, handleSaveAndPrint}) {  
  const {selectedProductData, saleData} = useSelector((state)=>state.sale);
  const { enqueueSnackbar } = useSnackbar();  
  const [openCreateProductModel, setOpenCreateProductModel] = useState(false)
  const [openCreateCustomerModel, setOpenCreateCustomerModel] = useState(false)

  const dispatch = useDispatch(); 
  const { register, handleSubmit, reset, resetField,control, setError, clearErrors, setValue, formState: { errors, isSubmitting } } = useForm({
    defaultValues:{"invoiceDate":moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ').toString(),"shipping":0.0,"additionalCharges":0.0, "discount":0.0,"discountAmount":0.0,"roundOff":0.0}
  });

  register("billingAddressId")
  register("shippingAddressId")
  
  const [customerData, setCustomerData] = useState([]);
  const [paymentModeData, setPaymentModeData] = useState([]);
  const [address, setAddress] = useState([]);

  const handelResetField = () => {
    reset()
    setAddress([])
    setValue("payments[0].paymentModeId",1)
    dispatch(clearSelectedProductData())
  };   

  const onSubmit = async data => { 
    data = {...data, ...saleData}
    data.saleItems = selectedProductData    
    if(row['id']){
        await handleUpdate({...data,id:row.id})
        handelResetField();
    }else{
        await handleAdd(data)
        handelResetField();
    }       
  };

  const onSubmitAndPrint = async data => { 
    data = {...data, ...saleData}
    data.saleItems = selectedProductData    
    if(row['id']){
        await handleUpdate({...data,id:row.id})
        handelResetField();
    }else{
        await handleSaveAndPrint(data)
        handelResetField();
    }       
  };

    const getAllCustomer = ()=>{
        customerService.getAllForDropdown().then(res=>{
            setCustomerData(res.data)
        })
    }

    const getAllPaymentMode = () =>{
      paymentModeService.getAll().then(res=>{
        setPaymentModeData(res.data)        
      })
    }

    const addCustomer = async (data) => {
      return customerService.create(data).then((res) => {
          enqueueSnackbar("New Customer Added",{variant:'success'})
          handleCloseCreateCustomerModel();      
      }).catch((error)=>{
          if(error.response.status===409){
              enqueueSnackbar("Customer Already Exist",{variant:'warning'});
              handleCloseCreateCustomerModel();
          }
      })
  }  

  const handleCloseCreateProductModel = () =>{
    setOpenCreateProductModel(false)
  }

  const handleOpenCreateProductModel = () =>{
    setOpenCreateProductModel(true)
  }

  const addProduct = async (data) => {
    return productService.create(data).then((res) => {
        enqueueSnackbar("New Product Added",{variant:'success'})
        handleCloseCreateProductModel();                
        
    }).catch((error)=>{
        if(error.response.status===409){
            enqueueSnackbar("Product Already Exist",{variant:'warning'});
            handleCloseCreateProductModel();
        }
    })
}

  const handleCloseCreateCustomerModel = () =>{
    setOpenCreateCustomerModel(false)
  } 

  const getAddressByCustomerId = async (data) =>{
    if(data){
      return customerService.getAddress(data.id).then((res)=>{
        setAddress(res.data)
        setValue("shippingAddressId",res.data[0].id)
        setValue("billingAddressId",res.data[0].id)
      })
    }else{    
      setAddress([])
      setValue("shippingAddressId","")
      setValue("billingAddressId","")
    }    
  }

  const addProductBatch= async (data)=>{
    return productBatchService.create(data).then(res=>{
        enqueueSnackbar("New Batch Added",{variant:'success'})
        dispatch(closeCreateBatchModel())                
    }).catch((error)=>{
        if(error.response.status===409){
            enqueueSnackbar("Batch Already Exist",{variant:'warning'});
            dispatch(closeCreateBatchModel())
        }
    })
  }

    useEffect(()=>{ 
        getAllCustomer();
        getAllPaymentMode();
        if(row['id']){
          setValue("customerId",row.customer.id)
          setValue("invoiceNo",row.invoiceNo)
          getAddressByCustomerId({id:row.customer.id})
          setValue("shippingAddressId",row?.shippingAddress?.id)
          setValue("billingAddressId",row?.billingAddress?.id)
          setValue("invoiceDate",row.invoiceDate)
          
          dispatch(setSelectedProductData(row.saleItems))
          
          setValue("additionalCharges",row.additionalCharges)
          dispatch(updateAdditionalCharges(row.additionalCharges))

          setValue("shipping",row.shipping)
          dispatch(updateShippingCharges(row.shipping))

          setValue("payments[0].paymentModeId",row.payments[0].paymentMode.id)
          setValue("payments[0].amount",row.payments[0].amount)
        }else{
          handelResetField();
        }
    },[row])

    useEffect(()=>{
      // setValue("roundOff", saleData.roundOff)
      setValue("payments[0].amount",saleData.grandTotal)
    },[saleData.grandTotal])    

    return (
      <>
      <div>        
        <form>
          <Grid container spacing={2} padding={1}>
              <Grid item xs={3}>
                <TextField
                  type='text'                
                  InputLabelProps={{ shrink: true }}    
                  placeholder="Auto"   
                  disabled        
                  {...register("invoiceNo")}
                  sx={{ width: "100%" }}
                  label="Invoice No"
                  size="small"
                  name="invoiceNo"
                />
              </Grid>
              <Grid item xs={3}>
                <DateTimePicker
                  name="invoiceDate"
                  label="Invoice Date"
                  errorMessage={"Select a date"}
                  required={true}
                  error={errors["invoiceDate"]}
                  control={control}
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                {/* Empty Grid for move customer auto complete to next row, find better way to do this */}
              </Grid>
            <Grid item xs={3}>
              <div style={{display:'flex'}}>
              <NamedAutoComplete
                control={control}
                required={true}
                error={errors["customerId"]}
                errorMessage={"Select a customer"}
                options={customerData}
                size="small"
                label="Customer"
                name={`customerId`}
                handelOnChange={(e)=>{                  
                  getAddressByCustomerId(e)                                  
                }}
                actionButton={{
                  name : "New Customer",
                  icon : <PersonAddAltOutlinedIcon />,
                  onClick : ()=>{setOpenCreateCustomerModel(true)}
                }}   
              />              
              </div>
            </Grid>   
            <Grid item xs={4}>
              <AddressMenu label={"Billing Address"} id="billing-address" name="billingAddressId" defaultValue={row?.billingAddress?.id} options={address} setValue={setValue} />
              {/* Billing Address */}
            </Grid>
            <Grid item xs={4}>
            <AddressMenu label={"Shipping Address"} id="shipping-address" name={"shippingAddressId"} defaultValue={row?.shippingAddress?.id} options={address} setValue={setValue}/>
              {/* Shipping Address */}
            </Grid>
            
            <Grid item xs={12}>
              <SaleSelectProduct handelOpenModel={handleOpenCreateProductModel} />
            </Grid>
          </Grid>
          
          <Grid container spacing={2} padding={1}>
            <Grid item container xs={6} spacing={2} padding={1}>
              <Grid item xs={3}>
              <DecimalField
                  control={control}
                  name="shipping"
                  label="Shipping"
                  size="small"
                  error={errors["shipping"]}
                  onBlur={(e)=>{
                    if(e.target.value === ""){
                      setValue("shipping",0.00)
                      dispatch(updateShippingCharges(parseFloat(0.00)))
                    }else{                      
                      dispatch(updateShippingCharges(parseFloat(e.target.value)))
                    }                    
                  }}
                />               
              </Grid>

              <Grid item xs={3}>
              <DecimalField
                  control={control}
                  name="additionalCharges"
                  label="Additional Charges"
                  size="small"
                  error={errors["additionalCharges"]}
                  onBlur={(e)=>{
                    if(e.target.value === ""){
                      setValue("additionalCharges",0.00)
                      dispatch(updateAdditionalCharges(parseFloat(0.00)))
                    }else{                      
                      dispatch(updateAdditionalCharges(parseFloat(e.target.value)))
                    }
                  }}
                />                    
              </Grid>

              {/* <Grid item xs={3}>
              <DecimalField
                  
                  name="discount"
                  label="Discount (%)"
                  size="small"
                  error={errors["discount"]}                  
                />                 
              </Grid>

              <Grid item xs={3}>
              <DecimalField
                
                  name="discountAmount"
                  label="Discount Amount"
                  size="small"
                  error={errors["discountAmount"]}
                  onBlur={(e)=>{
                    // dispatch(updateAdditionalCharges(parseFloat(e.target.value)))
                  }}
                />                 
              </Grid> */}

              {/* <Grid item xs={3}>
                <DecimalField
                  control={control}
                  name="roundOff"
                  label="Round Off"
                  size="small"
                  error={errors["roundOff"]}
                  onBlur={(e)=>{
                    dispatch(updateRoundOff(parseFloat(e.target.value)))
                  }}
                />                
              </Grid> */}

              <Grid item xs={3}>
                <NamedAutoComplete
                  control={control}
                  required={true}
                  error={errors["payments"]?.[0]?.paymentModeId}
                  options={paymentModeData}
                  size="small"
                  label="Payment Mode"
                  name={`payments[0].paymentModeId`}
                />
              </Grid>

              <Grid item xs={3}>
                <DecimalField
                  control={control}
                  required={true}
                  name="payments[0].amount"
                  label="Amount"
                  size="small"
                  error={errors["payments"]?.[0]?.amount}   
                  errorMessage={"Provide Paid Amount"}    
                />   
                
              </Grid>
             
            </Grid>

            <Grid item xs={6} sx={{ display:"flex", justifyContent: "end" }}>
              {/* <Fieldset title="Total Amount" style={{ minWidth: 400 }}>
                <Typography
                  sx={{ fontSize: 55, textAlign: "center" }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b> Rs. {saleData.grandTotal} </b>
                </Typography>
              </Fieldset> */}
              <Typography color="text.secondary" width={200} gutterBottom>
                CGST: &emsp; <b>₹{saleData.cgst}</b>
                <br />
                SGST: &emsp; <b>₹{saleData.sgst}</b>
                <br />
                IGST: &emsp; <b>₹{saleData.igst}</b>
                <br />
                Total GST: <b>&emsp; ₹{saleData.gstAmount}</b>
              </Typography>
          
              <Typography color="text.secondary" width={200} gutterBottom>
                {/* Subtotal : &emsp; <b>₹{saleData.grandTotal} </b>
                <br /> */}
                Discount : &emsp; <b>₹{saleData.discountAmount} </b>
                <br />
                Round Off : &emsp; <b>₹{saleData.roundOff} </b>
                <br />
                <span >Grand Total : &emsp;<b>₹{saleData.grandTotal}</b></span>
              </Typography>
            </Grid>
          </Grid>        
        </form>
        <Grid container sx={{ justifyContent: "end" }} spacing={2} padding={2 }>
        { !row["id"] && <Grid item>
              <Button
                variant="contained"
                disabled={isSubmitting}
                type="submit"
                size="small"
                onClick={handleSubmit(onSubmitAndPrint)}
              >
                Submit & Print
              </Button> 
              </Grid>}
              <Grid item>
              <Button
                variant="contained"
                disabled={isSubmitting}
                onClick={handleSubmit(onSubmit)}
                type="submit"
                size="small"
              >
                {row["id"] ? "Edit" : "Submit"}
              </Button> 
              </Grid>             
                
          { !row["id"] && <Grid item>
                <Button                
                  variant="contained"
                  onClick={handelResetField}
                  type="submit"
                  size="small"
                >
                  Reset
                </Button>
              </Grid>}
              </Grid>   
      </div>
      <FullScreenDialog title="Create Customer" open={openCreateCustomerModel} close={handleCloseCreateCustomerModel}>
        <CustomerForm handleAdd={addCustomer}></CustomerForm>
      </FullScreenDialog>  
      </>      
    );
}

function AddressMenu({options,label,id, name, setValue, defaultValue}){
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {stateCode} = useSelector((state)=>state.company);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    if (options.length > 0) setAnchorEl(event.currentTarget);
  };

  useEffect(()=>{
    if(defaultValue) setSelectedIndex(options.findIndex((obj)=>{return obj.id === defaultValue}))    
    //This for auto update the IGST flag if user only choose the customer and leave billing address with the default one
    if(name === "billingAddressId" && options.length > 0){
      if(options[0].state.code != stateCode){
        dispatch(updateIsIgst(true));
      }else{
        dispatch(updateIsIgst(false));
      }
    }
    
  },[options])

  const handleMenuItemClick = (event, index,id) => {   
    setValue(name,id)
    setSelectedIndex(index);
    setAnchorEl(null);
    //This if condition enable and disable the IGST based on the billing address state code comparison with the company's address location 
    if(name === "billingAddressId" && options.length > 0){
      if(options[0].state.code != stateCode){
        dispatch(updateIsIgst(true));
      }else{
        dispatch(updateIsIgst(false));
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };  

  return (
    <div>
      <List
        component="nav"
        aria-label={label}
        sx={{ bgcolor: 'background.paper', padding:0, }}
      >
        <ListItem         
          button
          sx={{padding:0}}
          id={`${id}-button`}
          aria-haspopup="listbox"
          aria-controls={`${id}-menu`}
          aria-label={label}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText 
            sx={{margin:0}}
            primary={label}
            secondary={options[selectedIndex] ? options[selectedIndex]?.street + ", " + options[selectedIndex]?.village : ' '}
          />
        </ListItem>
      </List>
      <Menu 
        id={`${id}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `${id}-button`,
          role: 'listbox',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.id}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index,option.id)}
          >
            {option?.street && <>{option.street + ","}</>}
            {option?.village && <><br/>{option.village + ","}</>}
            {option?.landmark && <>{" (" + option.landmark + ")"}</>}
            {option?.district?.name && <><br/>{option?.district?.name + ", "}</>}
            {option?.state?.name && <>{option?.state?.name}</>}                     
            {option?.country?.name && <><br/>{option?.country?.name}</>}
            {option?.pincode && <>{" - " + option?.pincode}</>}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

