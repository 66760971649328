import axios from '../../config/axiosConfig'

class InvoiceNumberingService{   
    getAll(){
        return axios.get('/v1/invoice-numbering')
    }

    update(data){
        return axios.put(`/v1/invoice-numbering/${data.id}`, data)
    }
}

export default new InvoiceNumberingService();