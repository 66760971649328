import fileDownload from "js-file-download";
import { enqueueSnackbar } from "notistack";

const downloadFile = (file, fileName) => {
  fileDownload(file, fileName);
};

export const validateDates = (mfgDate, expiryDate) => {
  // Parse dates
  const mfgDateObj = new Date(mfgDate);
  const expiryDateObj = new Date(expiryDate);
  const currentDate = new Date();

  // Check for valid date formats
  if (isNaN(mfgDateObj) || isNaN(expiryDateObj)) {
    return false;
  }

  // Check for future manufacturing date and valid expiry date
//   if (mfgDateObj > currentDate || expiryDateObj <= currentDate) {
//     enqueueSnackbar("New Stock Added", { variant: "warning" });

//     return false;
//   }

  // Check manufacturing date not greater than expiry date
  if (mfgDateObj > expiryDateObj) {
    enqueueSnackbar("manufacturing date not greater than expiry date", {
      variant: "warning",
    });

    return false;
  }

  // Check expiry date not before manufacturing date
  if (expiryDateObj < mfgDateObj) {
    enqueueSnackbar(" expiry date not before manufacturing date", {
      variant: "warning",
    });

    return false;
  }

  return true;
};
export default { downloadFile, validateDates };
