import axios from '../../config/axiosConfig'

class purchaseService {

    getAllPageable(page,size) {
        return axios.get(`/v1/pageable/purchase?page=${page}&size=${size}`);
    }    

    create(data) {
        return axios.post('/v1/purchase', data);
    }

    update(data) {
        return axios.put(`/v1/purchase/${data.id}`, data);
    }
    
    delete(id) {
        return axios.delete(`/v1/purchase/${id}`);
    }
}

export default new purchaseService();