import { createSlice } from "@reduxjs/toolkit";
import { decimalControl, getTaxAmount } from "../../util/numericUtil";

const initialState = {
    isIgst: false,
    selectedProductData: [],
    saleData:{
        gstAmount : 0.00,
        cgst : 0.00,
        sgst : 0.00,
        igst : 0.00,
        taxableAmount: 0.00,        
        discount:0.00,
        discountAmount:0.00,
        roundOff:0.00,
        grandTotal: 0.00,
        shipping:0.00,
        additionalCharges:0.00,
    }
};

const calcSaleData = (state) => {
    const shippingGst = decimalControl(getTaxAmount("EXCLUSIVE",5,state.saleData.shipping + state.saleData.additionalCharges));    
    const gstAmount = decimalControl(state.selectedProductData.reduce((n, {gstAmount}) => n + gstAmount, 0) + shippingGst);
    const taxableAmount = decimalControl(state.selectedProductData.reduce((n, {taxableAmount}) => n + taxableAmount, 0) + state.saleData.shipping + state.saleData.additionalCharges);
    const grandTotal = gstAmount+taxableAmount;
    const discountAmount = decimalControl(state.selectedProductData.reduce((n, {discountAmount}) => n + discountAmount, 0));

    state.saleData.taxableAmount = taxableAmount
    state.saleData.gstAmount = gstAmount;
    state.saleData.discountAmount = discountAmount;
    state.saleData.grandTotal = decimalControl(Math.round(grandTotal));
    state.saleData.roundOff = decimalControl(grandTotal - Math.round(grandTotal));    
    
    if(state.isIgst){
        state.saleData.igst = gstAmount;
        state.saleData.cgst = 0.00;
        state.saleData.sgst = 0.00;
    }else{
        state.saleData.igst = 0.00;
        state.saleData.cgst = decimalControl(gstAmount / 2);
        state.saleData.sgst = decimalControl(gstAmount / 2);
    }   
}

const sale = createSlice({
    name: "sale",
    initialState,
    reducers: {
        addSelectedProductData: (state, action) => {
            state.selectedProductData.push(action.payload)
            state.saleData.grandTotal = action.payload.totalAmount
            calcSaleData(state)
        },
        
        setSelectedProductData:(state, action) =>{
            state.selectedProductData = action.payload
            state.selectedProductData.map((obj)=>{
                if(!obj.batchNumber){
                    obj.saleItemId = obj.id
                    obj.batchNumber = obj.batch.batchNumber;
                    obj.batchId = obj.batch.id;
                }
            })
            calcSaleData(state)
        },
        
        deleteSelectedProductData: (state, action) =>{
            state.selectedProductData = state.selectedProductData.filter((obj)=>obj.id !== action.payload)
            calcSaleData(state)
        },

        clearSelectedProductData: (state,action)=>{
            state.selectedProductData = [];
            state.saleData = initialState.saleData;
        },

        quantityUpdate:(state,action)=>{               
            const dataIndex = state.selectedProductData.findIndex((obj)=> obj.id == action.payload.id) 
            let data = state.selectedProductData[dataIndex]    
            data.quantity = action.payload.quantity
            const totalSalePrice = (data.salePrice * action.payload.quantity) - data.discountAmount
            const gstAmount = getTaxAmount(data.gstType, data.gst, totalSalePrice)
            data.gstAmount = decimalControl(gstAmount)    
            if(state.isIgst){
                data.igst = gstAmount;
            }else{
                data.cgst = decimalControl(gstAmount / 2);
                data.sgst = decimalControl(gstAmount / 2);
            } 
            data.taxableAmount = decimalControl(data.gstType === 'INCLUSIVE' ? (totalSalePrice - gstAmount) : totalSalePrice)
            data.totalAmount = decimalControl(data.gstType === 'INCLUSIVE' ? totalSalePrice  : (totalSalePrice + gstAmount)) 
            calcSaleData(state)
            
        },

        discountUpdate:(state,action)=>{
            const dataIndex = state.selectedProductData.findIndex((obj)=> obj.id == action.payload.id) 
            let data = state.selectedProductData[dataIndex]    
            data.discountAmount = action.payload.discount
            const totalSalePrice = (data.salePrice * data.quantity) - action.payload.discount
            const gstAmount = getTaxAmount(data.gstType, data.gst, totalSalePrice)
            data.gstAmount = decimalControl(gstAmount)      
            if(state.isIgst){
                data.igst = gstAmount;
            }else{
                data.cgst = decimalControl(gstAmount / 2);
                data.sgst = decimalControl(gstAmount / 2);
            }   
            data.taxableAmount = decimalControl(data.gstType === 'INCLUSIVE' ? (totalSalePrice - gstAmount) : totalSalePrice)
            data.totalAmount = decimalControl(data.gstType === 'INCLUSIVE' ? totalSalePrice  : (totalSalePrice + gstAmount))
            calcSaleData(state)
        },

        batchIdUpdate:(state,action)=>{
            const dataIndex = state.selectedProductData.findIndex((obj)=> obj.id == action.payload.id) 
            let data = state.selectedProductData[dataIndex]  
            data.batchId = action.payload.batchId;
        },

        updateShippingCharges:(state,action)=>{
            state.saleData.shipping = action.payload
            calcSaleData(state)
        },
        updateAdditionalCharges:(state,action)=>{
            state.saleData.additionalCharges = action.payload
            calcSaleData(state)
        },

        updateRoundOff:(state,action)=>{
            state.saleData.roundOff = action.payload
        },

        updateIsIgst : (state,action) =>{   
            if(!state.isIgst && action.payload){
                state.selectedProductData.forEach((obj)=>{
                    obj.igst = obj.gstAmount;
                    obj.sgst = 0.00;
                    obj.cgst = 0.00;
                    return obj;
                })                
            }else if(state.isIgst && !action.payload) {
                state.selectedProductData.forEach((obj)=>{
                    obj.igst = 0.00;
                    obj.sgst = decimalControl(obj.gstAmount/2);
                    obj.cgst = decimalControl(obj.gstAmount/2);
                    return obj;
                })
            }            
            state.isIgst = action.payload
            calcSaleData(state)
        }
    },
});

export const { addSelectedProductData,updateRoundOff,deleteSelectedProductData,clearSelectedProductData, quantityUpdate, discountUpdate, batchIdUpdate, updateShippingCharges,updateAdditionalCharges,openCreateBatchModel,closeCreateBatchModel,setSelectedProductData, updateIsIgst} = sale.actions;

export default sale.reducer;