import { Box, Divider, Typography } from "@mui/material";

export default function LeftTextDivider({children}){

    return(
       <Box>
            <Typography color="text.secondary" display="block"  fontSize={14} variant="caption">
                {children}
            </Typography>            
            <Divider />             
       </Box> 
    )
}