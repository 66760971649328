import { DataGrid } from "@mui/x-data-grid";

export default function ItemsDataTable(props){
    const {rows, columns} = props;
    
    return(
        <DataGrid
            sx={{height:'28vh'}}
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            showCellVerticalBorder
            showColumnVerticalBorder
            columns={columns}
            rows={rows}
        />
    )
}