import Datatable from "../../components/datatable/Datatable"
import { Box } from "@mui/system"
import { useEffect, useRef, useState } from "react";
import UOMForm from "../../components/forms/master/UOMForm";
import uomService from "../../service/api/uomService";
import Action from "../../components/datatable/Action";
import Model from "../../components/model/Model";
import { useSnackbar } from "notistack";
import { async } from "q";

export default function UOM() {
    const defaultData = { "data": [],  "pageNumber": 0, "pageSize": 0, "totalPages": 0, "totalElements": 0 };
    const [uomData, setUOMData] = useState(defaultData)
    const [openModel, setOpenModel] = useState(false)
    const [modelTitle, setModelTitle] = useState('')
    const [gridLoading, setGridLoading]  = useState(false);
    const [row, setRow] = useState({})
    const currentPage = useRef(1);
    const currentRows = useRef(5);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', headerName: 'ID',width:"70" },
        {
            field: 'name',
            headerName: 'Name',
            editable: false,
            width:"200"
        },
        {
            field: 'shortName',
            headerName: 'Short Name',
            editable: false,
            width:"150"
        },
        {
            field: 'Action',
            headerName: 'Action',
            editable: false,
            renderCell: (params) => (
               <Action handleEdit={handleOpenEditModel} handleDelete={deleteUOM} row={params.row} ></Action>
            )
        }
    ];   

    const getAllPageable = async (page, size) => {   
        setGridLoading(true);
        return uomService.getAllPageable(page, size).then((res) => {
            setGridLoading(false);
            setUOMData(res.data) 
        }).catch((error)=>{
            setGridLoading(false)
            if(error.response.status===404){                
                setUOMData(defaultData);
            }
        })
    }

    const addUOM = async (data) => {
        return uomService.create(data).then((res) => {
            enqueueSnackbar("New UOM Added",{variant:'success'})
            handleCloseModel();
            currentPage.current=1            
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("UOM Already Exist",{variant:'warning'});
                handleCloseModel();
            }
        })
    }
    
    const updateUOM = async (data) => {
        return uomService.update(data).then((res) => {
            enqueueSnackbar("UOM Updated",{variant:'success'})
            handleCloseModel();
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'}); 
                currentPage.current=1           
                getAllPageable(currentPage.current,currentRows.current);
            }else if(error.response.status === 409){
                enqueueSnackbar("UOM Already Exist",{variant:'warning'});
                handleCloseModel();
            }
            
        })
    }

    const deleteUOM =  async (data) =>{
        return uomService.delete(data.id).then((res)=>{            
            getAllPageable(currentPage.current,currentRows.current);
            enqueueSnackbar("UOM Deleted",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){
                setUOMData(defaultData);
            }else if(error.response.status === 409){
                enqueueSnackbar("Couldn't Delete UOM",{variant:'warning'});                
            }
        })
    }

    const handleOpenAddModel = () =>{
        setModelTitle('Add UOM')
        setOpenModel(true)
    }

    const handleOpenEditModel = (data) =>{
        setModelTitle('Edit UOM')
        setOpenModel(true)
        setRow(data)
    }

    const handleCloseModel = () =>{
        setOpenModel(false);
        setRow({});
    }
   
    useEffect(() => {
        getAllPageable(currentPage.current,currentRows.current)
    }, [])

    return (
        <div>            
            <Box>
                <Datatable rows={uomData} columns={columns} loading={gridLoading} options={{"title":"UOM", "handleAddButtonClick":handleOpenAddModel,"currentpage":currentPage,"currentRows":currentRows}} getApi={getAllPageable} />
            </Box>            
            <Model title={modelTitle} open={openModel} close={handleCloseModel}>
                    <UOMForm handleAdd={addUOM} handleUpdate={updateUOM} row={row}></UOMForm>
            </Model>
        </div>
    )
}