
import Datatable from "../../components/datatable/Datatable"
import { Box } from "@mui/system"
import { useEffect, useRef, useState } from "react";
import UserForm from "../../components/forms/UserForm";
import userService from "../../service/api/userService";
import Action from "../../components/datatable/Action";
import { useSnackbar } from "notistack";
import FullScreenDialog from "../../components/model/FullScreenModel";


export default function User() {
    const defaultData = { "data": [],  "pageNumber": 0, "pageSize": 0, "totalPages": 0, "totalElements": 0 };
    const [userData, setUserData] = useState(defaultData)
    const [openModel, setOpenModel] = useState(false)
    const [gridLoading, setGridLoading]  = useState(false);
    const [modelTitle, setModelTitle] = useState('')
    const [row, setRow] = useState({})
    const currentPage = useRef(1);
    const currentRows = useRef(5);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', headerName: 'ID',width:60 },
        {
            field: 'firstName',
            headerName: 'First Name',
            editable: false,
            width:"130"
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            editable: false,
            width:"130"
        },
        {
            field: 'username',
            headerName: 'Username',
            editable: false,
            width:"130"
        },
        {
            field: 'email',
            headerName: 'Email',
            editable: false,
            width:"150"
        },
        {
            field: 'mobile',
            headerName: 'Mobile',
            editable: false,
            width:"130"
        },
        {
            field: 'gender',
            headerName: 'Gender',
            editable: false,
            width:"80"
        },
        {
            field: 'Action',
            headerName: 'Action',
            editable: false,
            renderCell: (params) => (
               <Action handleEdit={handleOpenEditModel} handleDelete={deleteUser} row={params.row} ></Action>
            )
        }
    ];   

    const getAllPageable = async (page, size) => {    
        setGridLoading(true)    
        return userService.getAllPageable(page, size).then((res) => {
            setUserData(res.data) 
            setGridLoading(false)
        }).catch((error)=>{
            if(error.response.status===404){
                setUserData(defaultData);
                setGridLoading(false)
            }
        })
    }

    const addUser = async (data) => {
        return userService.create(data).then((res) => {
            enqueueSnackbar("New User Added",{variant:'success'})
            handleCloseModel();
            currentPage.current=1            
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("User Already Exist",{variant:'warning'});
                handleCloseModel();
            }
        })
    }
    
    const updateUser = async (data) => {
        return userService.update(data).then((res) => {
            enqueueSnackbar("User Updated",{variant:'success'})
            handleCloseModel();
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'}); 
                currentPage.current=1           
                getAllPageable(currentPage.current,currentRows.current);
            }else if(error.response.status === 409){
                enqueueSnackbar("User Already Exist",{variant:'warning'});
                handleCloseModel();
            }
            
        })
    }

    const deleteUser = async (data) =>{
        return userService.delete(data.id).then((res)=>{            
            getAllPageable(currentPage.current,currentRows.current);
            enqueueSnackbar("User Deleted",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){
                setUserData(defaultData);
            }else if(error.response.status === 409){
                enqueueSnackbar("Couldn't Delete User",{variant:'warning'});                
            }
        })
    }

    const handleOpenAddModel = () =>{
        setModelTitle('Add User')
        setOpenModel(true)
    }

    const handleOpenEditModel = (data) =>{
        setModelTitle('Edit User')
        setOpenModel(true)
        setRow(data)
    }

    const handleCloseModel = () =>{
        setOpenModel(false);
        setRow({});
    }
   
    useEffect(() => {
        getAllPageable(currentPage.current,currentRows.current)
    }, [])

    return (
        <div>            
            <Box>
                <Datatable rows={userData} columns={columns} loading={gridLoading} options={{"title":"User", "handleAddButtonClick":handleOpenAddModel,"currentpage":currentPage,"currentRows":currentRows}} getApi={getAllPageable} />
            </Box>            
            <FullScreenDialog title={modelTitle} open={openModel} close={handleCloseModel}>
                    <UserForm handleAdd={addUser} handleUpdate={updateUser} row={row}></UserForm>
            </FullScreenDialog>

        </div>
    )
}