import axios from '../../config/axiosConfig'

class UomService {

    getAllPageable(page,size) {
        return axios.get(`/v1/pageable/uom?page=${page}&size=${size}`);
    }

    getAll() {
        return axios.get('/v1/uom');
    }

    create(data) {
        return axios.post('/v1/uom', data);
    }
    update(data) {
        return axios.put(`/v1/uom/${data.id}`, data);
    }
    delete(id) {
        return axios.delete(`/v1/uom/${id}`);
    }
}

export default new UomService();