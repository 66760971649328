import { Button, Card, CardContent, Grid } from "@mui/material";
import AddBulkStock from "../../components/forms/inventory/AddBulkStock";
import ItemsDataTable from "../../components/datatable/ItemsDataTable";
import { useDispatch, useSelector } from "react-redux";
import Action from "../../components/datatable/Action";
import {
  setBulkStock,
  setBulkStockOnUpdate,
} from "../../redux/reducer/bulkStock";
import inventoryService from "../../service/api/inventoryService";
import { enqueueSnackbar } from "notistack";

export default function (props) {

  const addBulkStock = async (data) => {
    return inventoryService
      .addBulkStock(data)
      .then((res) => {
        enqueueSnackbar("New Stock Added", { variant: "success" });
      })
      .catch((error) => {
        if (error.response.status === 409) {
          enqueueSnackbar("Error", { variant: "warning" });
        }
      });
  };
  
  return (
    <>
      <h2 style={{ marginBottom: 0 }}>Add New Stock</h2>
      <span style={{ fontSize: 14, marginBottom: 2 }}>
        Home / Inventory / Add New Stock
      </span>
      <Card>
        <CardContent>
          <AddBulkStock handleAdd={addBulkStock} />
        </CardContent>   
      </Card>
    </>
  );
}
