import { useState } from "react";
import PurchaseForm from "../../components/forms/purchase/PurchaseForm";
import { useSnackbar } from "notistack";
import purchaseService from "../../service/api/purchaseService";
import { Card, CardContent } from "@mui/material";

export default function CreatePurchase(){
    const [row, setRow] = useState({})
    const { enqueueSnackbar } = useSnackbar();

    const addPurchase = async (data) => {
        return purchaseService.create(data).then((res) => {
            enqueueSnackbar("New Purchase Added",{variant:'success'})            
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Purchase Already Exist",{variant:'warning'});               
            }
        })
    }
    
    const updatePurchase = async (data) => {
        return purchaseService.update(data).then((res) => {
            enqueueSnackbar("Purchase Updated",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'});              
            }else if(error.response.status === 409){
                enqueueSnackbar("Purchase Already Exist",{variant:'warning'});             
            }
            
        })
    }   

    return(
        <>
        <h2 style={{marginBottom:0}}>Create Purchase</h2>
        <span style={{fontSize:14,marginBottom:2}}>Home / Dashboard</span>
        <Card>
            <CardContent>
                <PurchaseForm handleAdd={addPurchase} handleUpdate={updatePurchase} row={row} />
            </CardContent>
        </Card>
        {/* <FullScreenDialog title={modelTitle} open={openModel} close={handleCloseModel}>
                    <ProductForm handleAdd={addProduct} handleUpdate={updateProduct} row={row}></ProductForm>
        </FullScreenDialog> */}
        </>
    )
}