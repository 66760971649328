import Datatable from "../../components/datatable/Datatable"
import { Box } from "@mui/system"
import { useEffect, useRef, useState } from "react";
import PaymentModeForm from "../../components/forms/master/PaymentModeForm";
import paymentModeService from "../../service/api/paymentModeService";
import Action from "../../components/datatable/Action";
import Model from "../../components/model/Model";
import { useSnackbar } from "notistack";

export default function PaymentMode() {
    const defaultData = { "data": [],  "pageNumber": 0, "pageSize": 0, "totalPages": 0, "totalElements": 0 };
    const [paymentModeData, setPaymentModeData] = useState(defaultData)
    const [openModel, setOpenModel] = useState(false)
    const [modelTitle, setModelTitle] = useState('')
    const [gridLoading, setGridLoading]  = useState(false);
    const [row, setRow] = useState({})
    const currentPage = useRef(1);
    const currentRows = useRef(5);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', headerName: 'ID',width:"70" },
        {
            field: 'name',
            headerName: 'Name',
            editable: false,
            width:"200"
        },
        {
            field: 'Action',
            headerName: 'Action',
            editable: false,
            renderCell: (params) => (
               <Action handleEdit={handleOpenEditModel} handleDelete={deletePaymentMode} row={params.row} ></Action>
            )
        }
    ];   

    const getAllPageable = (page, size) => {     
        setGridLoading(true);   
        return paymentModeService.getAllPageable(page, size).then((res) => {
            setGridLoading(false);
            setPaymentModeData(res.data) 
        }).catch((error)=>{
            if(error.response.status===404){
                setGridLoading(false);
                setPaymentModeData(defaultData);
            }
        })
    }

    const addPaymentMode = (data) => {
        return paymentModeService.create(data).then((res) => {
            enqueueSnackbar("New Payment Mode Added",{variant:'success'})
            handleCloseModel();
            currentPage.current=1            
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Payment Mode Already Exist",{variant:'warning'});
                handleCloseModel();
            }
        })
    }
    
    const updatePaymentMode = (data) => {
        return paymentModeService.update(data).then((res) => {
            enqueueSnackbar("Payment Mode Updated",{variant:'success'})
            handleCloseModel();
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'}); 
                currentPage.current=1           
                getAllPageable(currentPage.current,currentRows.current);
            }else if(error.response.status === 409){
                enqueueSnackbar("Payment Mode Already Exist",{variant:'warning'});
                handleCloseModel();
            }
            
        })
    }

    const deletePaymentMode = (data) =>{
        return paymentModeService.delete(data.id).then((res)=>{            
            getAllPageable(currentPage.current,currentRows.current);
            enqueueSnackbar("Payment Mode Deleted",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){
                setPaymentModeData(defaultData);
            }else if(error.response.status === 409){
                enqueueSnackbar("Couldn't Delete Payment Mode",{variant:'warning'});                
            }
        })
    }

    const handleOpenAddModel = () =>{
        setModelTitle('Add Payment Mode')
        setOpenModel(true)
    }

    const handleOpenEditModel = (data) =>{
        setModelTitle('Edit Payment Mode')
        setOpenModel(true)
        setRow(data)
    }

    const handleCloseModel = () =>{
        setOpenModel(false);
        setRow({});
    }
   
    useEffect(() => {
        getAllPageable(currentPage.current,currentRows.current)
    }, [])

    return (
        <div>            
            <Box>
                <Datatable rows={paymentModeData} columns={columns} loading={gridLoading} options={{"title":"Payment Mode", "handleAddButtonClick":handleOpenAddModel,"currentpage":currentPage,"currentRows":currentRows}} getApi={getAllPageable} />
            </Box>            
            <Model title={modelTitle} open={openModel} close={handleCloseModel}>
                    <PaymentModeForm handleAdd={addPaymentMode} handleUpdate={updatePaymentMode} row={row}></PaymentModeForm>
            </Model>
        </div>
    )
}