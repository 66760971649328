import axios from '../../config/axiosConfig'

class CompanyService {    

    get() {
        return axios.get('/v1/company');
    }

    edit(data) {
        return axios.post(`/v1/company`, data);
    }
    
}

export default new CompanyService();