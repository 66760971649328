import { combineReducers } from "redux";

//persistance imports
import { persistReducer } from "redux-persist";

//reducers

import settings from "../reducer/settings";
import purchase from "../reducer/purchase";
import user from "../reducer/user";
import sale from "../reducer/sale";
import menu from "../reducer/menu";
import bulkStock from "../reducer/bulkStock";
import company from "../reducer/company";
import persistConfig from "../../config/persistConfig";

const rootReducer = combineReducers({
  user,
  settings,
  purchase,
  sale,
  menu,
  bulkStock,
  company
});

export const reducer = persistReducer(persistConfig, rootReducer);
