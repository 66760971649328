import { useSnackbar } from "notistack";

import { useState } from "react";
import expenseService from "../../service/api/expenseService";
import { Card, CardContent } from "@mui/material";
import ExpenseForm from "../../components/forms/ExpenseForm";

export default function CreateExpense(){

    const [row, setRow] = useState({})
    const { enqueueSnackbar } = useSnackbar();

    const addExpense = async (data) => {        
        return expenseService.create(data).then((res) => {
            enqueueSnackbar("New Expense Added",{variant:'success'})            
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Expense Already Exist",{variant:'warning'});               
            }
        })
    }
    
    const updateExpense = async (data) => {
        return expenseService.update(data).then((res) => {
            enqueueSnackbar("Expense Updated",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'});              
            }else if(error.response.status === 409){
                enqueueSnackbar("Expense Already Exist",{variant:'warning'});             
            }
            
        })
    }

    return(
        <>
        <h2 style={{marginBottom:0}}>Add Expense</h2>
        <span style={{fontSize:14,marginBottom:2}}>Home / Dashboard</span>
        <Card sx={{marginTop:1}}>
            <CardContent>
             <ExpenseForm handleAdd={addExpense} handleUpdate={updateExpense} row={row}/>
        </CardContent>
        </Card>
        </>
    );
}