import SaveIcon from '@mui/icons-material/Save';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import customerService from '../../service/api/customerService';
import paymentModeService from '../../service/api/paymentModeService';
import DateTimePicker from '../ui/form/datepicker/DateTimePicker';
import DecimalField from '../ui/form/number/DecimalField';
import NamedAutoComplete from '../ui/form/select/NamedAutoComplete';
import TextBox from '../ui/form/TextBox';

export default function ExpenseForm(props) {      
    const {handleAdd, handleUpdate, row}   = props;
    const {  register, handleSubmit, reset, resetField,control, setError, clearErrors, setValue,watch, formState: { errors, isSubmitting } } = useForm();   
    const [customerData, setCustomerData] = useState([]);
    const [paymentModeData, setPaymentModeData] = useState([]);   

    const handelResetField = () => {
        reset()        
    };

    const onSubmit = async data => {    
        if(row['id']){                     
            await handleUpdate({...data,id:row.id})
        }else{
           await handleAdd(data)
           handelResetField();
        }       
    };

    useEffect(()=>{ 
        getAllCustomer();
        getAllPaymentMode();
        if(row['id']){      
        setValue("amount",row.amount)
        setValue("date",row.date)
        setValue("description",row.description)
        setValue("document",row.document)
        setValue("document",row.document)
        setValue("subject",row.subject)
        setValue("useBy",row.useBy)
        }else{
            handelResetField();
        }
         
    },[row])

    const getAllCustomer = ()=>{
        customerService.getAllForDropdown().then(res=>{
            setCustomerData(res.data)
        })
    }

    const getAllPaymentMode = () =>{
        paymentModeService.getAll().then(res=>{
          setPaymentModeData(res.data)            
        })
      }

    return (
        <div>          
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container spacing={2} padding={1}>                    
                    <Grid item xs={3}>
                        <DateTimePicker
                            name="date"
                            label="Invoice Date"
                            required={true}
                            error={errors["date"]}
                            control={control}
                            size="small"
                            errorMessage='Select a date'
                        />
                    </Grid>   
                    <Grid item xs={3}>
                        <TextBox
                            error={errors['subject']} 
                            control={control}
                            required={true}
                            label="Subject" 
                            size='small' 
                            name='subject'
                            errorMessage='Enter the subject'
                        />
                    </Grid>
                    
                    <Grid item xs={3}>
                        <DecimalField
                        control={control}
                        required={true}
                        name="amount"
                        label="Amount"
                        size="small"
                        error={errors["amount"]}
                        errorMessage='Enter the expense amount'
                        /> 
                    </Grid>
                    <Grid item xs={3}>
                    <NamedAutoComplete
                            control={control}
                            required={true}
                            error={errors["useBy"]}
                            options={customerData}
                            size="small"
                            label="User"
                            name={`useBy`}
                            errorMessage='Select a user'
                        />
                    </Grid>
                    <Grid item xs={3}>
                    <TextField error={errors['description']} multiline {...register("description")} sx={{ width: "100%" }} label="Description" size='small' name='description' />
                       
                    </Grid>
                    
                </Grid>

            <Grid   item  xs={12}   sx={{ textAlign: "end", position: "fixed", bottom: 25, right: 50 }} >
                        <Button variant="contained" disabled={isSubmitting} type="submit" size="small" ><SaveIcon/>{row['id']?"Edit":"Submit"}</Button>
                        {/* <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                            Save
                        </LoadingButton> */}
                        {/* <Button variant="outlined" type="button" onClick={props.close} size="small">Cancel</Button> */}
                    </Grid>
            </form>
               
        </div>
    );
}