import axios from '../../config/axiosConfig'

class CustomerService {

    getAllPageable(page,size) {
        return axios.get(`/v1/pageable/customer?page=${page}&size=${size}`);
    }

    getAllForDropdown() {
        return axios.get(`/v1/dropdown/customer`);
    }

    getAddress(id) {
        return axios.get(`/v1/customer-address/${id}`);
    }

    create(data) {
        return axios.post('/v1/customer', data);
    }

    update(data) {
        return axios.put(`/v1/customer/${data.id}`, data);
    }

    delete(id) {
        return axios.delete(`/v1/customer/${id}`);
    }
    
}

export default new CustomerService();