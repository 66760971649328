import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { IconButton, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedProductData, closeCreateBatchModel, setSelectedProductData, updateAdditionalCharges, updateIsIgst, updateShippingCharges } from '../../../redux/reducer/purchase';
import paymentModeService from '../../../service/api/paymentModeService';
import supplierService from '../../../service/api/supplierService';
import PurchaseSelectProduct from '../../function/PurchaseSelectProduct';
import DateTimePicker from '../../ui/form/datepicker/DateTimePicker';
import DecimalField from '../../ui/form/number/DecimalField';
import NamedAutoComplete from '../../ui/form/select/NamedAutoComplete';
import { useSnackbar } from 'notistack';
import FullScreenDialog from '../../model/FullScreenModel';
import SupplierForm from '../SupplierForm';
import ProductForm from '../product/ProductForm';
import productService from '../../../service/api/productService';
import ProductBatchForm from '../product/ProductBatchForm';
import Model from '../../model/Model';
import productBatchService from '../../../service/api/productBatchService';
import moment from 'moment-timezone';

export default function PurchaseForm(props) {  
  const {selectedProductData, purchaseData,createBatch} = useSelector((state)=>state.purchase);
  const {stateCode} = useSelector((state)=>state.company);
  const { enqueueSnackbar } = useSnackbar();  
  const [openCreateProductModel, setOpenCreateProductModel] = useState(false)
  const [openCreateSupplierModel, setOpenCreateSupplierModel] = useState(false)

  const dispatch = useDispatch(); 
  const {handleAdd, handleUpdate, row}   = props;
  const { register, handleSubmit, reset, resetField,control, setError, clearErrors, setValue, formState: { errors, isSubmitting } } = useForm({
    defaultValues:{"invoiceDate":moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ').toString(),"shipping":0.0,"additionalCharges":0.0, "discount":0.0,"discountAmount":0.0,"roundOff":0.0}
  });
  const [supplierData, setSupplierData] = useState([]);
  const [paymentModeData, setPaymentModeData] = useState([]);
  
  const handelResetField = () => {
    reset()
    setValue("payments[0].paymentModeId",1)
    dispatch(clearSelectedProductData())
  };   

  const onSubmit = async data => { 
    data = {...data, ...purchaseData}
    data.purchaseItems = selectedProductData    
    if(row['id']){
        await handleUpdate({...data,id:row.id})
        handelResetField();
    }else{
        await handleAdd(data)
        handelResetField();
    }       
  };

    const getAllSupplier = ()=>{
        supplierService.getAllForDropdown().then(res=>{
            setSupplierData(res.data)
        })
    }

    const getAllPaymentMode = () =>{
      paymentModeService.getAll().then(res=>{
        setPaymentModeData(res.data)
        setValue("payments[0].paymentModeId",1)
      })
    }

    const addSupplier = async (data) => {
      return supplierService.create(data).then((res) => {
          enqueueSnackbar("New Supplier Added",{variant:'success'})
          handleCloseCreateSupplierModel();      
      }).catch((error)=>{
          if(error.response.status===409){
              enqueueSnackbar("Supplier Already Exist",{variant:'warning'});
              handleCloseCreateSupplierModel();
          }
      })
  }  

  const handleCloseCreateProductModel = () =>{
    setOpenCreateProductModel(false)
  }

  const handleOpenCreateProductModel = () =>{
    setOpenCreateProductModel(true)
  }

  const addProduct = async (data) => {
    return productService.create(data).then((res) => {
        enqueueSnackbar("New Product Added",{variant:'success'})
        handleCloseCreateProductModel();                
        
    }).catch((error)=>{
        if(error.response.status===409){
            enqueueSnackbar("Product Already Exist",{variant:'warning'});
            handleCloseCreateProductModel();
        }
    })
}

  const handleCloseCreateSupplierModel = () =>{
    setOpenCreateSupplierModel(false)
  } 

  const addProductBatch= async (data)=>{
    return productBatchService.create(data).then(res=>{
        enqueueSnackbar("New Batch Added",{variant:'success'})
        dispatch(closeCreateBatchModel())                
    }).catch((error)=>{
        if(error.response.status===409){
            enqueueSnackbar("Batch Already Exist",{variant:'warning'});
            dispatch(closeCreateBatchModel())
        }
    })
  }

  const handleSupplierOnchange = (e)=>{
    if(e.stateCode != stateCode){
      dispatch(updateIsIgst(true));
    }else{
      dispatch(updateIsIgst(false));
    }
  }

    useEffect(()=>{ 
        getAllSupplier();
        getAllPaymentMode();
        if(row['id']){       
          setValue("supplierId",row.supplier.id)
          setValue("invoiceNo",row.invoiceNo)
          // getAddressByCustomerId({id:row.customer.id})
          // setValue("shippingAddressId",row.shippingAddress.id)
          // setValue("billingAddressId",row.billingAddress.id)
          setValue("invoiceDate",row.invoiceDate)
          
          dispatch(setSelectedProductData(row.purchaseItems))
          
          setValue("additionalCharges",row.additionalCharges)
          dispatch(updateAdditionalCharges(row.additionalCharges))

          setValue("shipping",row.shipping)
          dispatch(updateShippingCharges(row.shipping))

          setValue("payments[0].paymentModeId",row.payments[0].paymentMode.id)
          setValue("payments[0].amount",row.payments[0].amount)
        }else{
          handelResetField();            
        }
    },[row])

    useEffect(()=>{
      // setValue("roundOff", purchaseData.roundOff)
      setValue("payments[0].amount",purchaseData.grandTotal)
    },[purchaseData.grandTotal])

    return (
      <>
      <div>        
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} padding={1}>
          <Grid item xs={2}>
              <TextField
              type='text'
                error={errors["referenceNo"]}
                InputLabelProps={{ shrink: true }}
                placeholder="Auto"
                disabled
                sx={{ width: "100%" }}
                label="Reference No"
                size="small"
                name="referenceNo"
              />
            </Grid>
            <Grid item xs={3}>
              <DateTimePicker
                name="invoiceDate"
                errorMessage={"Select purchase date"}
                label="Invoice Date"
                required={true}
                error={errors["invoiceDate"]}
                control={control}
                size="small"
              />
            </Grid>
            <Grid item xs={3}>              
              <NamedAutoComplete
                control={control}
                required={true}
                error={errors["supplierId"]}
                errorMessage={"Select a Supplier"}
                options={supplierData}
                size="small"
                label="Supplier"
                name={`supplierId`}      
                handelOnChange={(e)=>{
                  handleSupplierOnchange(e)
                }}
                actionButton={{
                  name : "New Supplier",
                  icon : <PersonAddAltOutlinedIcon />,
                  onClick : ()=>{setOpenCreateSupplierModel(true)}
                }}          
              />             
            </Grid>   

            <Grid item xs={3}>
              <TextField
                type='text'
                error={errors["invoiceNo"]}
                helperText={errors["invoiceNo"]?"Enter the invoice no" : " "} 
                FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
                InputLabelProps={{ shrink: true }}
                {...register("invoiceNo",{required:true})}
                sx={{ width: "100%" }}
                label="Invoice No"
                size="small"
                name="invoiceNo"
              />
            </Grid>
           
            <Grid item xs={12}>
              <PurchaseSelectProduct handelOpenModel={handleOpenCreateProductModel} />
            </Grid>
          </Grid>
          
          <Grid container spacing={2} padding={1}>
            <Grid item container xs={6} spacing={2} padding={1}>
              <Grid item xs={3}>
              <DecimalField
                  control={control}
                  name="shipping"
                  label="Shipping"
                  size="small"
                  error={errors["shipping"]}
                  onBlur={(e)=>{
                    if(e.target.value === ""){
                      setValue("shipping",0.00)
                      dispatch(updateShippingCharges(parseFloat(0.00)))
                    }else{                      
                      dispatch(updateShippingCharges(parseFloat(e.target.value)))
                    }                    
                  }}
                />               
              </Grid>

              <Grid item xs={3}>
              <DecimalField
                  control={control}
                  name="additionalCharges"
                  label="Additional Charges"
                  size="small"
                  error={errors["additionalCharges"]}
                  onBlur={(e)=>{
                    if(e.target.value === ""){
                      setValue("additionalCharges",0.00)
                      dispatch(updateAdditionalCharges(parseFloat(0.00)))
                    }else{                      
                      dispatch(updateAdditionalCharges(parseFloat(e.target.value)))
                    }
                  }}
                />                    
              </Grid>

              {/* <Grid item xs={3}>
              <DecimalField
                 
                  name="discount"
                  label="Discount (%)"
                  size="small"
                  error={errors["discount"]}                  
                />                 
              </Grid>

              <Grid item xs={3}>
              <DecimalField
                 
                  name="discountAmount"
                  label="Discount Amount"
                  size="small"
                  error={errors["discountAmount"]}
                  onBlur={(e)=>{
                    // dispatch(updateAdditionalCharges(parseFloat(e.target.value)))
                  }}
                />                 
              </Grid> */}

              {/* <Grid item xs={3}>
                <DecimalField
                  control={control}
                  name="roundOff"
                  label="Round Off"
                  size="small"
                  error={errors["roundOff"]}
                  onBlur={(e)=>{
                    dispatch(updateRoundOff(parseFloat(e.target.value)))
                  }}
                />                
              </Grid> */}

              <Grid item xs={3}>
                <NamedAutoComplete
                  control={control}
                  required={true}
                  error={errors["payments"]?.[0]?.paymentModeId}
                  errorMessage="Select a payment Mode"
                  options={paymentModeData}
                  size="small"
                  label="Payment Mode"
                  name={`payments[0].paymentModeId`}
                />
              </Grid>

              <Grid item xs={3}>
                <DecimalField
                  control={control}
                  required={true}
                  name="payments[0].amount"
                  label="Amount"
                  size="small"
                  error={errors["payments"]?.[0]?.amount}
                  errorMessage={"Enter Paid Amount"}
                />   
                
              </Grid>
             
            </Grid>

            <Grid item xs={6} sx={{ display:"flex", justifyContent: "end" }}>
              {/* <Fieldset title="Total Amount" style={{ minWidth: 400 }}>
                <Typography
                  sx={{ fontSize: 55, textAlign: "center" }}
                  color="text.secondary"
                  gutterBottom
                >
                  <b> Rs. {purchaseData.grandTotal} </b>
                </Typography>
              </Fieldset> */}
              <Typography color="text.secondary" width={200} gutterBottom>
                CGST: &emsp; <b>₹{purchaseData.cgst}</b>
                <br />
                SGST: &emsp; <b>₹{purchaseData.sgst}</b>
                <br />
                IGST: &emsp; <b>₹{purchaseData.igst}</b>
                <br />
                Total GST: <b>&emsp; ₹{purchaseData.gstAmount}</b>
              </Typography>
          
              <Typography color="text.secondary" width={200} gutterBottom>
                {/* Subtotal : &emsp; <b>₹{purchaseData.grandTotal} </b>
                <br /> */}
                Discount : &emsp; <b>₹{purchaseData.discountAmount} </b>
                <br />
                Round Off : &emsp; <b>₹{purchaseData.roundOff} </b>
                <br />
                <span >Grand Total : &emsp;<b>₹{purchaseData.grandTotal}</b></span>
              </Typography>
            </Grid>
          </Grid>

          <Grid container sx={{ justifyContent: "end" }} spacing={2} padding={1}>
            <Grid item xs={10}>
            {row["id"] && <p style={{marginTop:0, color:'red'}}>Currently Update Purchase is Not working, This Option will available for Beta ASAP.</p>}
            </Grid> 
          </Grid>
        </form>
        <Grid container sx={{ justifyContent: "end" }} spacing={2} padding={2 }>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                  size="small"
                >
                  {row["id"] ? "Edit" : "Submit"}
                </Button> 
              </Grid>
        { !row["id"] && <Grid item>
                <Button                
                  variant="contained"
                  onClick={handelResetField}
                  type="submit"
                  size="small"
                >
                  Reset
                </Button>
              </Grid>}
              
              {/* Currently Submit and print Option no needed for the Purchase Screen */}
              
             {/* { !row["id"] && <Grid item>
              <Button
                variant="contained"
                disabled={isSubmitting}
                type="submit"
                size="small"
                onClick={handleSubmit(onSubmitAndPrint)}
              >
                Submit & Print
              </Button> 
              </Grid>} */}              
          </Grid>
      </div>

      <FullScreenDialog title="Create Supplier" open={openCreateSupplierModel} close={handleCloseCreateSupplierModel}>
        <SupplierForm handleAdd={addSupplier}></SupplierForm>
      </FullScreenDialog>   
      <FullScreenDialog title="Create Product" open={openCreateProductModel} close={handleCloseCreateProductModel}>
        <ProductForm handleAdd={addProduct}></ProductForm>
    </FullScreenDialog>   
    <Model title={"Create Batch"} open={createBatch.openModel} close={()=>{
      dispatch(closeCreateBatchModel())
    }}>
      <ProductBatchForm handleAdd={addProductBatch} variantId={createBatch.variantId}></ProductBatchForm>
    </Model>
      </>      
    );
}


