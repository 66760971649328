
import Datatable from "../../components/datatable/Datatable"
import { Box } from "@mui/system"
import { useEffect, useRef, useState } from "react";
import productService from "../../service/api/productService";
import Action from "../../components/datatable/Action";
import Model from "../../components/model/Model";
import { useSnackbar } from "notistack";
import FullScreenDialog from "../../components/model/FullScreenModel";
import ProductForm from "../../components/forms/product/ProductForm";


export default function ListProduct() {
    const defaultData = { "data": [],  "pageNumber": 0, "pageSize": 0, "totalPages": 0, "totalElements": 0 };
    const [productData, setProductData] = useState(defaultData)
    const [openModel, setOpenModel] = useState(false)
    const [modelTitle, setModelTitle] = useState('')
    const [row, setRow] = useState({})
    const [gridLoading, setGridLoading]  = useState(false);
    const currentPage = useRef(1);
    const currentRows = useRef(5);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', headerName: 'ID' },
        {
            field: 'name',
            headerName: 'Name',
            editable: false,
        },
        {
            field: 'brand',
            headerName: 'Brand',
            valueFormatter: ({ value }) => value.name,
            editable: false,
        },
        {
            field: 'category',
            headerName: 'Category',
            valueFormatter: ({ value }) => value.name,
            editable: false,
        },
        {
            field: 'baseUnit',
            headerName: 'Base Unit',
            valueFormatter: ({ value }) => value.shortName,
            editable: false,
        },
        {
            field: 'gst',
            headerName: 'GST',
            valueFormatter: ({ value }) => value.name,
            editable: false,
        },
        {
            field: 'gstType',
            headerName: 'GST Type',
            editable: false,
        },
        {
            field: 'productVariants',
            headerName: 'Variants',
            valueFormatter: ({value}) => value.length,
            editable: false,
        },
        {
            field: 'Action',
            headerName: 'Action',
            editable: false,
            renderCell: (params) => (
               <Action handleEdit={handleOpenEditModel} handleDelete={deleteProduct} row={params.row} ></Action>
            )
        }
    ];   

    const getAllPageable = (page, size) => {   
        setGridLoading(true);     
        return productService.getAllPageable(page, size).then((res) => {
            setGridLoading(false);
            setProductData(res.data) 
        }).catch((error)=>{
            if(error.response.status===404){
                setGridLoading(false);
                setProductData(defaultData);
            }
        })
    }

    const addProduct = (data) => {
        return productService.create(data).then((res) => {
            enqueueSnackbar("New Product Added",{variant:'success'})
            handleCloseModel();
            currentPage.current=1            
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Product Already Exist",{variant:'warning'});
                handleCloseModel();
            }
        })
    }
    
    const updateProduct = (data) => {
        return productService.update(data).then((res) => {
            enqueueSnackbar("Product Updated",{variant:'success'})
            handleCloseModel();
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'}); 
                currentPage.current=1           
                getAllPageable(currentPage.current,currentRows.current);
            }else if(error.response.status === 409){
                enqueueSnackbar("Product Already Exist",{variant:'warning'});
                handleCloseModel();
            }
            
        })
    }

    const deleteProduct = (data) =>{
        return productService.delete(data.id).then((res)=>{            
            getAllPageable(currentPage.current,currentRows.current);
            enqueueSnackbar("Product Deleted",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){
                setProductData(defaultData);
            }else if(error.response.status === 409){
                enqueueSnackbar("Couldn't Delete Product",{variant:'warning'});                
            }
        })
    }

    const handleOpenAddModel = () =>{
        setModelTitle('Add Product')
        setOpenModel(true)
    }

    const handleOpenEditModel = (data) =>{
        setModelTitle('Edit Product')
        setOpenModel(true)
        setRow(data)
    }

    const handleCloseModel = () =>{
        setOpenModel(false);
        setRow({});
    }
   
    useEffect(() => {
        getAllPageable(currentPage.current,currentRows.current)
    }, [])

    return (
        <div>            
            <Box>
                <Datatable rows={productData} columns={columns} loading={gridLoading} options={{"title":"Product", "handleAddButtonClick":handleOpenAddModel,"currentpage":currentPage,"currentRows":currentRows}} getApi={getAllPageable} />
            </Box>            
            <FullScreenDialog title={modelTitle} open={openModel} close={handleCloseModel}>
                    <ProductForm handleAdd={addProduct} handleUpdate={updateProduct} row={row}></ProductForm>
            </FullScreenDialog>

        </div>
    )
}