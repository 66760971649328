import "./App.css";
import Layout from "./layout/Layout";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/stores/stores";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import { SnackbarProvider } from "notistack";
import ErrorBoundary from "./components/error/ErrorBoundary";
import Error from "./pages/error/Error";
import PreLoading from "./components/loading/PreLoading";
import { PersistGate } from "redux-persist/integration/react";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      light: "#4791db",
      main: "#1976d2",
      dark: "#115293",
      contrastText: "#fff",
    },
    secondary: {
      light: "#834bff",
      main: "#651fff",
      dark: "#4615b2",
      contrastText: "#000",
    },
  },
});

function App() {
  return (
    <>
      {/* <PreLoading></PreLoading> */}
      <ErrorBoundary fallback={<Error />}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <SnackbarProvider anchorOrigin={{vertical: 'bottom',horizontal: 'right',}}>
                <BrowserRouter>
                  <div className="App">
                    <Layout />
                  </div>
                </BrowserRouter>
              </SnackbarProvider>
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
