import axios from "axios";
import authRefreshInterceptor from "axios-auth-refresh";
import appConfig from "./appConfig";
import { purgeAllData } from "../redux/stores/stores";

const axiosBase = axios.create({
  baseURL: appConfig.backendUrl,
  withCredentials: true,
});

const refreshAuthLogic = () =>
  axios.get("/api/v1/auth/refresh", {skipAuthRefresh: true})
  .then(() => {
    return Promise.resolve();
  })
  .catch(() => {
    purgeAllData()
    window.location.reload();
    return null;
  });

authRefreshInterceptor(axiosBase, refreshAuthLogic);

export default axiosBase;
