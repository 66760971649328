import Datatable from "../../components/datatable/Datatable"
import { Box } from "@mui/system"
import { useEffect, useRef, useState } from "react";
import expenseService from "../../service/api/expenseService";
import Action from "../../components/datatable/Action";
import Model from "../../components/model/Model";
import { useSnackbar } from "notistack";
import FullScreenDialog from "../../components/model/FullScreenModel";

import printUtil from "../../util/printUtil";
import commonUtil from "../../util/commonUtil";
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import ExpenseForm from "../../components/forms/ExpenseForm";

export default function ListExpense(){
    const defaultData = { "data": [],  "pageNumber": 0, "pageSize": 0, "totalPages": 0, "totalElements": 0 };
    const [expenseData, setExpenseData] = useState(defaultData)
    const [openModel, setOpenModel] = useState(false)
    const [modelTitle, setModelTitle] = useState('')
    const [row, setRow] = useState({})
    const [gridLoading, setGridLoading]  = useState(false);
    const currentPage = useRef(1);
    const currentRows = useRef(5);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', headerName: 'ID', width:"60" }, 
        {
            field: 'date',
            headerName: 'Date',
            editable: false,
            width:"180",
            type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
        },   
        { field: 'subject', headerName: 'Subject' },       
        { field: 'amount', headerName: 'Amount' },     
        {
            field: 'Action',
            headerName: 'Action',
            editable: false,
            width: 140,
            renderCell: (params) => (
               <Action handleEdit={handleOpenEditModel} handleDelete={deleteExpense} row={params.row} ></Action>
            )
        }
    ];   

    const handleDownloadClick = (id,fileName) =>{
        expenseService.getPdfInvoice(id).then(res=>{
            commonUtil.downloadFile(res.data, fileName + ".pdf")            
        })
    }

    const handlePrintClick = (id,fileName) =>{
        expenseService.getPdfInvoice(id).then(res=>{           
            printUtil.printPdf([res.data])
        })
    }

    const moreActions = [
        {label:'Print', icon: <PrintIcon/> , handler:handlePrintClick},
        {label:'Download', icon: <DownloadIcon/> , handler:handleDownloadClick},
    ]

    const getAllPageable = async (page, size) => {     
        setGridLoading(true);  
        return expenseService.getAllPageable(page, size).then((res) => {
            setGridLoading(false);
            setExpenseData(res.data) 
        }).catch((error)=>{
            if(error.response.status===404){
                setGridLoading(false);
                setExpenseData(defaultData);
            }
        })
    }

    const addExpense = async (data) => {
        return expenseService.create(data).then((res) => {
            enqueueSnackbar("New Expense Added",{variant:'success'})
            handleCloseModel();
            currentPage.current=1            
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Expense Already Exist",{variant:'warning'});
                handleCloseModel();
            }
        })
    }
    
    const updateExpense = async (data) => {
        return expenseService.update(data).then((res) => {
            enqueueSnackbar("Expense Updated",{variant:'success'})
            handleCloseModel();
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'}); 
                currentPage.current=1           
                getAllPageable(currentPage.current,currentRows.current);
            }else if(error.response.status === 409){
                enqueueSnackbar("Expense Already Exist",{variant:'warning'});
                handleCloseModel();
            }
            
        })
    }

    const deleteExpense = async (data) =>{
        return expenseService.delete(data.id).then((res)=>{            
            getAllPageable(currentPage.current,currentRows.current);
            enqueueSnackbar("Expense Deleted",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){
                setExpenseData(defaultData);
            }else if(error.response.status === 409){
                enqueueSnackbar("Couldn't Delete Expense",{variant:'warning'});                
            }
        })
    }

    const handleOpenAddModel = () =>{
        setModelTitle('Add Expense')
        setOpenModel(true)
    }

    const handleOpenEditModel = (data) =>{
        setModelTitle('Edit Expense')
        setOpenModel(true)
        setRow(data)
    }

    const handleCloseModel = () =>{
        setOpenModel(false);
        setRow({});
    }
   
    useEffect(() => {
        getAllPageable(currentPage.current,currentRows.current)
    }, [])

    return (
        <div>            
            <Box>
                <Datatable rows={expenseData} columns={columns} loading={gridLoading} options={{"title":"Expense", "handleAddButtonClick":handleOpenAddModel,"currentpage":currentPage,"currentRows":currentRows}} getApi={getAllPageable} />
            </Box>            
            <FullScreenDialog title={modelTitle} open={openModel} close={handleCloseModel}>
                    <ExpenseForm handleAdd={addExpense} handleUpdate={updateExpense} row={row}></ExpenseForm>
            </FullScreenDialog>

        </div>
    )
}