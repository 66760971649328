import Dashboard from "../pages/Dashboard";
import Brand from "../pages/master/Brand";
import Category from "../pages/master/Category";
import UOM from "../pages/master/UOM";
import PaymentMode from "../pages/master/PaymentMode";
import GST from "../pages/master/GST";
import ListCustomer from "../pages/customer/ListCustomer";
import AddCustomer from "../pages/customer/AddCustomer";
import AddSupplier from "../pages/supplier/AddSupplier";
import ListSupplier from "../pages/supplier/ListSupplier";
import User from "../pages/user/User";
import AddProduct from "../pages/product/AddProduct";
import ListProduct from "../pages/product/ListProduct";
import CreateSale from "../pages/sale/CreateSale";
import ListSale from "../pages/sale/ListSale";
import CreatePurchase from "../pages/purchase/CreatePurchase";
import ListPurchase from "../pages/purchase/ListPurchase";
import ListExpense from "../pages/expense/ListExpense";
import CreateExpense from "../pages/expense/CreateExpense";
import ListPaymentReceipt from "../pages/payment/ListPaymentReceipt";
import CreatePaymentReceipt from "../pages/payment/CreatePaymentReceipt";
import AddNewStock from "../pages/inventory/AddNewStock";
import ViewStock from "../pages/inventory/ViewStock";
import MyAccount from "../pages/user/MyAccount";


const PageRoute = [   
    {
        "path":"dashboard",
        "Component":<Dashboard />
    },
    {
        "path":"user/account",
        "Component":<MyAccount/>
    },
    {
        "path":"master/brand",
        "Component":<Brand />
    },
    {
        "path":"master/category",
        "Component":<Category />
    },
    {
        "path":"master/uom",
        "Component":<UOM />
    },
    {
        "path":"master/payment-mode",
        "Component":<PaymentMode />
    },
    {
        "path":"master/gst",
        "Component":<GST />
    },
    {
        "path":"customer/list-customer",
        "Component":<ListCustomer />
    },
    {
        "path":"customer/add-customer",
        "Component":<AddCustomer />
    },
    {
        "path":"supplier/list-supplier",
        "Component":<ListSupplier />
    },
    {
        "path":"supplier/add-supplier",
        "Component":<AddSupplier />
    },
    {
        "path":"user",
        "Component":<User />
    },
    {
        "path":"product/add-product",
        "Component":<AddProduct />
    },
    {
        "path":"product/list-product",
        "Component":<ListProduct/>
    },
    {
        "path":"sale/create-sale",
        "Component":<CreateSale/>
    },
    {
        "path":"sale/list-sale",
        "Component":<ListSale/>
    },
    {
        "path":"purchase/create-purchase",
        "Component":<CreatePurchase/>
    },
    {
        "path":"purchase/list-purchase",
        "Component":<ListPurchase/>
    },
    {
        "path":"payment/list-payment",
        "Component":<ListPaymentReceipt/>
    },
    {
        "path":"payment/create-payment",
        "Component":<CreatePaymentReceipt/>
    },
    {
        "path":"expense/list-expense",
        "Component":<ListExpense/>
    },
    {
        "path":"expense/create-expense",
        "Component":<CreateExpense/>
    },
    {
        "path":"inventory/add-new-stock",
        "Component":<AddNewStock/>
    },
    {
        "path":"inventory/view-stock",
        "Component":<ViewStock/>
    },
]

export {PageRoute}