import axios from '../../config/axiosConfig'

class SupplierService{
    getAllPageable(page,size) {
        return axios.get(`/v1/pageable/supplier?page=${page}&size=${size}`);
    }

    getAllForDropdown() {
        return axios.get(`/v1/dropdown/supplier`);
    }

    create(data) {
        return axios.post('/v1/supplier', data);
    }

    update(data) {
        return axios.put(`/v1/supplier/${data.id}`, data);
    }
    
    delete(id) {
        return axios.delete(`/v1/supplier/${id}`);
    }
}

export default new SupplierService();