import Datatable from "../../components/datatable/Datatable"
import { Box } from "@mui/system"
import { useEffect, useRef, useState } from "react";
import CategoryForm from "../../components/forms/master/CategoryForm";
import categoryService from "../../service/api/categoryService";
import Action from "../../components/datatable/Action";
import Model from "../../components/model/Model";
import { useSnackbar } from "notistack";

export default function Category() {
    const defaultData = { "data": [],  "pageNumber": 0, "pageSize": 0, "totalPages": 0, "totalElements": 0 };
    const [categoryData, setCategoryData] = useState(defaultData)
    const [openModel, setOpenModel] = useState(false)
    const [modelTitle, setModelTitle] = useState('')
    const [gridLoading, setGridLoading]  = useState(false);
    const [row, setRow] = useState({})
    const currentPage = useRef(1);
    const currentRows = useRef(5);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', headerName: 'ID', width:"70" },
        {
            field: 'name',
            headerName: 'Name',
            editable: false,
            width:"200"
        },
        {
            field: 'description',
            headerName: 'Description',
            editable: false,
            width:"200"
        },
        {
            field: 'Action',
            headerName: 'Action',
            editable: false,
            renderCell: (params) => (
               <Action handleEdit={handleOpenEditModel} handleDelete={deleteCategory} row={params.row} ></Action>
            )
        }
    ];   

    const getAllPageable = (page, size) => {    
        setGridLoading(true);
        return categoryService.getAllPageable(page, size).then((res) => {
            setCategoryData(res.data) 
            setGridLoading(false);
        }).catch((error)=>{
            if(error.response.status===404){
                setCategoryData(defaultData);
                setGridLoading(false);
            }
        })
    }

    const addCategory = (data) => {
        return categoryService.create(data).then((res) => {
            enqueueSnackbar("New Category Added",{variant:'success'})
            handleCloseModel();
            currentPage.current=1            
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Category Already Exist",{variant:'warning'});
                handleCloseModel();
            }
        })
    }
    
    const updateCategory = (data) => {
        return categoryService.update(data).then((res) => {
            enqueueSnackbar("Category Updated",{variant:'success'})
            handleCloseModel();
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'}); 
                currentPage.current=1           
                getAllPageable(currentPage.current,currentRows.current);
            }else if(error.response.status === 409){
                enqueueSnackbar("Category Already Exist",{variant:'warning'});
                handleCloseModel();
            }
            
        })
    }

    const deleteCategory = (data) =>{
        return categoryService.delete(data.id).then((res)=>{            
            getAllPageable(currentPage.current,currentRows.current);
            enqueueSnackbar("Category Deleted",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){
                setCategoryData(defaultData);
            }else if(error.response.status === 409){
                enqueueSnackbar("Couldn't Delete Category",{variant:'warning'});                
            }
        })
    }

    const handleOpenAddModel = () =>{
        setModelTitle('Add Category')
        setOpenModel(true)
    }

    const handleOpenEditModel = (data) =>{
        setModelTitle('Edit Category')
        setOpenModel(true)
        setRow(data)
    }

    const handleCloseModel = () =>{
        setOpenModel(false);
        setRow({});
    }
   
    useEffect(() => {
        getAllPageable(currentPage.current,currentRows.current)
    }, [])

    return (
        <div>            
            <Box>
                <Datatable rows={categoryData} columns={columns} loading={gridLoading} options={{"title":"Category", "handleAddButtonClick":handleOpenAddModel,"currentpage":currentPage,"currentRows":currentRows}} getApi={getAllPageable} />
            </Box>            
            <Model title={modelTitle} open={openModel} close={handleCloseModel}>
                    <CategoryForm handleAdd={addCategory} handleUpdate={updateCategory} row={row}></CategoryForm>
            </Model>
        </div>
    )
}