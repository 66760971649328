import { Skeleton } from "@mui/material";

export default function SideMenuLoading() {
  return (
    <>
      {" "}
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
      <div style={{ display: "flex" }}>
        <Skeleton
          animation="wave"
          height={50}
          width="15%"
          style={{ marginBottom: 6, marginRight: "5px", marginLeft: "6px" }}
        />{" "}
        <Skeleton
          animation="wave"
          height={50}
          width="70%"
          style={{ marginBottom: 6 }}
        />{" "}
      </div>
    </>
  );
}
