import { useSnackbar } from "notistack";
import PaymentReceiptForm from "../../components/forms/PaymentReceiptForm";
import { useState } from "react";
import paymentReceiptService from "../../service/api/paymentReceiptService";
import { Card, CardContent } from "@mui/material";

export default function CreatePaymentReceipt(){

    const [row, setRow] = useState({})
    const { enqueueSnackbar } = useSnackbar();

    const addPaymentReceipt = async (data) => {        
        return paymentReceiptService.create(data).then((res) => {
            enqueueSnackbar("New Payment Receipt Added",{variant:'success'})            
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Payment Receipt Already Exist",{variant:'warning'});               
            }
        })
    }
    
    const updatePaymentReceipt = async (data) => {
        return paymentReceiptService.update(data).then((res) => {
            enqueueSnackbar("Payment Receipt Updated",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'});              
            }else if(error.response.status === 409){
                enqueueSnackbar("Payment Receipt Already Exist",{variant:'warning'});             
            }
            
        })
    }

    return(
        <>
        <h2 style={{marginBottom:0}}>Create Payment Receipt</h2>
        <span style={{fontSize:14,marginBottom:2}}>Home / Dashboard</span>
        <Card sx={{marginTop:1}}>
            <CardContent>
             <PaymentReceiptForm handleAdd={addPaymentReceipt} handleUpdate={updatePaymentReceipt} row={row}/>
        </CardContent>
        </Card>
        </>
    );
}