import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function DatePicker({name,label,error,size,control,defaultValue,required}){    
    return(
        <Controller
        name={name}
        rules={{required}}
        defaultValue={defaultValue ? defaultValue : ''}
        control={control}
        render={({field: { onChange, value }}) => (
          <TextField
            type="date"            
            InputLabelProps={{ shrink: true}}
            size={size}
            error={error}
            onChange={onChange}
            // value={value}
            fullWidth
            label={label}
            variant="outlined"
          />
        )}
      />

    )
}