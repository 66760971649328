import Box from '@mui/material/Box';
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import { TextField, Button, Pagination } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TablePagination from '@mui/material/TablePagination';
import NoRowsOverlay from './NoRowOverlay';

const ITEM_HEIGHT = 48;

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}



function QuickSearchToolbar(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
      <Grid
        container
        spacing={2}
        sx={{
          padding: 1,
          paddingLeft: 2,
          borderBottom: 0.2,
          borderBottomColor: "rgba(224, 224, 224, 1)",
          backgroundColor: "#F8F9FB",
        }}
      >
        <Grid lg={12} item>
          <h2 style={{ marginBottom: 0 }}>{props["options"]["title"]}</h2>
        </Grid>
        <Grid sm={12} md={4} item xs={3} sx={{ textAlign: "left" }}>
          <TextField
            // fullWidth
            size="small"
            variant="outlined"
            value={props.value}
            onChange={props.onChange}
            placeholder="Search…"
            InputProps={{
              sx: {
                borderRadius: 20,
                height: 35,
              },
              endAdornment: (
                <InputAdornment>
                  <IconButton sx={{ color: "#3D6DED" }}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={9} md={8} sm={12} sx={{ textAlign: "end" }}>
          {props["options"]["handleAddButtonClick"] && <Button
            variant="contained"
            size="small"
            onClick={props["options"]["handleAddButtonClick"]}
            sx={{  boxShadow: 0, marginRight: 1 }}
          >
            <AddIcon /> Add {props["options"]["title"]}
          </Button>}

          <GridToolbarExport />

          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            <MenuItem>
              <GridToolbarColumnsButton />
            </MenuItem>
            <MenuItem>
              <GridToolbarDensitySelector />
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={12}  md={12} sm={4} sx={{ textAlign: "end", display: "flex", justifyContent: "end" }} >
            {/* This is filter area */}
        </Grid>
      </Grid>
    );
}

function Footer(props) {
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangeRowsPerPage = (event) => { 
        props['getApi'](1, parseInt(event.target.value, 10))     
        setRowsPerPage(parseInt(event.target.value, 10));   
        props['options'].currentRows.current = parseInt(event.target.value, 10);  
    };
    return (
        <Grid container spacing={2} sx={{ justifyContent: "end", marginTop: 0.5, borderTopWidth: 0.5, borderTop: "0.5px solid", borderTopColor: "#E0E0E0" }} >
            <Grid lg={6} sx={{ paddingRight: 2 }} item>
                <TablePagination
                    component="div"
                    sx={{ width: "100%" }}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    ActionsComponent={(e) => (<></>)}
                    count={props['rows']['totalElements']}
                    page={props['rows']['pageNumber']-1}
                    // onPageChange={(event)=>{console.log("e")}}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </Grid>
            <Grid lg={12} sx={{ paddingRight: 2, paddingBottom: 2 }} item>
                <Pagination size='small' sx={{
                    '& .MuiPagination-ul': {
                        borderRadius: 10,
                        justifyContent: "end",
                    }
                }}                 
                count={props['rows']['totalPages']} 
                page={props['rows']['pageNumber']}
                onChange={(e, page) => {  
                  props['options'].currentpage.current = page;             
                  props['getApi'](page, rowsPerPage)
                }}                 
                variant="outlined" shape="rounded" />
            </Grid>
        </Grid>
    )
}

function Datatable(props) {
    const [searchText, setSearchText] = useState("");
    const [rows, setRows] = useState([]);    

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = rows.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearchText("");
        requestSearch(searchText);
    };

    return (
        <div>
            <Box sx={{ width: '100%', backgroundColor: "#fff", boxShadow: "rgba(0, 0, 0, 0.1) 2px 0px 2px 0px;", borderRadius: 2 }}>
                <DataGrid
                    autoHeight
                    loading={props.loading}
                    rows={props.rows.data}
                    columns={props.columns}                    
                    sx={{
                        borderTop: 0,
                        '& 	.MuiDataGrid-columnHeaders': {
                            backgroundColor: "#F8F9FB",

                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: "600",
                        },
                        "&.MuiDataGrid-checkboxInput": {
                            color: "#B5B5C1",
                            //    backgroundColor:"red"
                        }
                    }}                   
                    disableColumnFilter
                    disableColumnMenu
                    checkboxSelection
                    disableRowSelectionOnClick
                    initialState={{ pinnedColumns: {right: ['Action'] } }}
                    slots={{
                        toolbar: QuickSearchToolbar,
                        footer: Footer,
                        noRowsOverlay: NoRowsOverlay,
                        // loadingOverlay: SkeletonLoading
                    }}
                    slotProps={{
                        toolbar: {
                            value: searchText,
                            "options": props['options'],
                            onChange: (event) =>
                            requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                        footer: {
                            ...props,                           
                        }

                    }}
                />
            </Box>            
        </div>
    );
}

export default Datatable