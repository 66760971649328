import infinityLoader from '../../assets/svg/infinite-spinner.svg'
import gearLoader from '../../assets/svg/bouncing-circles.svg'


export default function PreLoading(props){

    return(
        <div style={{height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}} id="spinner">
                <img src={gearLoader}  width={'30%'} height={'30%'} alt="" />
                {/* <h1>Loading ...</h1> */}
        </div>
    );
}