import axios from '../../config/axiosConfig'

class AuthService{
    login(data){
        return axios.post('/v1/auth/login',{...data},{skipAuthRefresh: true});
    } 

    logout(){
        return axios.get('/v1/auth/logout')
    }
    autoLogin(){
        return axios.get('/v1/auth/autoLogin')
    }
}

export default new AuthService();