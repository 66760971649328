import Datatable from "../../components/datatable/Datatable"
import { Box } from "@mui/system"
import { useEffect, useRef, useState } from "react";
import saleService from "../../service/api/saleService";
import Action from "../../components/datatable/Action";
import Model from "../../components/model/Model";
import { useSnackbar } from "notistack";
import FullScreenDialog from "../../components/model/FullScreenModel";
import SaleForm from "../../components/forms/sale/SaleForm";
import printUtil from "../../util/printUtil";
import commonUtil from "../../util/commonUtil";
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import { Chip } from "@mui/material";

export default function ListSale(){
    const defaultData = { "data": [],  "pageNumber": 0, "pageSize": 0, "totalPages": 0, "totalElements": 0 };
    const [saleData, setSaleData] = useState(defaultData)
    const [openModel, setOpenModel] = useState(false)
    const [modelTitle, setModelTitle] = useState('')
    const [row, setRow] = useState({})
    const [gridLoading, setGridLoading]  = useState(false);
    const currentPage = useRef(1);
    const currentRows = useRef(5);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', headerName: 'ID', width:"60" },
        {
            field: 'invoiceNo',
            headerName: 'Invoice No',
            editable: false,
            width:"130"
        },
        {
            field: 'invoiceDate',
            headerName: 'Date',
            editable: false,
            type: 'dateTime',
            width:"190",
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'customer',
            headerName: 'Customer',
            valueFormatter: ({ value }) => value.companyName ?? value.firstName ,
            editable: false,
        },
        
        {
            field: 'grandTotal',
            headerName: 'Grand Total',
            editable: false,
        },
        {
            field: 'discountAmount',
            headerName: 'Discount',
            editable: false,
        },
        {
            field: 'paymentStatus',
            headerName: 'Payment',
            editable: false,
            width:"110",
            renderCell:({value}) => <Chip size="small" label={value} color={value === 'PAID'?'success':'error'} />
        },
        {
            field: 'amountPaid',
            headerName: 'Paid',
            editable: false,
        },
        {
            field: 'amountPending',
            headerName: 'Pending',
            editable: false,
        },
        {
            field: 'Action',
            headerName: 'Action',
            editable: false,
            width: 140,
            renderCell: (params) => (
               <Action handleEdit={handleOpenEditModel} handleDelete={deleteSale} moreActions={moreActions} row={params.row} ></Action>
            )
        }
    ];   

    const handleDownloadClick = (id,fileName) =>{
        saleService.getPdfInvoice(id).then(res=>{
            commonUtil.downloadFile(res.data, fileName + ".pdf")            
        })
    }

    const handlePrintClick = (id,fileName) =>{
        saleService.getPdfInvoice(id).then(res=>{           
            printUtil.printPdf([res.data])
        })
    }

    const moreActions = [
        {label:'Print', icon: <PrintIcon/> , handler:handlePrintClick},
        {label:'Download', icon: <DownloadIcon/> , handler:handleDownloadClick},
    ]

    const getAllPageable = async (page, size) => {
        setGridLoading(true);
        return saleService.getAllPageable(page, size).then((res) => {
            setGridLoading(false);
            setSaleData(res.data)
        }).catch((error)=>{
            if(error.response.status===404){
                setGridLoading(false);
                setSaleData(defaultData);
            }
        })
        
    }

    const addSale = async (data) => {
        return saleService.create(data).then((res) => {
            enqueueSnackbar("New Sale Added",{variant:'success'})
            handleCloseModel();
            currentPage.current=1            
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Sale Already Exist",{variant:'warning'});
                handleCloseModel();
            }
        })
    }

    const addSaleAndPrint = async (data) => {
        return saleService.createAndGetInvoice(data).then((res) => {
            enqueueSnackbar("New Sale Added",{variant:'success'})
            printUtil.printPdf([res.data])
            handleCloseModel();
            currentPage.current=1            
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Sale Already Exist",{variant:'warning'});
                handleCloseModel();
            }
        })
    }
    
    const updateSale = async (data) => {
        return saleService.update(data).then((res) => {
            enqueueSnackbar("Sale Updated",{variant:'success'})
            handleCloseModel();
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'}); 
                currentPage.current=1           
                getAllPageable(currentPage.current,currentRows.current);
            }else if(error.response.status === 409){
                enqueueSnackbar("Sale Already Exist",{variant:'warning'});
                handleCloseModel();
            }
            
        })
    }

    const deleteSale = async (data) =>{
        return saleService.delete(data.id).then((res)=>{            
            getAllPageable(currentPage.current,currentRows.current);
            enqueueSnackbar("Sale Deleted",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){
                setSaleData(defaultData);
            }else if(error.response.status === 409){
                enqueueSnackbar("Couldn't Delete Sale",{variant:'warning'});                
            }
        })
    }

    const handleOpenAddModel = () =>{
        setModelTitle('Add Sale')
        setOpenModel(true)
    }

    const handleOpenEditModel = (data) =>{
        setModelTitle('Edit Sale')
        setOpenModel(true)
        setRow(data)
    }

    const handleCloseModel = () =>{
        setOpenModel(false);
        setRow({});
    }
   
    useEffect(() => {
        getAllPageable(currentPage.current,currentRows.current)
    }, [])

    return (
        <div>            
            <Box>
                <Datatable rows={saleData} columns={columns} loading={gridLoading} options={{"title":"Sale", "handleAddButtonClick":handleOpenAddModel,"currentpage":currentPage,"currentRows":currentRows}} getApi={getAllPageable} />
            </Box>            
            <FullScreenDialog title={modelTitle} open={openModel} close={handleCloseModel}>
                    <SaleForm handleAdd={addSale} handleUpdate={updateSale} handleSaveAndPrint={addSaleAndPrint} row={row}></SaleForm>
            </FullScreenDialog>

        </div>
    )
}