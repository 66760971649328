import axios from '../../config/axiosConfig'

class ExpenseService {

    getAllPageable(page,size) {
        return axios.get(`/v1/pageable/expense?page=${page}&size=${size}`);
    }

    getAll(){
        return axios.get('/v1/expense');
    }

    create(data) {
        return axios.post('/v1/expense', data);
    }

    update(data) {
        return axios.put(`/v1/expense/${data.id}`, data);
    }
    
    delete(id) {
        return axios.delete(`/v1/expense/${id}`);
    }
}

export default new ExpenseService();