import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

export default function Model(props)  {
  return (
    <Dialog open={props.open} onClose={props.close} fullWidth>
      <DialogTitle>{props.title} 
      <IconButton aria-label="close" onClick={props.close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],   
            "&:hover": {color:'red'}      
          }}
        >
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
};
