import { useEffect, useState } from 'react'; 
import { styled, alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { AppBar, ListItemIcon } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import ProfileCard from '../cards/ProfileCard';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

import Logo from "../../assets/logo/logo.png"
import authService from '../../service/api/authService';
import { Badge } from '@mui/base';
import { color } from '@mui/system';
import userService from '../../service/api/userService';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, setISLoggedIn } from '../../redux/reducer/user';
import { Link } from 'react-router-dom';
import { purgeAllData } from '../../redux/stores/stores';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import moment from 'moment-timezone';
import Clock from '../ui/Clock';
const pages = ['Products', 'Pricing', 'Blog'];
const settings = [
    { label:'Account', icon: <ManageAccountsIcon/>, path:"user/account" },
    { label:'Settings', icon: <SettingsIcon/>, path:"settings" },   
];

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#EAEBF0",
    borderColor: "#EAEBF0",
    borderWidth: 2,
    // '&:hover': {
    //     backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function TopNav(params) { 
    const dispatch = useDispatch();
    const {userData} = useSelector((state)=>state.user); 
    useEffect(()=>{
        if(userData === null || userData ===undefined)
        userService.getCurrentUser().then(res=>{
           dispatch(addUser(res.data))
        })
    },[userData])

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogoutClick = async () => {        
        handleCloseUserMenu();        
        return authService.logout().then(res=>{
            dispatch(setISLoggedIn(false));
            purgeAllData()
            window.location.reload();            
        })        
    }

    return (
        <AppBar elevation={2} >
            <Toolbar sx={{ backgroundColor: "rgb(255, 255, 255)"}}  >
                <Grid container spacing={0} >
                    <Grid item xs={2} sx={{ display: "flex" }}>
                        <div>
                            <IconButton
                                sx={{ color: "#3F6CEB" }}
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => params.setOpens()}
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                        <div style={{marginTop:"auto"}}>  
                            <img style={{ width: "100%" }} src={Logo} />
                        </div>
                        <div style={{alignSelf: 'self-end'}}>
                            <h4 style={{color:'rgb(49, 49, 49)',margin:0}} >Beta</h4>
                        </div>

                    </Grid>
                    <Grid item xs={4}>
                        {/* <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search> */}
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "end" ,marginTop:"auto",marginBottom:"auto"}}>
                        <Box >
                            <Clock/>
                            <IconButton sx={{marginRight:2,}} >
                                <NotificationsNoneOutlinedIcon fontSize='large'/>
                            </IconButton>
                            <Tooltip>
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar sx={{ backgroundColor: "#7DA0FA", borderColor: "#7DA0FA" }} alt={userData?.firstName}>
                                    {userData?.firstName[0]}
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <ProfileCard userData={userData} />
                                </div>
                                {settings.map((setting) => (                                  
                                    <Link key={setting.label} style={{textDecoration: 'none', textAlign : 'center'}} onClick={handleCloseUserMenu} to={setting.path}> 
                                        <MenuItem >
                                        <ListItemIcon>
                                            {setting.icon}
                                        </ListItemIcon>
                                            <Typography color={"#60626E"} textAlign="center"> {setting.label}</Typography>
                                        </MenuItem> 
                                    </Link>                                    
                                ))}
                                <MenuItem onClick={handleLogoutClick}>         
                                    <ListItemIcon>
                                        <LogoutIcon/>   
                                    </ListItemIcon>                                                       
                                        <Typography color={"#60626E"} textAlign="center"> Logout </Typography>
                                    </MenuItem> 
                            </Menu>
                        </Box>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default TopNav;