import { Alert, Box, Button, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import DatePicker from "../../ui/form/datepicker/DatePicker";
import barcodeService from "../../../service/api/barcodeService";
import NamedAutoComplete from "../../ui/form/select/NamedAutoComplete";
import productService from "../../../service/api/productService";
import { useState } from "react";
import DecimalField from "../../ui/form/number/DecimalField";
import { useDispatch, useSelector } from "react-redux";
import { clearBulkStock, setBulkStock, setBulkStockOnUpdate } from "../../../redux/reducer/bulkStock";
import ItemsDataTable from "../../datatable/ItemsDataTable";
import Action from "../../datatable/Action";
import {validateDates} from "../../../util/commonUtil";
import GetAppIcon from '@mui/icons-material/GetApp';

export default function AddBulkStock({handleAdd}){    

    const dispatch =useDispatch()

    const [productSearchData, setProductSearchData] = useState([]);
    const { register, handleSubmit, resetField, setValue, reset, control, formState: { errors, isSubmitting } } = useForm();
    const rows = useSelector((state) => state.bulkStock.bulkStockDataArr);


    const columns = [
        {
          field: "id",
          headerName: "ID",
          width: 70,
          renderCell: (index) =>
            index.api.getRowIndexRelativeToVisibleRows(index.row.id) + 1,
        },
        {
          field: "productName",
          headerName: "Product Name",
          width: 350,
          editable: false,
        },
        {
          field: "batchNumber",
          headerName: "Batch Number",
          width: 130,
          editable: true,
        },
        { field: "barcode", headerName: "Barcode", width: 130, editable: true },
        { field: "quantity", headerName: "Quantity", width: 120, editable: true },
        {
          field: "discount",
          headerName: "Discount",
          width: 130,
          editable: true,
          type: "number",
        },
        {
          field: "expireDate",
          headerName: "Expire Date",
          width: 130,
          editable: true,
        },
        {
          field: "manufactureDate",
          headerName: "Manufacture Date",
          width: 180,
          editable: true,
        },        
        {
          field: "Action",
          headerName: "Action",
          editable: false,
          renderCell: (params) => (
            <Action         
              handleDelete={deleteBrand}
              row={params.row}
            ></Action>
          ),
        },
    ];

    const deleteBrand = (data) => {
        dispatch(setBulkStockOnUpdate(rows.filter((val) => val.id !== data.id)));
      };

    const onSubmit = async (data) => {
      // const result = validateDates(data.manufactureDate,data.expireDate);
      const result = true;
      if (result) {
        const productName = productSearchData.find((obj)=> obj.id == data.variantId).name
        dispatch(setBulkStock({...data, productName }));
        reset();
      } 
    };

    const handleOnClickSubmit = async () =>{
        await handleAdd(rows)
        reset()
        dispatch(clearBulkStock());
    }
  
    const handleGenerateBarcode = async () =>{
        return barcodeService.generateBarcode().then(res=>{
            setValue("barcode",res.data.barcode)
        })
    }

    const handleProductSearchOnChange = (e)=>{
        if(e?.target?.value){
        //   setSearchInput(e.target.value)          
          productService.search(e.target.value,'PURCHASE').then(res=>{
              setProductSearchData(res.data)
          }).catch(errors=>{
  
          })          
        }else{
        //   setSearchInput('')          
        }        
      }

    return(
      <>        
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} padding={1}>
                <Grid item xs={3}>
                <NamedAutoComplete
                    control={control}
                    onInputChange={(e)=>{handleProductSearchOnChange(e)}}
                    required={true}
                    error={errors["variantId"]}
                    errorMessage='Select a product'                 
                    options={productSearchData}
                    size="small"
                    label="Product"
                    name={`variantId`}
                    InputLabelProps={{ shrink: true}}            

                />
                </Grid>
                <Grid item xs={3}>
                    <TextField                        
                        error={errors["batchNumber"]}
                        {...register("batchNumber", { required: true })}
                        fullWidth
                        label="Batch Number"
                        size="small"
                        name="batchNumber"
                        InputLabelProps={{ shrink: true}}            
                        helperText={errors["batchNumber"]?"Enter batch number" : " "} 
                        FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
                    />
                </Grid>
                <Grid item xs={2}>                  
                    <TextField
                        error={errors["barcode"]}
                        size='small'
                        {...register("barcode", { required: true })}
                        fullWidth
                        label="Barcode"
                        name="barcode"
                        InputLabelProps={{ shrink: true }}
                        helperText={errors["barcode"]?"Enter / Generate barcode" : " "} 
                        FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
                        InputProps={{
                          endAdornment:  (
                            <InputAdornment position="end"> <IconButton sx={{padding:0}} aria-label="toggle password visibility" onClick={handleGenerateBarcode}>
                            <GetAppIcon/>
                          </IconButton> </InputAdornment>
                          ),
                        }}
                    />
                                          
                </Grid>
                <Grid item xs={2}>
                    <DecimalField
                        control={control}
                        required={true}
                        name="quantity"
                        label="Quantity"
                        size="small"
                        error={errors["quantity"]} 
                        errorMessage='Enter product quantity'
                    /> 
                </Grid>
                <Grid item xs={2}>                    
                    <DecimalField
                        control={control}
                        defaultValue='0'
                        name="discount"
                        label="Discount"
                        size="small"
                        error={errors["discount"]}    
                        endAdornmentVal={"%"}   
                    /> 
                </Grid>
                <Grid item xs={3}>
                    <DatePicker
                        name="manufactureDate"
                        label="Manufacture Date"
                        error={errors["manufactureDate"]}
                        control={control}
                        size="small"
                    />
                </Grid>    
                <Grid item xs={3}>
                    <DatePicker                        
                        name="expireDate"
                        label="Expire Date"
                        error={errors["expireDate"]}
                        control={control}
                        size="small"
                    />
                </Grid>            
                <Grid item xs={3} sx={{alignSelf:'center'}}>
                    <Button
                        variant="contained"
                        disabled={isSubmitting}
                        type="submit"
                        size="small"
                    >
                        Add Stock
                    </Button>
                
                </Grid>            
            </Grid>        
        </form>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                <div>
                  <p> Added Stock List</p>
                </div>
                <div style={{alignSelf:'center'}}>
                  <Button variant="contained" disabled={!rows.length} onClick={handleOnClickSubmit} size="small">Submit</Button>
                   {/* <Button variant="outlined" type="button" onClick={props.close} size="small">Cancel</Button> */}
                </div>
            </Box>
        </Box>
        <ItemsDataTable rows={rows} columns={columns} />
      </>
    )
}