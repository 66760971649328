import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import locationService from "../../service/api/locationService";
import NamedAutoComplete from "../ui/form/select/NamedAutoComplete";
import LeftTextDivider from "../ui/divider/LeftTextDivider";
import CustomSelect from "../ui/form/select/CustomSelect";
import commonService from "../../service/api/commonService";
import TextBox from "../ui/form/TextBox";

export default function UserForm(props) {
  const { handleAdd, handleUpdate, row } = props;

  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const passwordRef = useRef();
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    clearErrors,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();
  const handelResetField = () => {
    reset();
    setValue("countryId", 1);
  };

  const getAllCountry = () => {
    locationService.getAllCountry().then((res) => {
      setCountryData(res.data);
    });
  };

  const getAllRole = () => {
    commonService.getAllRole().then((res) => {
      setRoleData(res.data);
    });
  };

  const getStateByCountry = (id) => {
    locationService.getStateByCountry(id).then((res) => {
      setStateData(res.data);
    });
  };

  const getDistrictByState = (id) => {
    locationService.getDistrictByState(id).then((res) => {
      setDistrictData(res.data);
    });
  };

  const handleStateOnChange = (value) => {
    if (value) {
      setDistrictData([]);
      getDistrictByState(value?.id);
    }
  };

  const onSubmit = async (data) => {
    if (row["id"]) {
      await handleUpdate({ ...data, id: row.id });
    } else {
      await handleAdd(data);
      handelResetField();
    }
  };

  useEffect(() => {
    getAllRole();
    getAllCountry();
    getStateByCountry(1);
    if (row["id"]) {
      getDistrictByState(row.state.id);
      setValue("firstName", row.firstName);
      setValue("lastName", row.lastName);
      setValue("username", row.username);
      setValue("password", "123");
      setValue("mobile", row.mobile);
      setValue("email", row.email);
      setValue("gender", row.gender);
      setValue("roleId", row.role.id);
      setValue(`street`, row.street);
      setValue(`village`, row.village);
      setValue(`landmark`, row.landmark);
      setValue(`pincode`, row.pincode);
      setValue(`countryId`, row.country.id);
      setValue(`stateId`, row.state.id);
      setValue(`districtId`, row.district.id);
    } else {
      handelResetField();
    }
  }, [row]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} padding={1}>
          <Grid item xs={3}>            
            <TextBox              
              error={errors["firstName"]}
              control={control}
              required={true}
              label="First Name"
              size="small"
              name="firstName"
              errorMessage="Enter First Name"
            />
          </Grid>
          <Grid item xs={3}>
            <TextBox
              error={errors["lastName"]}    
              control={control}          
              label="Last Name"
              size="small"
              name="lastName"
            />
          </Grid>
          <Grid item xs={3}>
            <TextBox
              error={errors["username"]}
              required={true}  
              control={control}            
              label="Username"
              size="small"
              name="username"
              errorMessage="Enter UserName"
            />
          </Grid>
          <Grid item xs={3}>
            <TextBox
              type="number"
              control={control}
              required={true}
              error={errors["mobile"]}
              label="Mobile"
              size="small"
              name="mobile"
              errorMessage="Enter the user mobile"
            />
          </Grid>

          <Grid item xs={3}>
            <TextBox
              type="email"
              control={control}
              required={true}
              error={errors["email"]}
              label="Email"
              size="small"
              name="email"
              errorMessage="Enter the user email address"
            />
          </Grid>

          <Grid item xs={1}>
            <CustomSelect
              name="gender"
              error={errors["gender"]}
              required={true}
              control={control}
              label="Gender"
              size="small"
              errorMessage="Choose the gender"
            >
              <MenuItem value={"MALE"}>Male</MenuItem>
              <MenuItem value={"FEMALE"}>Female</MenuItem>
            </CustomSelect>
          </Grid>

          <Grid item xs={2}>
            <NamedAutoComplete
              control={control}
              required={true}
              error={errors["roleId"]}
              errorMessage="Select role"
              options={roleData}
              size="small"
              label="Role"
              name={`roleId`}
            />
          </Grid>
          {!row["id"] && (
            <>
              <Grid item xs={3}>
                <TextField
                  inputRef={passwordRef}
                  onChange={(e) => {
                    if (getValues("password") !== e.target.value) {
                      setError("password", "Password doesn't match");
                    } else {
                      clearErrors("password");
                    }
                  }}
                  type="password"
                  fullWidth
                  label="Password"
                  size="small"
                />
              </Grid>

              <Grid item xs={3}>
                <TextBox
                  name="password"
                  type="password"
                  error={errors["password"]}
                  control={control}
                  required={!row["id"]}                 
                  onChange = {(e) => {
                    if (passwordRef.current.value !== e.target.value) {
                      setError("password", "Password doesn't match");
                    } else {
                      clearErrors("password");
                    }
                  }}                  
                  fullWidth
                  label="Confirm Password"
                  size="small"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <LeftTextDivider>Address</LeftTextDivider>
          </Grid>
          <Grid item xs={3}>
            <TextBox
              error={errors["street"]}
              control={control}
              required={true}
              errorMessage="Enter the street"
              fullWidth
              label="Street"
              size="small"
              name="street"
            />
          </Grid>

          <Grid item xs={3}>
            <TextBox
              error={errors["village"]}
              control={control}
              required={true}
              errorMessage="Enter the village"
              label="Village"
              size="small"
              name="village"
            />
          </Grid>

          <Grid item xs={3}>
            <TextBox
              error={errors["landmark"]}
              control={control}
              label="Landmark"
              size="small"
              name="landmark"
            />
          </Grid>

          <Grid item xs={3}>
            <TextBox
              type="number"
              error={errors["pincode"]}
              control={control}
              required={true}
              sx={{ width: "100%" }}
              label="Pin Code"
              size="small"
              errorMessage="Enter the area pincode"
              name="pincode"
            />
          </Grid>

          <Grid item xs={3}>
            <NamedAutoComplete
              control={control}
              required={true}
              error={errors["countryId"]}
              options={countryData}
              size="small"
              label="Country"
              name={`countryId`}
              errorMessage="Select Country"
            />
          </Grid>

          <Grid item xs={3}>
            <NamedAutoComplete
              control={control}
              required={true}
              error={errors["stateId"]}
              options={stateData}
              size="small"
              label="State"
              name={`stateId`}
              handelOnChange={handleStateOnChange}
              errorMessage="Select State"
            />
          </Grid>

          <Grid item xs={3}>
            <NamedAutoComplete
              control={control}
              required={true}
              error={errors["districtId"]}
              options={districtData}
              size="small"
              label="District"
              name={`districtId`}
              errorMessage="Select District"
            />
          </Grid>

          <Grid   item  xs={12}   sx={{ textAlign: "end", position: "fixed", bottom: 25, right: 50 }} >
            <Button
              variant="contained"
              disabled={isSubmitting}
              type="submit"
              size="small"
            >
              {row["id"] ? "Edit" : "Submit"}
            </Button>
            {/* <Button variant="outlined" type="button" onClick={props.close} size="small">Cancel</Button> */}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
