import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuList: [],
};

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenuList: (state, action) => {
      state.menuList = action.payload;
    },
  },
});

export const { setMenuList } = menu.actions;

export default menu.reducer;
