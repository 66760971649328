import StorageIcon from '@mui/icons-material/Storage';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FireTruckOutlinedIcon from '@mui/icons-material/FireTruckOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ShapeLineOutlinedIcon from '@mui/icons-material/ShapeLineOutlined';

const icons={
    "StorageIcon" : <StorageIcon/>,
    "ContactMailOutlinedIcon" : <ContactMailOutlinedIcon/>,
    "DashboardOutlinedIcon":<DashboardOutlinedIcon/>,
    "FireTruckOutlinedIcon":<FireTruckOutlinedIcon/>,
    "PersonOutlineOutlinedIcon":<PersonOutlineOutlinedIcon/>,
    "Inventory2OutlinedIcon":<Inventory2OutlinedIcon/>,
    "PointOfSaleOutlinedIcon":<PointOfSaleOutlinedIcon/>,
    "AddShoppingCartOutlinedIcon":<AddShoppingCartOutlinedIcon/>,
    "QueryStatsOutlinedIcon":<QueryStatsOutlinedIcon/>,
    "SettingsIcon":<SettingsIcon/>,
    "CurrencyRupeeIcon" : <CurrencyRupeeIcon/>,
    "RequestQuoteOutlinedIcon" : <RequestQuoteOutlinedIcon/>,
    "ShapeLineOutlinedIcon":<ShapeLineOutlinedIcon/>
}

export default icons;