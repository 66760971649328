import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteAlert from "../model/DeleteAlert";
import { useState } from "react";
import saleService from "../../service/api/saleService";
import fileDownload from "js-file-download";
import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function Action({ handleEdit, handleDelete, row, moreActions }) {
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [OpenDeleteAlert, setOpenDeleteAlert] = useState(false);

  const handleOpen = () => {
    setOpenDeleteAlert(true);
  };

  const handleClose = () => {
    setOpenDeleteAlert(false);
  };

  const handleClickDelete = () => {
    handleDelete(row);
    handleClose();
  };

  const handleOpenMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <div>
      {handleEdit && (
        <IconButton
          sx={{ "&:hover": { color: "blue" } }}
          onClick={() => {
            handleEdit(row);
          }}
        >
          <EditIcon></EditIcon>
        </IconButton>
      )}
      <IconButton sx={{ "&:hover": { color: "red" } }} onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      {moreActions && (
        <>
          <IconButton
            sx={{ "&:hover": { color: "blue" } }}
            onClick={handleOpenMenu}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-more-action"
            anchorEl={anchorElMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElMenu)}
            onClose={handleCloseMenu}
          >
            {moreActions.map((moreAction) => (
              <MenuItem
                key={moreAction.label}
                onClick={() => {
                  moreAction.handler(row.id, row.invoiceNo);
                }}
              >
                <ListItemIcon>{moreAction.icon}</ListItemIcon>
                <Typography textAlign="center">{moreAction.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}

      {handleDelete && (
        <DeleteAlert
          open={OpenDeleteAlert}
          handleClose={handleClose}
          handleClickDelete={handleClickDelete}
        />
      )}
    </div>
  );
}
