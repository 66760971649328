export const decimalControl = (value) =>{
   return parseFloat(value.toFixed(2))
}

export const getTaxAmount=(taxType,taxPercentage, price)=>{      
  if(taxType === 'EXCLUSIVE'){
    return (price / 100) * taxPercentage;
  }else if(taxType === 'INCLUSIVE'){
    return price * taxPercentage /(100 + taxPercentage);
  }
}
