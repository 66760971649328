import axios from '../../config/axiosConfig'

class UserService{
    getCurrentUser(){
        return axios.get('/v1/user/get-current-user')
    }
    getAllPageable(page,size) {
        return axios.get(`/v1/pageable/user?page=${page}&size=${size}`);
    }

    create(data) {
        return axios.post('/v1/user', data);
    }

    update(data) {
        return axios.put(`/v1/user/${data.id}`, data);
    }

    delete(id) {
        return axios.delete(`/v1/user/${id}`);
    }
}

export default new UserService();