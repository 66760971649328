import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    settings: [],
};

const Settings = createSlice({
    name: "settings",
    initialState,
    reducers: {
        addsettings: (state, action) => {
            state.settings = action.payload
        },
        updatesettings: (state, action) => {
            state.settings = action.payload
        },
        deleteSettings: (state, action) => {
            state.settings = []
        },
    },
});

export const { addsettings, updatesettings ,deleteSettings} = Settings.actions;

export default Settings.reducer;