import { InputAdornment, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function TextBox({name,label,error,size,control,defaultValue,handleOnChange,onBlur,required,endAdornmentVal,errorMessage = " ", ...props}){  
    return(
      <Controller
        name={name}
        rules={{required}}
        defaultValue={defaultValue ? defaultValue : ''}
        control={control}
        render={({field : { onChange, value }}) => (
          <TextField
            {...props}
            type="text"
            value={value}
            InputLabelProps={{ shrink: true}}
            size={size}
            error={error}
            helperText={error?errorMessage : " "}
            FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
            onChange={e=>{
                onChange(e.target.value)
                if(handleOnChange){
                  handleOnChange(e)
                }
              }
            }
            onBlur={onBlur}
            fullWidth
            label={label}
            variant="outlined"
            InputProps={{
              endAdornment: endAdornmentVal && (
                <InputAdornment position="end">{endAdornmentVal}</InputAdornment>
              ),
            }}
          />
        )}
      />
    
    )
}