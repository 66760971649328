import Datatable from "../../components/datatable/Datatable"
import { Box } from "@mui/system"
import { useEffect, useRef, useState } from "react";
import GSTForm from "../../components/forms/master/GSTForm";
import gstService from "../../service/api/gstService";
import Action from "../../components/datatable/Action";
import Model from "../../components/model/Model";
import { useSnackbar } from "notistack";

export default function GST() {
    const defaultData = { "data": [],  "pageNumber": 0, "pageSize": 0, "totalPages": 0, "totalElements": 0 };
    const [gstData, setGSTData] = useState(defaultData)
    const [openModel, setOpenModel] = useState(false)
    const [modelTitle, setModelTitle] = useState('')
    const [gridLoading, setGridLoading]  = useState(false);
    const [row, setRow] = useState({})
    const currentPage = useRef(1);
    const currentRows = useRef(5);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', headerName: 'ID',width:"70" },
        {
            field: 'name',
            headerName: 'Name',
            editable: false,
        },
        {
            field: 'percentage',
            headerName: 'Percentage',
            editable: false,
        },
        {
            field: 'description',
            headerName: 'Description',
            editable: false,
            width:300
        },
        // {
        //     field: 'Action',
        //     headerName: 'Action',
        //     editable: false,
        //     renderCell: (params) => (
        //        <Action handleEdit={handleOpenEditModel} handleDelete={deleteGST} row={params.row} ></Action>
        //     )
        // }
    ];   

    const getAllPageable = async (page, size) => {
        setGridLoading(true);        
        return gstService.getAllPageable(page, size).then((res) => {
            setGridLoading(false);
            setGSTData(res.data) 
        }).catch((error)=>{
            if(error.response.status===404){
                setGridLoading(false);
                setGSTData(defaultData);
            }
        })
    }

    const addGST = async (data) => {
        return gstService.create(data).then((res) => {
            enqueueSnackbar("New GST Added",{variant:'success'})
            handleCloseModel();
            currentPage.current=1            
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("GST Already Exist",{variant:'warning'});
                handleCloseModel();
            }
        })
    }
    
    const updateGST = async (data) => {
        return gstService.update(data).then((res) => {
            enqueueSnackbar("GST Updated",{variant:'success'})
            handleCloseModel();
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'}); 
                currentPage.current=1           
                getAllPageable(currentPage.current,currentRows.current);
            }else if(error.response.status === 409){
                enqueueSnackbar("GST Already Exist",{variant:'warning'});
                handleCloseModel();
            }
            
        })
    }

    const deleteGST = async (data) =>{
        return gstService.delete(data.id).then((res)=>{            
            getAllPageable(currentPage.current,currentRows.current);
            enqueueSnackbar("GST Deleted",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){
                setGSTData(defaultData);
            }else if(error.response.status === 409){
                enqueueSnackbar("Couldn't Delete GST",{variant:'warning'});                
            }
        })
    }

    const handleOpenAddModel = () =>{
        setModelTitle('Add GST')
        setOpenModel(true)
    }

    const handleOpenEditModel = (data) =>{
        setModelTitle('Edit GST')
        setOpenModel(true)
        setRow(data)
    }

    const handleCloseModel = () =>{
        setOpenModel(false);
        setRow({});
    }
   
    useEffect(() => {
        getAllPageable(currentPage.current,currentRows.current)
    }, [])

    return (
        <div>            
            <Box>
                <Datatable rows={gstData} columns={columns} loading={gridLoading} options={{"title":"GST", "currentpage":currentPage,"currentRows":currentRows}} getApi={getAllPageable} />
            </Box>            
            <Model title={modelTitle} open={openModel} close={handleCloseModel}>
                    <GSTForm handleAdd={addGST} handleUpdate={updateGST} row={row}></GSTForm>
            </Model>
        </div>
    )
}