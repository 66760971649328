import { useEffect } from 'react';
import Button from '@mui/material/Button';
import { CircularProgress, TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Grid';
import TextBox from '../../ui/form/TextBox';

export default function BrandForm(props) {  
    const {handleAdd, handleUpdate, row}   = props;
    const { register, handleSubmit, resetField, setValue, control, formState: { errors, isSubmitting } } = useForm();
    
    const handelResetField = () => {
        resetField("name")
        resetField("description")
    };

    const onSubmit = async data => {       
        if(row['id']){
            await handleUpdate({...data,id:row.id})
        }else{
            await handleAdd(data)
        }       
    };

    useEffect(()=>{ 
        if(row['id']){           
        setValue("name",row.name)
        setValue("description",row.description)
        }else{
            handelResetField();
        }
    },[row])

    return (
        <div>            
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container spacing={2} padding={1}>
                    <Grid item xs={12}>
                        <TextBox error={errors['name']} control={control} required={true} label="Name" size='small' name='name' errorMessage='Enter a brand name' />
                    </Grid>
                    <Grid item xs={12}>
                        <TextBox error={errors['description']} control={control} label="Description" size='small' name='description' />
                    </Grid>
                    <Grid   item  xs={12}   sx={{ textAlign: "end", bottom: 25, right: 50 }} >
                        <Button variant="contained" disabled={isSubmitting} type="submit" size="small" > { row['id']?"Edit":"Submit"}</Button>
                        {/* <Button variant="outlined" type="button" onClick={props.close} size="small">Cancel</Button> */}
                    </Grid>
                </Grid>
            </form>
               
        </div>
    );
}