import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { CircularProgress, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CheckboxLabeled from "../ui/form/checkbox/CheckboxLabeled";
import locationService from "../../service/api/locationService";
import NamedAutoComplete from "../ui/form/select/NamedAutoComplete";
import LeftTextDivider from "../ui/divider/LeftTextDivider";

export default function SupplierForm(props) {
  const { handleAdd, handleUpdate, row } = props;

  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const {
    register,
    control,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, isSubmitting},
  } = useForm({
    defaultValues: {
      account: false,
    },
  });
 
  const handelResetField = () => {
    reset();           
    // setValue("account",false)   
    setValue("countryId",1)
  }; 

  const getAllCountry = () => {
    locationService.getAllCountry().then((res) => {
      setCountryData(res.data);
    });
  };

  const getStateByCountry = (id) => {
    locationService.getStateByCountry(id).then((res) => {
      setStateData(res.data);
    });
  };

  const getDistrictByState = (id) => {
    locationService.getDistrictByState(id).then((res) => {
      setDistrictData(res.data);
    });
  };

  const handleStateOnChange = (value) => {
    if (value) {
      setDistrictData([]);
      getDistrictByState(value?.id);
    }
  };

  const onSubmit = async (data) => {    
    if (row?.id) {
        await handleUpdate({ ...data, id: row.id });
    } else {
      await handleAdd(data);
      handelResetField()
    }
  };

  useEffect(() => {
    getAllCountry();
    getStateByCountry(1);
    if (row?.id) {
      getDistrictByState(row.state.id)
      setValue("companyName", row.companyName);
      setValue("gstNo", row.gstNo);
      setValue("mobile", row.mobile);
      setValue("email", row.email);
      setValue("phone", row.phone);
      setValue("backAccountNo", row.backAccountNo);
      setValue("ifscCode", row.ifscCode);
      setValue(`street`,row.street)
      setValue(`village`,row.village)
      setValue(`landmark`,row.landmark)
      setValue(`pincode`,row.pincode)
      setValue(`countryId`,row.country.id)
      setValue(`stateId`,row.state.id)
      setValue(`districtId`,row.district.id)
    } else {
      handelResetField();
    }
  }, [row]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>        
        <Grid container spacing={2} padding={1}>
          <Grid item xs={5}>
            <TextField
              autoFocus
              error={errors["companyName"]}
              {...register("companyName", { required: true })}
              sx={{ width: "100%" }}
              label="Company Name"
              size="small"
              name="companyName"
              helperText={errors["companyName"]?"Enter the company name" : " "} 
              FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              error={errors["gstNo"]}
              {...register("gstNo", { required: true })}
              sx={{ width: "100%" }}
              label="GST No"
              size="small"
              name="gstNo"
              helperText={errors["gstNo"]?"Enter the GST number" : " "} 
              FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="number"
              error={errors["mobile"]}
              {...register("mobile", { required: false })}
              sx={{ width: "100%" }}
              label="Mobile"
              size="small"
              name="mobile"
            />
          </Grid>         
          <Grid item xs={3}>
            <TextField
              type="number"
              error={errors["phone"]}
              {...register("phone", { required: false })}
              sx={{ width: "100%" }}
              label="Phone"
              size="small"
              name="phone"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="email"
              error={errors["email"]}
              {...register("email", { required: false })}
              sx={{ width: "100%" }}
              label="Email"
              size="small"
              name="email"
            />
          </Grid>
          <Grid item xs={3}>
            <CheckboxLabeled
              name="account"
              control={control}
              label="Create Credit Account"
            />
          </Grid>
          <Grid item xs={12}>
            <LeftTextDivider>Bank Details</LeftTextDivider>
          </Grid>

          <Grid item xs={4}>
            <TextField             
              error={errors["backAccountNo"]}
              {...register("backAccountNo")}
              sx={{ width: "100%" }}
              label="Account No"
              size="small"
              name="backAccountNo"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              error={errors["ifscCode"]}
              {...register("ifscCode")}
              sx={{ width: "100%" }}
              label="IFSC Code"
              size="small"
              name="ifscCode"
            />
          </Grid>
          <Grid item xs={12}>
            <LeftTextDivider>Address</LeftTextDivider>
          </Grid>
          <Grid item xs={3}>
            <TextField
              error={errors["street"]}
              {...register(`street`, { required: true })}
              fullWidth
              label="Street"
              size="small"
              helperText={errors["street"]?"Enter the street name" : " "} 
              FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              error={errors["village"]}
              {...register(`village`, { required: true })}
              sx={{ width: "100%" }}
              label="Village"
              size="small"
              helperText={errors["village"]?"Enter the village name" : " "} 
              FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              error={errors["landmark"]}
              {...register(`landmark`)}
              sx={{ width: "100%" }}
              label="Landmark"
              size="small"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              type="number"
              error={errors["pincode"]}
              {...register(`pincode`, { required: true })}
              sx={{ width: "100%" }}
              label="Pin Code"
              size="small"
              helperText={errors["pincode"]?"Enter the pincode" : " "} 
              FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
            />
          </Grid>

          <Grid item xs={3}>
            <NamedAutoComplete
              control={control}
              required={true}
              error={errors["countryId"]}
              options={countryData}
              size="small"
              label="Country"
              name={`countryId`}
              errorMessage="Select a country"
            />
          </Grid>

          <Grid item xs={3}>
            <NamedAutoComplete
              control={control}
              required={true}
              error={errors["stateId"]}
              defaultValue={null}
              options={stateData}
              size="small"
              label="State"
              name={`stateId`}
              handelOnChange={handleStateOnChange}
              errorMessage="Select a state"
            />
          </Grid>

          <Grid item xs={3}>
            <NamedAutoComplete
              control={control}
              defaultValue={null}
              required={true}
              error={errors["districtId"]}
              options={districtData}
              size="small"
              label="District"
              name={`districtId`}
              errorMessage="Select a district"
            />
          </Grid>          

          <Grid   item  xs={12}   sx={{ textAlign: "end", position:'fixed', bottom: 25, right: 50 }} >
            <Button
              variant="contained"
              disabled={isSubmitting}
              type="submit"
              size="small"
            >
              {" "}
              {row?.id ? "Edit" : "Submit"}
            </Button>
            {/* <Button variant="outlined" type="button" onClick={props.close} size="small">Cancel</Button> */}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
