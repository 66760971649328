import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Divider, FormControlLabel, IconButton, MenuItem, Switch, TextField} from '@mui/material';
import { useFieldArray, useForm } from "react-hook-form";
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import LeftTextDivider from '../../ui/divider/LeftTextDivider';
import CustomerAddressForm from './CustomerAddressForm';
import CustomSelect from '../../ui/form/select/CustomSelect';
import CheckboxLabeled from '../../ui/form/checkbox/CheckboxLabeled';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

export default function CustomerForm(props) {  
    const defaultAddress = [
      {
        // "addressType": "",
        "countryId": 1,
        "street": "",
        "village": "",
        "landmark": "",
        "pincode": "",
        "stateId": "",
        "districtId": ""
      }
    ]
    const {handleAdd, handleUpdate, row}   = props;
    const { register, control, handleSubmit, resetField, reset, setValue, getValues, clearErrors, formState: { errors , isSubmitting} } = useForm({
      defaultValues:{
        "customerType": "INDIVIDUAL",       
        "account":false ,
        // "customerAddress" : defaultAddress
      }
    });  
    const {fields,append,remove} = useFieldArray({
      control,
      name: "customerAddress",  
    }); 
    const [required, setRequired] = useState(false)

    const handelResetField = () => {      
      reset();     
      // append(defaultAddress)
    };    
   
    const onSubmit = async (data) => {
      if (row?.id) {
        delete data.customerAddress.country;
        delete data.customerAddress.state;
        delete data.customerAddress.district;
        await handleUpdate({ ...data, id: row.id });
        handelResetField();
      } else {
        await handleAdd(data);
        handelResetField();
      }
    };
   
    useEffect(()=>{
      if(row?.id){
        setValue("firstName", row.firstName)
        setValue("lastName", row.lastName)
        setValue("email", row.email)
        setValue("gender", row.gender)
        setValue("gstNo", row.gstNo)
        setValue("companyName", row.companyName)
        setRequired(row.customerType === "COMPANY" ? true : false)
        setValue("customerType", row.customerType)
        setValue("mobile", row.mobile)     
        setValue("customerAddress",row.customerAddress)   
      }else{          
        handelResetField();
      }
         
    },[row])
    return (
      <div>              
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={4}>              
                <CustomSelect                  
                  name="customerType"
                  control={control}                  
                  label="Customer Type"                  
                  size="small"
                  handelOnChange={(e)=>{                   
                    if(e.target.value === "COMPANY"){ 
                      setRequired(true)
                    }else{
                      setRequired(false)
                      clearErrors("gstNo")
                      clearErrors("companyName")
                    }
                  }}                
                >
                  <MenuItem value={"INDIVIDUAL"}>Individual</MenuItem>
                  <MenuItem value={"COMPANY"}>Company</MenuItem>
                </CustomSelect>           
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={errors["firstName"]}
                {...register("firstName", { required: true })}
                sx={{ width: "100%" }}
                label="First Name"
                size="small"
                name="firstName"
                helperText={errors["firstName"]?"Enter the First Name" : " "} 
                FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={errors["lastName"]}
                {...register("lastName")}
                sx={{ width: "100%" }}
                label="Last Name"
                size="small"
                name="lastName"
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
              type='email'
                error={errors["email"]}
                {...register("email", { required: false })}
                sx={{ width: "100%" }}
                label="Email"
                size="small"
                name="email"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField  
                type='number'        
                error={errors["mobile"]}
                {...register("mobile", { required: false })}
                sx={{ width: "100%" }}
                label="Mobile"
                size="small"
                name="mobile"
                helperText={errors["mobile"]?"Enter the mobile number" : " "} 
                FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={errors["companyName"]}
                disabled={!required}
                {...register("companyName", { required })}
                sx={{ width: "100%" }}
                label="Company Name"
                size="small"
                name="companyName"
                helperText={errors["companyName"]?"Enter the company name" : " "} 
                FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={errors["gstNo"]}
                disabled={!required}
                {...register("gstNo", { required })}
                sx={{ width: "100%" }}
                label="GST No"
                size="small"
                name="gstNo"
                helperText={errors["gstNo"]?"Enter the GST number" : " "} 
                FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
              />
            </Grid>
            <Grid item xs={4}>              
              <CheckboxLabeled 
                name="account"
                control={control}
                label="Create Credit Account"
              />
            </Grid>

            {/* Billing Address */}       
            <Grid item xs={12}>
              <LeftTextDivider>Address 1
                <Button 
                  variant="text"
                  onClick={() => {
                    append(defaultAddress);
                  }}
                  sx={{ color: "green", mr: 2 }}
                  size="small"
                >
                  <AddIcon fontSize="small" /> Add Address
                </Button>
              </LeftTextDivider>
            </Grid> 
            {fields.map((item, index) => (          
              <Grid key={index} container spacing={2} padding={1}>
                {index >0 && <Grid item xs={12}>
                  <LeftTextDivider>Address {index + 1} </LeftTextDivider>
                </Grid> }
                <CustomerAddressForm register={register} control={control} index={index} setValue={setValue} row={row?.customerAddress} errors={errors}/>
                <Grid item xs={2}>
                  <IconButton
                    sx={{ color: "red" }}
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid> 
              </Grid>
            ))} 
           
            <Grid   item  xs={12}   sx={{ textAlign: "end", position: "fixed", bottom: 25, right: 50 }} >
              <Button
                variant="contained"
                disabled={isSubmitting}
                type="submit"
                size="small"
              >
                <SaveIcon fontSize="small" />
                {row?.id ? "Edit" : "Submit"}
              </Button>
              {/* <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                            Save
                        </LoadingButton> */}
              {/* <Button variant="outlined" type="button" onClick={props.close} size="small">Cancel</Button> */}
            </Grid>
          </Grid>
        </form>
      </div>
    );
}

