import Dashboardcard from "../components/cards/Dashboardcards"
import authService from "../service/api/authService"
import { Card, Grid, Box,Typography } from "@mui/material"
import ApexChart from "../components/charts/Apexchart"


const Menus = [
    {
        "Name": "Customers",
        "Revanue": 32124124,

    },
    {
        "Name": "Sales",
        "Revanue": 32124124
    },
    {
        "Name": "Revanue",
        "Revanue": 32124124
    },
    {
        "Name": "Stock",
        "Revanue": 32124124
    }
]

const chartdata = {
    options: {
        chart: {
            id: 'apexchart-example'
        },
        xaxis: {
            categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        }
    },
    series: [{
        name: 'series-1',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
    }]
}


function Dashboard() {
  
    return (
        <div>
            <h1 style={{marginBottom:0}}>Dashboard</h1>
            <p style={{marginTop:0, color:'red'}}>Dashboard Showing dummy data which means this is not real time data. We are hardly working to get the real time data.</p>
            <div>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Grid container spacing={2}>
                            {
                                Menus.map((item, index) => (
                                    <Grid item xs={6} >
                                        <Dashboardcard name={item.Name} Revanue={item.Revanue} />
                                    </Grid>

                                ))
                            }
                        </Grid>

                        <Card sx={{marginTop: 2}}>

                        <Typography variant="h6" sx={{padding:2}} component="div">
                    Invoice
                </Typography>

                            <Box sx={{  padding: 2, height: 217, overflow: "auto" }}>
                                {
                                    Menus.map((item, index) => (
                                        <Box key={index} sx={{ flexGrow: 1, borderBottom: 0.5, marginBottom: 2, borderBottomColor: "#F8F9FB" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <div> General #123</div>
                                                    <div> Surya</div>

                                                </Grid>
                                                <Grid item xs={4}>
                                                    Invoice
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div>
                                                        <div>Total</div>
                                                        <div>324523532</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Card>



                    </Grid>
                    <Grid item xs={8}>
                        <ApexChart options={chartdata.options} series={chartdata.series} type="bar" />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Dashboard