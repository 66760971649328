import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";

export default function CheckboxLabeled(props){
    const {control, name, label, defaultValue, handleOnChange} = props;
    return(
        <FormControlLabel
        control={
          <Controller
            defaultValue={defaultValue ? defaultValue : false}
            name={name}
            control={control}
            render={({ field }) => <Checkbox checked={field['value'] ?? false} {...field} onChange={(e)=>{            
              field.onChange(e)  
              if(handleOnChange) handleOnChange(e)
            }}  />}
          />
        }
        label={label}
      />
    )
}