import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ProfileCard(props) {
  const [expanded, setExpanded] = useState(false);
  const {userData } = props;
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ minWidth: 300,background:"#fff",boxShadow:0,borderBottom:1,borderRadius:0,borderColor:"#EAEBF0" }}>
      <CardHeader
        avatar={
          <Avatar sx={{ backgroundColor: "#7DA0FA", borderColor: "#7DA0FA" }} aria-label="recipe">
            {userData?.firstName[0]}
          </Avatar>
        }

        title={userData?.firstName + ' ' + (userData?.lastName ? userData?.lastName:'')}
        subheader={userData?.role?.name}
   
        // title={userData['firstName']+ ' ' + userData['lastName']}
        // subheader={userData['role']['name']}
      />

    </Card>
  );
}