import { Checkbox, FormControlLabel, Switch } from "@mui/material";
import { Controller } from "react-hook-form";

export default function SwitchLabeled({control, name, label, defaultValue, handleOnChange, ...props}){    
    return(
        <FormControlLabel
        control={
          <Controller
            defaultValue={defaultValue ? defaultValue : false}
            name={name}
            control={control}
            render={({ field }) => <Switch {...props} checked={field['value'] ?? false} {...field} onChange={(e)=>{            
              field.onChange(e)  
              if(handleOnChange) handleOnChange(e)
            }}  />}
          />
        }
        label={label}
      />
    )
}