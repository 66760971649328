import { useEffect, useRef, useState } from "react";
import productService from "../../service/api/productService";
import { useSnackbar } from "notistack";
import ProductForm from "../../components/forms/product/ProductForm";
import { Card, CardContent } from "@mui/material";

export default function AddProduct(){

    const [row, setRow] = useState({})
    const { enqueueSnackbar } = useSnackbar();

    const addProduct = async (data) => {
        return productService.create(data).then((res) => {
            enqueueSnackbar("New Product Added",{variant:'success'})            
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Product Already Exist",{variant:'warning'});               
            }
        })
    }
    
    const updateProduct = async (data) => {
        return productService.update(data).then((res) => {
            enqueueSnackbar("Product Updated",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'});              
            }else if(error.response.status === 409){
                enqueueSnackbar("Product Already Exist",{variant:'warning'});             
            }
            
        })
    }

    return(
        <>
        <h2 style={{marginBottom:0}}>Create Purchase</h2>
        <span style={{fontSize:14,marginBottom:2}}>Home / Dashboard</span>
        <Card>
            <CardContent>
             <ProductForm handleAdd={addProduct} handleUpdate={updateProduct} row={row}/>
        </CardContent>
        </Card>
        </>
    );
}