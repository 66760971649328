import { auto } from "@popperjs/core"
import image from "./../../assets/svg/bug_fixing.svg"

export default function Error() {
    return(
        <div style={{display:'flex', alignContent:'center',height:'80vh'}}>
            <div style={{ margin:'auto', width:'80vh'}}>
            <img style={{width:'90%', display:'block', margin:auto}} src={image} alt="React Logo" />
            <h1 style={{'text-align': 'center'}}>Something Went Wrong</h1>
            <h3 style={{'text-align': 'center', marginBottom:0}}>Kindly Refresh the Page and Try Again</h3>
            <h3 style={{'text-align': 'center', marginTop:0}}>Issue Not Resolved, Kindly Contact Your Administrator</h3>
            </div>
        </div>
    )
}

