
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import Action from "../../components/datatable/Action";
import Datatable from "../../components/datatable/Datatable";
import CustomerForm from "../../components/forms/customer/CustomerForm";
import FullScreenDialog from "../../components/model/FullScreenModel";
import customerService from "../../service/api/customerService";


export default function CreateCustomer() {
    const defaultData = { "data": [],  "pageNumber": 0, "pageSize": 0, "totalPages": 0, "totalElements": 0 };
    const [customerData, setCustomerData] = useState(defaultData)
    const [openModel, setOpenModel] = useState(false)
    const [modelTitle, setModelTitle] = useState('')
    const [row, setRow] = useState({})
    const [gridLoading, setGridLoading]  = useState(false);
    const currentPage = useRef(1);
    const currentRows = useRef(5);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', headerName: 'ID', width:"60" },
        {
            field: 'customerType',
            headerName: 'Type',
            width:"105",
            editable: false,
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            editable: false,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            editable: false,
        },
        {
            field: 'companyName',
            headerName: 'Company',
            editable: false,
            width:"130"
        },
        {
            field: 'mobile',
            headerName: 'mobile',
            editable: false,
        },
        {
            field: 'Action',
            headerName: 'Action',
            editable: false,
            renderCell: (params) => (
               <Action handleEdit={handleOpenEditModel} handleDelete={deleteCustomer} row={params.row} ></Action>
            )
        }
    ];   

    const getAllPageable = async (page, size) => {   
        setGridLoading(true);     
        return customerService.getAllPageable(page, size).then((res) => {
            setGridLoading(false);
            setCustomerData(res.data) 
        }).catch((error)=>{
            if(error.response.status===404){
                setGridLoading(false);
                setCustomerData(defaultData);
            }
        })
    }

    const addCustomer = async (data) => {
        return customerService.create(data).then((res) => {
            enqueueSnackbar("New Customer Added",{variant:'success'})
            handleCloseModel();
            currentPage.current=1            
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Customer Already Exist",{variant:'warning'});
                handleCloseModel();
            }
        })
    }
    
    const updateCustomer = async (data) => {
        return customerService.update(data).then((res) => {
            enqueueSnackbar("Customer Updated",{variant:'success'})
            handleCloseModel();
            getAllPageable(currentPage.current,currentRows.current)
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'}); 
                currentPage.current=1           
                getAllPageable(currentPage.current,currentRows.current);
            }else if(error.response.status === 409){
                enqueueSnackbar("Customer Already Exist",{variant:'warning'});
                handleCloseModel();
            }
            
        })
    }

    const deleteCustomer = async (data) =>{
        return customerService.delete(data.id).then((res)=>{            
            getAllPageable(currentPage.current,currentRows.current);
            enqueueSnackbar("Customer Deleted",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){
                setCustomerData(defaultData);
            }else if(error.response.status === 409){
                enqueueSnackbar("Couldn't Delete Customer",{variant:'warning'});                
            }
        })
    }

    const handleOpenAddModel = () =>{
        setModelTitle('Add Customer')
        setOpenModel(true)
    }

    const handleOpenEditModel = (data) =>{
        setModelTitle('Edit Customer')
        setOpenModel(true)
        setRow(data)
    }

    const handleCloseModel = () =>{
        setOpenModel(false);
        setRow({});
    }
   
    useEffect(() => {
        getAllPageable(currentPage.current,currentRows.current)
    }, [])

    return (
        <div>            
            <Box>
                <Datatable rows={customerData} columns={columns} loading={gridLoading} options={{"title":"Customer", "handleAddButtonClick":handleOpenAddModel,"currentpage":currentPage,"currentRows":currentRows}} getApi={getAllPageable} />
            </Box>            
            <FullScreenDialog title={modelTitle} open={openModel} close={handleCloseModel}>
                    <CustomerForm handleAdd={addCustomer} handleUpdate={updateCustomer} row={row}></CustomerForm>
            </FullScreenDialog>

        </div>
    )
}