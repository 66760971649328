import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import locationService from "../../../service/api/locationService";
import NamedAutoComplete from "../../ui/form/select/NamedAutoComplete";

export default function CustomerAddressForm(props) {
  const { register, control, index, errors, setValue, row } = props;

  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);

  const getAllCountry = async () => {
    return locationService.getAllCountry().then((res) => {
      setCountryData(res.data);
    });
  };

  const getStateByCountry = async (id) => {
    return locationService.getStateByCountry(id).then((res) => {
      setStateData(res.data);
    });
  };

  const getDistrictByState = async (id) => {
    return locationService.getDistrictByState(id).then((res) => {
      setDistrictData(res.data);
    });
  };

  const handleStateOnChange = (value) => {
    if(value){
      setDistrictData([])
      getDistrictByState(value?.id);
    }
    
  };

  useEffect(() => {
    if(row && row[index]){
      getAllCountry();
      getStateByCountry(1);
      getDistrictByState(row[index].state.id)      
      setValue(`customerAddress[${index}].street`,row[index].street)
      setValue(`customerAddress[${index}].village`,row[index].village)
      setValue(`customerAddress[${index}].landmark`,row[index].landmark)
      setValue(`customerAddress[${index}].pincode`,row[index].pincode)
      setValue(`customerAddress[${index}].countryId`,row[index].country.id)
      setValue(`customerAddress[${index}].stateId`,row[index].state.id)
      setValue(`customerAddress[${index}].districtId`,row[index].district.id)
    }else{
      getAllCountry();
      getStateByCountry(1);
    }
  },[]);
  return (
    <>
      {/* <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="address-type-label">Address Type</InputLabel>
          <Select
            disabled
            labelId="address-type-label"
            id="addressType"
            defaultValue={index === 0 ? "BILLING" : "SHIPPING"}
            {...register(`customerAddress[${index}].addressType`, { required: false })}
            label="Address Type"
            size="small"
          >
            <MenuItem value={"BILLING"}>
              Billing
            </MenuItem>
            <MenuItem value={"SHIPPING"}>Shipping</MenuItem>
          </Select>
        </FormControl>
      </Grid> */}

      <Grid item xs={3}>
        <TextField
          error={!!errors?.customerAddress?.[index]?.street} 
          {...register(`customerAddress[${index}].street`, { required: true })}
          fullWidth
          label="Street"
          size="small"
          helperText={!!errors?.customerAddress?.[index]?.street?"Enter the street name" : " "} 
          FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          error={!!errors?.customerAddress?.[index]?.village} 
          {...register(`customerAddress[${index}].village`, { required: true })}
          sx={{ width: "100%" }}
          label="Village"
          size="small"
          helperText={!!errors?.customerAddress?.[index]?.village?"Enter the village name" : " "} 
          FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          error={!!errors?.customerAddress?.[index]?.landmark} 
          {...register(`customerAddress[${index}].landmark`)}
          sx={{ width: "100%" }}
          label="Landmark"
          size="small"
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          type="number"
          error={!!errors?.customerAddress?.[index]?.pincode} 
          {...register(`customerAddress[${index}].pincode`, { required: true })}
          sx={{ width: "100%" }}
          label="Pin Code"
          size="small"
          helperText={!!errors?.customerAddress?.[index]?.pincode?"Enter the pincode name" : " "} 
          FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
        />
      </Grid>    

      <Grid item xs={3}>
        <NamedAutoComplete
          control={control}
          required={true}
          error={!!errors?.customerAddress?.[index]?.countryId} 
          options={countryData}
          size="small"
          label="Country"
          name={`customerAddress[${index}].countryId`}
          errorMessage="Select country"
        />
      </Grid>
      <Grid item xs={3}>
        <NamedAutoComplete
          control={control}
          required={true}
          error={!!errors?.customerAddress?.[index]?.stateId} 
          options={stateData}
          size="small"
          label="State"
          name={`customerAddress[${index}].stateId`}
          handelOnChange={handleStateOnChange}
          errorMessage="Select country"
        />
      </Grid>
      <Grid item xs={3}>
        <NamedAutoComplete
          control={control}
          required={true}
          error={!!errors?.customerAddress?.[index]?.districtId} 
          options={districtData}
          size="small"
          label="District"
          name={`customerAddress[${index}].districtId`}
          errorMessage="Select country"
        />
      </Grid>
        
    </>
  );
}
