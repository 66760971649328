import { useEffect } from 'react';
import Button from '@mui/material/Button';
import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Grid';
import DatePicker from '../../ui/form/datepicker/DatePicker';
import barcodeService from '../../../service/api/barcodeService';
import { validateDates } from '../../../util/commonUtil';


export default function ProductBatchForm(props) {  
    const {handleAdd, handleUpdate, row, variantId}   = props;
    const { register, handleSubmit, resetField, setValue, reset, control, formState: { errors, isSubmitting } } = useForm({defaultValues:{
      discount:0.00
    }});  
    const handelResetField = () => {
       reset();
    };

    const onSubmit = async (data) => {
      const result = validateDates(data.manufactureDate, data.expireDate);
      if (result) {
        if (row && row["id"]) {
          await handleUpdate({ ...data, id: row.id });
        } else {
          await handleAdd(data);
        }
      } else {
        return;
      }
    };

    const handleGenerateBarcode = async () =>{
      return barcodeService.generateBarcode().then(res=>{
        setValue("barcode",res.data.barcode)
      })
    }

    //This is use for update alone

    // useEffect(()=>{ 
    //     if(row['id']){           
    //         setValue("name",row.name)
    //         setValue("description",row.description)
    //     }else{
    //         handelResetField();
    //     }
    // },[row])

    useEffect(()=>{
        setValue("variantId",variantId)
        // handelResetField();
    })

    return (
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                error={errors["batchNumber"]}
                {...register("batchNumber", { required: true })}
                fullWidth
                label="Batch Number"
                size="small"
                name="batchNumber"
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                error={errors["barcode"]}
                size='small'
                {...register("barcode")}
                fullWidth
                label="Barcode"
                name="barcode"
              />
            </Grid>
            <Grid item xs={3}>
              <Button variant="text" type='button' onClick={handleGenerateBarcode} size='small'>Get Barcode</Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="number"
                size='small'
                error={errors["discount"]}
                inputProps={{
                  step: ".01"
                }}
                {...register("discount")}
                fullWidth
                label="Discount"
                name="discount"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                name="expireDate"
                label="Expire Date"
                error={errors["expireDate"]}
                control={control}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                name="manufactureDate"
                label="Manufacture Date"
                error={errors["manufactureDate"]}
                control={control}
                size="small"
              />
            </Grid>
            <Grid   item  xs={12}   sx={{ textAlign: "end", position: "fixed", bottom: 25, right: 50 }} >
              <Button
                variant="contained"
                disabled={isSubmitting}
                type="submit"
                size="small"
              >
                {" "}
                Add Batch
              </Button>
              {/* <Button variant="outlined" type="button" onClick={props.close} size="small">Cancel</Button> */}
            </Grid>
          </Grid>
        </form>
      </div>
    );
}