import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { CircularProgress, IconButton, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { useFieldArray, useForm } from "react-hook-form";
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import brandService from '../../../service/api/brandService'
import categoryService from '../../../service/api/categoryService'
import gstService from '../../../service/api/gstService'
import uomService from '../../../service/api/uomService'
import NamedAutoComplete from '../../ui/form/select/NamedAutoComplete';
import CustomSelect from '../../ui/form/select/CustomSelect'
import CheckboxLabeled from '../../ui/form/checkbox/CheckboxLabeled'
import DecimalField from '../../ui/form/number/DecimalField';

export default function ProductForm(props) {  
    const productVariantsDefault=[{
      "sku": "",
      "name": "",
      "weight": "",
      "colour": "",
      "size": "",
      "mrp": "",
      "unitPrice": "",
      "salePrice": ""}]
    const {handleAdd, handleUpdate, row}   = props;
    const { register, handleSubmit, reset, resetField, control, watch, setValue, clearErrors, formState: { errors, isSubmitting } } = useForm({
      defaultValues:{productVariants:productVariantsDefault}
    });
    const[brandData, setBrandData] = useState([])
    const[categoryData, setCategoryData] = useState([])
    const[gstData, setGstData] = useState([])
    const[uomData, setUomData] = useState([])
    const {fields,append,remove} = useFieldArray({
      control,
      name: "productVariants",       
    });
    const handelResetField = () => {
        reset()
    };

    const checkEnableSecondaryUnit = watch("enableSecondaryUnit", false)
    const secondaryUnitId = watch("secondaryUnitId", 0)
    const baseUnitId = watch("baseUnitId", 0)
    const checkEnableVariant = watch("enableVariant", false)    

    const getAllBrand = async ()=>{
        return brandService.getAll().then(res=>{
            setBrandData(res.data)
        })
    }

    const getAllCategory= async ()=>{
        return categoryService.getAll().then(res=>{
            setCategoryData(res.data)
        })
    }

    const getAllGst = async ()=>{
        return gstService.getAll().then((res)=>{
            setGstData(res.data)
        })
    }

    const getAllUOM = async ()=>{
        return uomService.getAll().then(res=>{
            setUomData(res.data)
        })
    }   

    const onSubmit = async data => {  
        if(row?.id){
            await handleUpdate({...data,id:row.id})
        }else{
            await handleAdd(data)
            handelResetField();
        }       
    };   

    useEffect(()=>{ 
        getAllGst();
        getAllBrand();
        getAllCategory();
        getAllUOM();
        if(row?.id){  
          setValue("name",row.name)
          setValue("brandId",row.brand.id)
          setValue("categoryId",row.category.id)
          setValue("gstId",row.gst.id)
          setValue("gstType",row.gstType)
          setValue("enableVariant",row.enableVariant)
          setValue("baseUnitId",row.baseUnit.id)
          setValue("enableSecondaryUnit",row.enableSecondaryUnit)
          if(row.enableSecondaryUnit){
            setValue("secondaryUnitId",row.secondaryUnit.id)
            setValue("conversionValue",row.conversionValue)
          }
          setValue("productVariants",row.productVariants)
          // setValue("description",row.description)
        }else{
            handelResetField();
        }
    },[row])

    return (
      <div>
        <form id="product-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} padding={1}>
            <Grid container spacing={2} padding={1}>
              <Grid item xs={2}>
                <NamedAutoComplete
                  control={control}
                  required={true}
                  error={errors["brandId"]}
                  errorMessage={"Select a brand"}
                  options={brandData}
                  size="small"
                  label="Brand"
                  name={`brandId`}
                />
              </Grid>

              <Grid item xs={2}>
                <NamedAutoComplete
                  control={control}
                  required={true}
                  error={errors["categoryId"]}
                  errorMessage={"Select a category"}
                  options={categoryData}
                  size="small"
                  label="Category"
                  name={`categoryId`}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField  
                  name="name"   
                  label="Name"  
                  size="small"           
                  {...register("name", { required: true })}
                  sx={{ width: "100%" }}
                  InputLabelProps={{ shrink: true}} 
                  error={errors["name"]}
                  helperText={errors["name"]?"Enter product name" : " "} 
                  FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
                />
              </Grid>
              <Grid item xs={2}>
                <NamedAutoComplete
                  control={control}
                  required={true}
                  error={errors["gstId"]}
                  errorMessage={"Select GST percentage"}
                  options={gstData}
                  size="small"
                  label="GST"
                  name={`gstId`}
                />
              </Grid>

              <Grid item xs={2}>
                <CustomSelect
                  name="gstType"
                  required={true}
                  error={errors["gstType"]}
                  errorMessage="Select a GST type"
                  control={control}
                  label="GST Type"
                  size="small"                  
                >
                  <MenuItem value={"EXCLUSIVE"}>Exclusive</MenuItem>
                  <MenuItem value={"INCLUSIVE"}>Inclusive</MenuItem>
                </CustomSelect>
              </Grid>

              <Grid item xs={2}>
                <NamedAutoComplete
                  control={control}
                  required={true}
                  error={errors["baseUnitId"]}
                  errorMessage='Select base Unit'
                  options={uomData}
                  size="small"
                  label="Base Unit"
                  name={`baseUnitId`}
                />
              </Grid>

              <Grid item xs={2}>
                <CheckboxLabeled
                  name="enableSecondaryUnit"
                  control={control}
                  label="Enable Secondary Unit"
                />
              </Grid>

              {checkEnableSecondaryUnit && (
                <>
                  <Grid item xs={2}>
                    <NamedAutoComplete
                      control={control}
                      required={checkEnableSecondaryUnit}
                      error={errors["secondaryUnitId"]}
                      errorMessage='Select secondary Unit'
                      options={uomData}
                      size="small"
                      label="Secondary Unit"
                      name={`secondaryUnitId`}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <b>
                        1{" "}
                        {secondaryUnitId !== 0 && uomData.length > 0
                          ? uomData.find((obj) => obj.id === secondaryUnitId)
                              .name
                          : ""}{" "}
                        ={" "}
                      </b>
                    </span>
                    <TextField
                      error={errors["conversionValue"]}
                      {...register("conversionValue", {
                        required: checkEnableSecondaryUnit,
                      })}
                      sx={{ width: "20%", mx: 1 }}
                      // label="Value"
                      size="small"
                      name="conversionValue"
                      helperText={errors["conversionValue"]?"provide conversion value" : " "} 
                      FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
                    />
                    <span>
                      <b>
                        {baseUnitId !== 0 && uomData.length > 0
                          ? uomData.find((obj) => obj.id === baseUnitId).name
                          : ""}
                      </b>
                    </span>
                  </Grid>
                </>
              )}
            </Grid>

            <Paper elevation={1} sx={{ margin: 1, padding: 1, width: "100%" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography fontSize={20} fontWeight={540} component="p">
                    {checkEnableVariant
                      ? "Product Variants Details"
                      : "Product Details"}
                  </Typography>
                  {checkEnableVariant && (
                    <Typography fontSize={13} component="p">
                      Click add button to add more variants
                    </Typography>
                  )}
                </div>
                <div>
                  {checkEnableVariant && (
                    <Button
                      variant="text"
                      onClick={() => {
                        append(productVariantsDefault);
                      }}
                      sx={{ color: "green", mr: 2 }}
                      size="small"
                    >
                      <AddIcon fontSize="small" /> Add Variant
                    </Button>
                  )}

                  <CheckboxLabeled
                    name="enableVariant"
                    control={control}
                    // handleOnChange={handleEnableVariantOnChange}
                    label="Enable Variant"
                  />
                </div>
              </div>
              {fields.map((item, index) => (
                <div style={{ display: "flex", "align-items": "center" }}>
                  <div style={{marginRight:15}}>
                    <span><b>{index+1}</b></span>
                  </div>
                  <Grid container spacing={2} paddingTop={2} key={item.id}>
                    <Grid item xs={4}>
                      <TextField
                        error={!!errors?.productVariants?.[index]?.name}
                        {...register(`productVariants.${index}.name`, {
                          required: true,
                        })}
                        sx={{ width: "100%" }}
                        InputLabelProps={{ shrink: true}}   
                        label="Variant Name"
                        size="small"
                        name={`productVariants.${index}.name`}
                        helperText={!!errors?.productVariants?.[index]?.name?"Enter product Variant name" : " "} 
                        FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        error={!!errors?.productVariants?.[index]?.sku}
                        {...register(`productVariants.${index}.sku`, {
                          required: true,
                        })}
                        sx={{ width: "100%" }}
                        InputLabelProps={{ shrink: true}}   
                        label="SKU"
                        size="small"
                        name={`productVariants.${index}.sku`}
                        helperText={!!errors?.productVariants?.[index]?.sku?"Enter the product SKU" : " "} 
                        FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        error={!!errors?.productVariants?.[index]?.weight}
                        {...register(`productVariants.${index}.weight`, {
                          required: false,
                        })}
                        sx={{ width: "100%" }}
                        InputLabelProps={{ shrink: true}}   
                        label="Weight"
                        size="small"
                        name={`productVariants.${index}.weight`}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        error={!!errors?.productVariants?.[index]?.colour}
                        {...register(`productVariants.${index}.colour`, {
                          required: false,
                        })}
                        sx={{ width: "100%" }}
                        InputLabelProps={{ shrink: true}}   
                        label="Colour"
                        size="small"
                        name={`productVariants.${index}.colour`}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        error={!!errors?.productVariants?.[index]?.size}
                        {...register(`productVariants.${index}.size`, {
                          required: false,
                        })}
                        sx={{ width: "100%" }}
                        InputLabelProps={{ shrink: true}}   
                        label="Size"
                        size="small"
                        name={`productVariants.${index}.size`}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <DecimalField
                        control={control}
                        required={true}
                        name={`productVariants.${index}.mrp`}
                        label="MRP"
                        size="small"
                        error={!!errors?.productVariants?.[index]?.mrp}
                        errorMessage='Enter the MRP'
                      /> 
                    </Grid>

                    <Grid item xs={2}>
                    <DecimalField
                      control={control}
                      required={true}
                      name={`productVariants.${index}.unitPrice`}
                      label="Unit Price"
                      size="small"
                      error={!!errors?.productVariants?.[index]?.unitPrice}
                      errorMessage='Enter the Unit / Purchase price'
                    /> 
                    </Grid>

                    <Grid item xs={2}>
                      <DecimalField
                        control={control}
                        required={true}
                        name={`productVariants.${index}.salePrice`}
                        label="Sale Price"
                        size="small"
                        error={!!errors?.productVariants?.[index]?.salePrice}
                        errorMessage='Enter the sale price'
                      />                      
                    </Grid>
                    {checkEnableVariant && (
                      <Grid item xs={2}>
                        <IconButton
                          sx={{ color: "red" }}
                          disabled={fields.length < 2}
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </div>
              ))}
            </Paper>

            <Grid   item  xs={12}   sx={{ textAlign: "end", position: "fixed", bottom: 25, right: 50 }} >
             
              <Button
                variant="contained"
                disabled={isSubmitting}
                type="submit"
                size="small"          
                form='product-form'      
              >
                {row?.id ? "Edit" : "Submit"}
              </Button>
              {/* <Button variant="outlined" type="button" onClick={props.close} size="small">Cancel</Button> */}
            </Grid>
          </Grid>
        </form>
      </div>
    );
}