import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    stateCode:null
};

const company = createSlice({
    name: "company",
    initialState,
    reducers: {
        addStateCode: (state, action) => {
            state.stateCode = action.payload
        },       
             
    },
});

export const { addStateCode } = company.actions;

export default company.reducer;