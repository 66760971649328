import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../components/common/Copyright';
import authService from '../service/api/authService';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch } from 'react-redux';
import { setISLoggedIn } from '../redux/reducer/user';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';

const theme = createTheme();

function SignIn() {

  const navigate = useNavigate();
  const dispatch =useDispatch()
  const { register, handleSubmit, formState:{errors,isSubmitting} } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const login = async (data) =>{
    return authService.login(data).then((res) => {
      dispatch(setISLoggedIn(true));
      navigate("/Dashboard");
    }).catch((err) => {
      setErrorMsg("Kindly enter valid credential");
    });
  }

  const onSubmit = async (data) => {
    setErrorMsg("");
    await login(data);
  };
  return (
    <Container component="div" maxWidth="xs">
      <CssBaseline />
      <div

        style={{ height: "100vh", display: "flex" }}
      >
        <div style={{ alignSelf: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              error={errors.username?true:false}
              size='small'
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              // helperText="Enter your username"
              {...register("username",{required:true})}
            />
            <TextField
              margin="normal"
              error={errors.password?true:false}
              required
              size='small'
              fullWidth
              name="password"
              label="Password"
              // helperText="Enter your Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="password"
              autoComplete="current-password"
              {...register("password",{required:true})}
            />
            <p style={{color:"red",textAlign:"end",margin:0,fontSize:14}}>{ErrorMsg}</p>            
              <Button type="submit" disabled={isSubmitting} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} >
                {isSubmitting ? <CircularProgress size="25px" color="inherit" /> : "Sign In"}
              </Button>       
          </Box>
          <Copyright />
        </div>
      </div>
    </Container>
  );
}

export default SignIn;