import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Grid, IconButton, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedProductData, batchIdUpdate, deleteSelectedProductData, discountUpdate, openCreateBatchModel, quantityUpdate } from '../../redux/reducer/sale';
import productBatchService from '../../service/api/productBatchService';
import productService from '../../service/api/productService';
import { decimalControl, getTaxAmount } from '../../util/numericUtil';
import ItemsDataTable from "../datatable/ItemsDataTable";
import ProductBatchForm from "../forms/product/ProductBatchForm";
import Model from "../model/Model";
import FullScreenDialog from '../model/FullScreenModel';
import ProductForm from '../forms/product/ProductForm';

export default function SaleSelectProduct({handelOpenModel}){      
    const [productSearchData, setProductSearchData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const {selectedProductData} = useSelector((state)=>state.sale);
    const isIgst = useSelector((state)=>state.sale.isIgst);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const columns = [
        { 
          field: "id", 
          headerName: "ID", 
          filterable: false, 
          sortable: false ,
          renderCell: (index) => index.api.getRowIndexRelativeToVisibleRows(index.row.id) + 1,
          width:50,
        },
          
        {
          field: "name",
          headerName: "Product Name",
          editable: false,
          width: 400,
          sortable: false,
        },
        {
          field: "batchNumber",
          headerName: "Batch",
          width: 200,
          editable: false,
          sortable: false,
        },
        {
          field: "quantity",
          headerName: "Quantity",
          renderCell: (params) => (            
            <TextField
              type="text"
              variant="standard"
              defaultValue={params.row.quantity}
              onBlur={(e) => {
                if(e.target.value <= params.row.availableQuantity){
                  dispatch(quantityUpdate({quantity : e.target.value ,id:params.row.id}))
                }else{
                  enqueueSnackbar(`${params.row.availableQuantity} Quantity only available in this product`,{variant:'error'});   
                }                              
              }}
            />
          ),
          sortable: false,
        }, 
        {
          field: "discount",
          headerName: "Disc.",
          renderCell: (params) => (
            <TextField
              type="number"
              variant="standard"
              defaultValue={params.row.discountAmount}
              onBlur={(e) => {
                dispatch(discountUpdate({discount:parseFloat(e.target.value),id: params.row.id}))                
              }}
            />
          ),
          sortable: false,
        },
        {
          field: "mrp",
          headerName: "MRP",
          editable: false,
          sortable: false,
        },
        {
          field: "salePrice",
          headerName: "Sale Price",
          editable: false,
          sortable: false,
        },
        {
          field: "gst",
          headerName: "GST(%)",
          editable: false,
          sortable: false,
          width:75,
        },
        {
          field: "gstAmount",
          headerName: "Tax Amt.",
          editable: false,
          sortable: false,
        },
        {
          field: "taxableAmount",
          headerName: "Taxable Amt.",
          editable: false,
          sortable: false,
        },
       
        {
          field: "totalAmount",
          headerName: "Total",
          editable: false,
          sortable: false,
        },
        {
          field: "action",
          headerName: "Action",
          editable: false,
          renderCell: (params) => (
            <IconButton
              sx={{ color: "red" }}
              onClick={() => {
                dispatch(deleteSelectedProductData(params.row.id));
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        },
      ];
        
      const handleProductSearchOnChange = (e)=>{        
        if(e){
          setSearchInput(e.target.value)          
          productService.search(e.target.value,'SALE').then(res=>{
              setProductSearchData(res.data)
          }).catch(errors=>{
  
          })          
        }else{
          setSearchInput('')
        }        
    }

      const handelSelectProductOnchange = (id) => {        
        setSearchInput('')
        productService.getByBatchId(id).then(res=>{
          const data = res?.data;
          if (data) {
            const totalSalePrice = data.productVariants.salePrice        
            const gstAmount = getTaxAmount(data.gstType, data.gst.percentage, totalSalePrice);
            dispatch(addSelectedProductData({ 
              id:window.crypto.randomUUID(),  
              batchNumber: data.productVariants.productBatch.batchNumber,
              availableQuantity : data.productVariants.productBatch.availableQuantity,
              batchId: data.productVariants.productBatch.id,
              name: data.name + " " + data.productVariants.name,
              quantity: 1,
              mrp:data.productVariants.mrp,
              salePrice:data.productVariants.salePrice,
              gst: data.gst.percentage,
              gstType: data.gstType,
              gstAmount: decimalControl(gstAmount),                            
              igst: isIgst ? decimalControl(gstAmount) : 0.00,
              sgst: !isIgst ? decimalControl(gstAmount/2) : 0.00,
              cgst: !isIgst ? decimalControl(gstAmount/2) : 0.00,
              taxableAmount: decimalControl(data.gstType === 'INCLUSIVE' ? (totalSalePrice - gstAmount) : totalSalePrice),
              totalAmount: decimalControl(data.gstType === 'INCLUSIVE' ? totalSalePrice  : (totalSalePrice + gstAmount)),
              discount: 0.0,
              discountAmount: 0.0
            }))
            setProductSearchData([])
          }
        })

      };

    return (
      <>
      <Grid container spacing={2} padding={0}>
        <Grid item xs={7}>
          <div style={{display:'flex'}}>
          <Autocomplete    
            value={null}
            fullWidth
            options={productSearchData}          
            size="small"
            onChange={(e, value) => {
              handelSelectProductOnchange(value.id);
            }}
            inputValue={searchInput}
            getOptionLabel={(option) => option.name }
            onInputChange={handleProductSearchOnChange}
            renderInput={(params) => (
              <TextField {...params} label="Search Product" />
            )}
          />
          {/* <ProductSearch options={productSearchData}  handleProductSearchOnChange={handleProductSearchOnChange}/> */}

          {/* <IconButton color="primary" onClick={handelOpenModel} sx={{padding:0}} aria-label="add to shopping cart">
            <AddCircleOutlineIcon fontSize='large' />
          </IconButton> */}
          </div>
        </Grid>        
        <Grid item xs={12}>
          <ItemsDataTable columns={columns} rows={selectedProductData} />
        </Grid>
      </Grid>
    </>
    );
}
