import { useEffect, useRef, useState } from "react";
import CustomerForm from "../../components/forms/customer/CustomerForm";
import customerService from "../../service/api/customerService";
import { useSnackbar } from "notistack";

export default function AddCustomer(){

    const [row, setRow] = useState({})
    const { enqueueSnackbar } = useSnackbar();

    const addCustomer = async (data) => {
        return customerService.create(data).then((res) => {
            enqueueSnackbar("New Customer Added",{variant:'success'})            
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Customer Already Exist",{variant:'warning'});               
            }
        })
    }
    
    const updateCustomer = async (data) => {
        return customerService.update(data).then((res) => {
            enqueueSnackbar("Customer Updated",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'});              
            }else if(error.response.status === 409){
                enqueueSnackbar("Customer Already Exist",{variant:'warning'});             
            }
            
        })
    }

    return(
        <>
             <h2>Add Customer</h2>
             <CustomerForm handleAdd={addCustomer} handleUpdate={updateCustomer} row={row}/>
        </>
    );
}