import axios from '../../config/axiosConfig'

class PaymentModeService {

    getAllPageable(page,size) {
        return axios.get(`/v1/pageable/payment-mode?page=${page}&size=${size}`);
    }
    getAll(data) {
        return axios.get('/v1/payment-mode');
    }
    create(data) {
        return axios.post('/v1/payment-mode', data);
    }
    update(data) {
        return axios.put(`/v1/payment-mode/${data.id}`, data);
    }
    delete(id) {
        return axios.delete(`/v1/payment-mode/${id}`);
    }
}

export default new PaymentModeService();