import { Box, Card, CardContent } from "@mui/material";
import Datatable from "../../components/datatable/Datatable";
import inventoryService from "../../service/api/inventoryService";
import { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";

export default function ViewStock(props){
    const defaultData = { "data": [],  "pageNumber": 0, "pageSize": 0, "totalPages": 0, "totalElements": 0 };
    const [stockData, setStockData] = useState(defaultData)
    const [openModel, setOpenModel] = useState(false)
    const [modelTitle, setModelTitle] = useState('')
    const [row, setRow] = useState({})
    const [gridLoading, setGridLoading]  = useState(false);
    const currentPage = useRef(1);
    const currentRows = useRef(5);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', headerName: 'ID', width:50 },        
        {
            field: 'sku',
            headerName: 'SKU',
            editable: false,
            width:150
        },
        {
            field: 'batchNo',
            headerName: 'Batch No',
            editable: false,
            width:200
        },
        {
            field: 'name',
            headerName: 'Product Name',
            editable: false,
            width:350
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            editable: false,
        },
    ]; 

    const getAllPageable = async (page, size) => {
        setGridLoading(true);
        return inventoryService.getAllProductStock(page, size).then((res) => {
            setGridLoading(false);
            setStockData(res.data)
        }).catch((error)=>{
            if(error.response.status===404){
                setGridLoading(false);
                setStockData(defaultData);
            }
        })
        
    }

    // const addStock = async (data) => {
    //     return stockService.create(data).then((res) => {
    //         enqueueSnackbar("New Stock Added",{variant:'success'})
    //         handleCloseModel();
    //         currentPage.current=1            
    //         getAllPageable(currentPage.current,currentRows.current)
    //     }).catch((error)=>{
    //         if(error.response.status===409){
    //             enqueueSnackbar("Stock Already Exist",{variant:'warning'});
    //             handleCloseModel();
    //         }
    //     })
    // }
    
    // const updateStock = async (data) => {
    //     return stockService.update(data).then((res) => {
    //         enqueueSnackbar("Stock Updated",{variant:'success'})
    //         handleCloseModel();
    //         getAllPageable(currentPage.current,currentRows.current)
    //     }).catch((error)=>{
    //         if(error.response.status===404){   
    //             enqueueSnackbar("Data NotFound to Update",{variant:'error'}); 
    //             currentPage.current=1           
    //             getAllPageable(currentPage.current,currentRows.current);
    //         }else if(error.response.status === 409){
    //             enqueueSnackbar("Stock Already Exist",{variant:'warning'});
    //             handleCloseModel();
    //         }
            
    //     })
    // }

    // const deleteStock = async (data) =>{
    //     return stockService.delete(data.id).then((res)=>{            
    //         getAllPageable(currentPage.current,currentRows.current);
    //         enqueueSnackbar("Stock Deleted",{variant:'success'})
    //     }).catch((error)=>{
    //         if(error.response.status===404){
    //             setStockData(defaultData);
    //         }else if(error.response.status === 409){
    //             enqueueSnackbar("Couldn't Delete Stock",{variant:'warning'});                
    //         }
    //     })
    // }

    // const handleOpenAddModel = () =>{
    //     setModelTitle('Add Stock')
    //     setOpenModel(true)
    // }

    // const handleOpenEditModel = (data) =>{
    //     setModelTitle('Edit Stock')
    //     setOpenModel(true)
    //     setRow(data)
    // }

    // const handleCloseModel = () =>{
    //     setOpenModel(false);
    //     setRow({});
    // }
   
    useEffect(() => {
        getAllPageable(currentPage.current,currentRows.current)
    }, [])

    return (
        <div>            
            <Box>
                <Datatable rows={stockData} columns={columns} loading={gridLoading} options={{"title":"Stock", "currentpage":currentPage,"currentRows":currentRows}} getApi={getAllPageable} />
            </Box>  
        </div>
    )
}