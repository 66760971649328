import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userData: null,
    isLogged:null
};

const user = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUser: (state, action) => {
            state.userData = action.payload
        },       
        setISLoggedIn: (state, action) => {
            state.isLogged = action.payload
        },       
    },
});

export const { addUser,setISLoggedIn } = user.actions;

export default user.reducer;