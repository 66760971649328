import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { IconButton, Paper, TextField, Typography } from '@mui/material';
import { useFieldArray, useForm } from "react-hook-form";
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import customerService from '../../service/api/customerService';
import paymentModeService from '../../service/api/paymentModeService';
import NamedAutoComplete from '../ui/form/select/NamedAutoComplete';
import DateTimePicker from '../ui/form/datepicker/DateTimePicker';
import CheckboxLabeled from '../ui/form/checkbox/CheckboxLabeled';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DecimalField from '../ui/form/number/DecimalField';
import moment from 'moment-timezone';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ErrorIcon from '@mui/icons-material/Error';

export default function PaymentReceiptForm(props) {  
    const paymentsDefault = [{
        "amount": "",
        "paymentModeId": "",
        "note": ""
    }]
    const {handleAdd, handleUpdate, row}   = props;
    const {  register, handleSubmit, reset, resetField,control, setError, clearErrors, setValue,watch, formState: { errors, isSubmitting } } = useForm({defaultValues : {payments:paymentsDefault}});   
    const [customerData, setCustomerData] = useState([]);
    const [paymentModeData, setPaymentModeData] = useState([]);
    const {fields,append,remove} = useFieldArray({
        control,
        name: "payments",       
      });

    const handelResetField = () => {
        reset()        
    };

    const onSubmit = async data => {    
        if(row['id']){                     
            await handleUpdate({...data,id:row.id})
        }else{
           await handleAdd(data)
           handelResetField();
        }       
    };

    useEffect(()=>{ 
        getAllCustomer();        
        if(row['id']){      
          setValue("customerId",row.customer.id)          
          setValue("date",row.date)
          setValue("amount",row.amount)
          setValue("payments",row.payments) 
        }else{
            handelResetField();
        }
        getAllPaymentMode();
    },[row])

    const getAllCustomer = ()=>{
        customerService.getAllForDropdown().then(res=>{
            setCustomerData(res.data)
        })
    }

    const getAllPaymentMode = () =>{
        paymentModeService.getAll().then(res=>{
          setPaymentModeData(res.data)            
        })
      }

    return (
        <div>            
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container spacing={2} padding={1}>
                    <Grid item xs={3}>
                        <NamedAutoComplete
                            control={control}
                            required={true}
                            error={errors["customerId"]}
                            options={customerData}
                            size="small"
                            label="Customer"
                            name={`customerId`}           
                            errorMessage={"Select a Customer"}
                        />
                        {/* <span style={{fontSize:11, color:'red'}}> <ErrorIcon sx={{fontSize:11}}/>&nbsp;Choose a Customer Name</span> */}
                    </Grid>
                    <Grid item xs={3}>                      
                        <DateTimePicker
                            name="date"
                            label="Date"
                            defaultValue={moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ').toString()}
                            required={true}
                            error={errors["date"]}
                            control={control}
                            size="small"
                        />
                    </Grid>   
                    <Grid item xs={3}>
                        <DecimalField
                        control={control}
                        required={true}
                        name="amount"
                        label="Amount"
                        size="small"
                        error={errors["amount"]}     
                        errorMessage="Enter the amount"  
                        /> 
                    </Grid>
                </Grid>


                {/* <Paper elevation={1} sx={{ margin: 1, padding: 1, width: "100%" }}> */}
              <div style={{ display: "flex", justifyContent: "space-between", margin:1, padding: 1 }}>
                <div>
                  <Typography fontSize={20} fontWeight={540} component="p"> Payments </Typography>                  
                    <Typography fontSize={13} component="p">
                      Click add button to add more Payments
                    </Typography>                  
                </div>
                <div>
                    <Button
                      variant="text"
                      onClick={() => {
                        append(paymentsDefault);
                      }}
                      sx={{ color: "green", mr: 2 }}
                      size="small"
                    >
                      <AddIcon fontSize="small" /> Add Payment
                    </Button>
                </div>
              </div>
              {fields.map((item, index) => (
                <Payments key={item.id} paymentModeData={paymentModeData} row={row?.payments} index={index} control={control} errors={errors} setValue={setValue} register={register} remove={remove}/>
              ))}
            {/* </Paper> */}
            <Grid   item  xs={12}   sx={{ textAlign: "end", position: "fixed", bottom: 25, right: 50 }} >
                        <Button variant="contained" disabled={isSubmitting} type="submit" size="small" ><SaveIcon/>{row['id']?"Edit":"Submit"}</Button>
                        {/* <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                            Save
                        </LoadingButton> */}
                        {/* <Button variant="outlined" type="button" onClick={props.close} size="small">Cancel</Button> */}
                    </Grid>
            </form>
               
        </div>
    );
}

function Payments({index,control,errors,paymentModeData, register, remove, setValue, row}){

  useEffect(()=>{
    if(row && row[index]){
      setValue(`payments.${index}.paymentModeId`,row[index].paymentMode.id)
    }else{
      setValue(`payments.${index}.paymentModeId`,1)
    }
  },[])

  return(
    <div style={{ display: "flex", "align-items": "center" }}>
                  <div style={{marginRight:15}}>
                    <span><b>{index+1}</b></span>
                  </div>
                  <Grid container spacing={2} paddingTop={2}>

                  <Grid item xs={2}>
                        <NamedAutoComplete
                            control={control}
                            required={true}
                            error={!!errors?.payments?.[index]?.paymentModeId}
                            options={paymentModeData}
                            size="small"
                            label="Payment Mode"
                            name={`payments.${index}.paymentModeId`}
                            errorMessage="Select payment Mode"
                        />
                    </Grid>                  

                    <Grid item xs={2}>
                        <DecimalField
                            control={control}
                            required={true}
                            name={`payments.${index}.amount`}
                            label="Amount"
                            size="small"
                            error={!!errors?.payments?.[index]?.amount}
                            errorMessage="Enter a amount"
                        />   
                    </Grid>                    

                    <Grid item xs={5}>
                        <TextField
                            error={!!errors?.payments?.[index]?.note}
                            {...register(`payments.${index}.note`)}
                            sx={{ width: "100%" }}
                            label="Note"
                            size="small"
                            name={`payments.${index}.note`}
                        />
                    </Grid>
                    
                    {index > 0 && (
                      <Grid item xs={2}>
                        <IconButton
                          sx={{ color: "red" }}
                          // disabled={fields.length < 2}
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </div>
  )
}

