import axios from '../../config/axiosConfig'

class AuthService{
    create(data){
        return axios.post('/v1/product-batch',data);
    } 

    getByVariant(id){
        return axios.get(`/v1/product-batch-by-variant/${id}`)
    }
}

export default new AuthService();