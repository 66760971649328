import axios from '../../config/axiosConfig'

class ProductService{
    getAllPageable(page,size) {
        return axios.get(`/v1/pageable/product?page=${page}&size=${size}`);
    }

    search(query,searchType,signal){
        return axios.get(`/v1/product/search?q=${query}&t=${searchType}`)
    }

    getVariantById(id){
        return axios.get(`/v1/product/variant/${id}`)
    }

    getByBatchId(id){
        return axios.get(`/v1/product/batch/${id}`)
    }

    create(data) {
        return axios.post('/v1/product', data);
    }

    update(data) {
        return axios.put(`/v1/product/${data.id}`, data);
    }
    
    delete(id) {
        return axios.delete(`/v1/product/${id}`);
    }
}

export default new ProductService();