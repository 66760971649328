import { InputAdornment, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function DecimalField({name,label,error,size,control,defaultValue,handleOnChange,onBlur,required,endAdornmentVal,errorMessage = " "}){  
    return(
      <Controller
        name={name}
        rules={{required}}
        defaultValue={defaultValue ? defaultValue : ''}
        control={control}
        render={({field : { onChange, value }}) => (
          <TextField
            type="text"
            value={value}
            InputLabelProps={{ shrink: true}}
            size={size}
            error={error}
            helperText={error?errorMessage : " "}
            FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
            onChange={ e=>{
              const regex =  /^[+-]?((\d+(\.\d{0,2})?)|(\.\d{0,2}))$/;
              //Old regex without decimal control
              // const regex =  /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                onChange(e.target.value)
                if(handleOnChange){
                  handleOnChange(e)
                }
              }
            }}
            onBlur={onBlur}
            fullWidth
            label={label}
            variant="outlined"
            InputProps={{
              endAdornment: endAdornmentVal && (
                <InputAdornment position="end">{endAdornmentVal}</InputAdornment>
              ),
            }}
          />
        )}
      />
    
    )
}