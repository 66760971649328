import { Box, LinearProgress } from "@mui/material";
import ScrollableTab from "../../components/ui/ScrollableTab";
import Barcode from "./Barcode";
import Company from "./Company";
import InvoiceNumber from "./InvoiceNumber";
import Notification from "./Notification";

export default function Settings(){

    const settingsRoute = [
        {
            "label":"Company",
            "Component":<Company/>
        },    
        // {
        //     "label":"Notification",
        //     "Component":<Notification/>
        // },
        // {
        //     "label":"Barcode",
        //     "Component":<Barcode/>
        // },
        // {
        //     "label":"Invoice Number",
        //     "Component":<InvoiceNumber/>
        // },
    ]

    return (
        <>
            <h2 style={{marginBottom:0}}>Settings</h2>
            <ScrollableTab pages={settingsRoute}/>
        </>    
    )
}