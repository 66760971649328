import { useEffect, useRef, useState } from "react";
import supplierService from "../../service/api/supplierService";
import { useSnackbar } from "notistack";
import SupplierForm from "../../components/forms/SupplierForm";
import { Card, CardContent } from "@mui/material";
import { Margin } from "@mui/icons-material";

export default function AddSupplier(){

    const [row, setRow] = useState({})
    const { enqueueSnackbar } = useSnackbar();

    const addSupplier =  async (data) => {
        return supplierService.create(data).then((res) => {
            enqueueSnackbar("New Supplier Added",{variant:'success'})            
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Supplier Already Exist",{variant:'warning'});               
            }
        })
    }
    
    const updateSupplier = async (data) => {
        return supplierService.update(data).then((res) => {
            enqueueSnackbar("Supplier Updated",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'});              
            }else if(error.response.status === 409){
                enqueueSnackbar("Supplier Already Exist",{variant:'warning'});             
            }
            
        })
    }

    return (
      <>
        <h2 style={{marginBottom:0}}>Add Supplier</h2>
        <span style={{fontSize:14,marginBottom:2}}>Home / Dashboard</span>
        <Card>
          <CardContent>
            <SupplierForm handleAdd={addSupplier} handleUpdate={updateSupplier} row={row} />
          </CardContent>
        </Card>
      </>
    );
}