import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import TopNav from '../topnav/TopNav';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import commonService from '../../service/api/commonService';

import './Sidebar.css'
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import icons from '../../config/icon';
import { Grid } from '@mui/material';
import SkeletonLoading from '../loading/skeleton/SideMenuLoading';
import SideMenuLoading from '../loading/skeleton/SideMenuLoading';
import { setMenuList } from '../../redux/reducer/menu';
import { useDispatch, useSelector } from 'react-redux';

const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function SideNav(params) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false)
  const [activeMenu, setActivemenu] = useState(0)
  // const [menu, setMenus] = useState([])
  const [collapsemenu, setcollapsemenu] = useState({})
  const dispatch =useDispatch()

  const menu = useSelector((state) => state.menu.menuList);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getMenu = async () => {
    setLoading(true);
    return commonService.getMenu().then((res) => {
      dispatch(setMenuList(res?.data));
      const result = [...res.data];
      // setMenus((prev) => {
      //   return result
      // })
      setLoading(false);
    }).catch(()=>{
      setLoading(false);
    });
  };
  // This is for side menu loading wait time, only for testing
  // useEffect(() => {
  //   setTimeout(() => {
  //     getMenu()
  //   }, 20000);
  // }, [])

  useEffect(() => {   
    if (!menu.length ) {      
      getMenu();
    }   
  }, [menu]);


  return (
    <Box >
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <TopNav setOpens={(e) => {
          params.sizeChange(!open)
          setOpen(!open)
          if(open) setcollapsemenu({})
        }} />
      </AppBar>
      <Drawer variant="permanent" sx={{ background: "rgb(255, 255, 255)" }} open={open}>
        <div style={{ marginTop: 80 }}>
          {!loading ? (
            menu?.map((item, index) => (
              item.appScreen.length > 0 ? (
                NestedMenu(item, index, setActivemenu, activeMenu, collapsemenu, setcollapsemenu, open,setOpen,params.sizeChange)
              ) : (
                SingleMenu(item, index, setActivemenu, activeMenu)
              )
            ))) : (<SideMenuLoading/>)
          }
        </div>  
      </Drawer>
    </Box>
  );
}

function SingleMenu(params, index, setActivemenu, activeMenu) {

  return (
    <List key={params.path} sx={{ width: '100%' }}>
      <Link style={{textDecoration: 'none'}} to={params['path']}>

      <ListItemButton className={(activeMenu == index) ? "active-current" : ""} onClick={() => {
        setActivemenu(index)
      }}>
        <ListItemIcon >
        {icons[params['iconClass']]}
         
          <span style={{ color: "#60626E", marginLeft: 15 }} >{params['label']}</span>
        </ListItemIcon>

      </ListItemButton>
      </Link>
    </List>
  );
}

function NestedMenu(params, ins, setActivemenu, activeMenu, collapsemenu, setcollapsemenu,openMenu, setOpen,sizeChange) {
  return (
    <List key={params.path}>
      <ListItemButton onClick={() => {
        if (collapsemenu[ins]) {
          setcollapsemenu({ ...activeMenu, [ins]: !collapsemenu[ins] })

        } else {
          setcollapsemenu({ ...activeMenu, [ins]: true })
        }        
        setActivemenu(ins)
        if(!openMenu){ 
          setOpen(!openMenu)
          sizeChange(!openMenu)
        }
      }} >  
      <Grid container>      
      <Grid item xs={10}>
      
        <ListItemIcon>
          {icons[params['iconClass']]}
          <span style={{ fontSize: 16, color: "#60626E", marginLeft: 15 }} >{params['label']}</span>
        </ListItemIcon>
        </Grid>
        <Grid item xs={2}>
         {openMenu && <>{(collapsemenu[ins]) ? <ExpandLess /> : <ExpandMore />}</>}
        </Grid>
        </Grid>
      </ListItemButton>
      <Collapse in={(collapsemenu[ins])} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            params.appScreen.map((item, index) => (
              <Link key={params['path']+item['path']} style={{textDecoration: 'none',color:'gray'}} to={params['path']+item['path']}>
                <ListItemButton sx={{ pl: 4 }}>
                  {/* <ListItemIcon>
                    <AttachmentOutlinedIcon />
                  </ListItemIcon> */}
                  <ListItemText sx={{ml:2}} primary={item.label} />
                </ListItemButton>
              </Link>
            ))
          }

        </List>
      </Collapse>
    </List>
  );
}

export default SideNav