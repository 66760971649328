import axios from '../../config/axiosConfig'

class GstService {

    getAllPageable(page,size) {
        return axios.get(`/v1/pageable/gst?page=${page}&size=${size}`);
    }

    getAll() {
        return axios.get('/v1/gst');
    }

    create(data) {
        return axios.post('/v1/gst', data);
    }

    update(data) {
        return axios.put(`/v1/gst/${data.id}`, data);
    }

    delete(id) {
        return axios.delete(`/v1/gst/${id}`);
    }
}

export default new GstService();