import { createSlice } from "@reduxjs/toolkit";
import { decimalControl, getTaxAmount } from "../../util/numericUtil";

const initialState = {
    isIgst: false,
    selectedProductData: [],
    purchaseData:{
        gstAmount : 0.00,
        cgst : 0.00,
        sgst : 0.00,
        igst : 0.00,
        taxableAmount: 0.00,        
        discount:0.00,
        discountAmount:0.00,
        roundOff:0.00,
        grandTotal: 0.00,
        shipping:0.00,
        additionalCharges:0.00,
    },
    createBatch:{
        openModel:false,
        variantId : null,
    }
};

const calcPurchaseData = (state) => {
    const shippingGst = decimalControl(getTaxAmount("EXCLUSIVE",5,state.purchaseData.shipping + state.purchaseData.additionalCharges));    
    const gstAmount = decimalControl(state.selectedProductData.reduce((n, {gstAmount}) => n + gstAmount, 0)) + shippingGst;
    const taxableAmount = decimalControl(state.selectedProductData.reduce((n, {taxableAmount}) => n + taxableAmount, 0)) + state.purchaseData.shipping + state.purchaseData.additionalCharges;
    const grandTotal = gstAmount+taxableAmount;
    const discountAmount = decimalControl(state.selectedProductData.reduce((n, {discountAmount}) => n + discountAmount, 0));

    state.purchaseData.taxableAmount = taxableAmount
    state.purchaseData.gstAmount = gstAmount;
    state.purchaseData.discountAmount = discountAmount;
    state.purchaseData.grandTotal = decimalControl(Math.round(grandTotal));
    state.purchaseData.roundOff = decimalControl(grandTotal - Math.round(grandTotal));
    
    if(state.isIgst){
        state.purchaseData.igst = gstAmount;
        state.purchaseData.cgst = 0.00;
        state.purchaseData.sgst = 0.00;
    }else{
        state.purchaseData.igst = 0.00;
        state.purchaseData.cgst = decimalControl(gstAmount / 2);
        state.purchaseData.sgst = decimalControl(gstAmount / 2);
    }   
}

const purchase = createSlice({
    name: "purchase",
    initialState,
    reducers: {
        addSelectedProductData: (state, action) => {
            state.selectedProductData.push(action.payload)
            state.purchaseData.grandTotal = action.payload.totalAmount
            calcPurchaseData(state)
        }, 

        setSelectedProductData:(state, action) =>{
            state.selectedProductData = action.payload
            state.selectedProductData.map((obj)=>{
                if(!obj.purchaseItemId){
                    obj.purchaseItemId = obj.id
                    // obj.batchNumber = obj.batch.batchNumber;
                    obj.batchId = obj.batch.id;
                }
            })
            calcPurchaseData(state)
        },
        
        deleteSelectedProductData: (state, action) =>{
            state.selectedProductData = state.selectedProductData.filter((obj)=>obj.id !== action.payload)
            calcPurchaseData(state)
        },

        clearSelectedProductData: (state,action)=>{
            state.selectedProductData = [];
            state.purchaseData = initialState.purchaseData;
        },

        quantityUpdate:(state,action)=>{               
            const dataIndex = state.selectedProductData.findIndex((obj)=> obj.id == action.payload.id) 
            let data = state.selectedProductData[dataIndex]    
            data.quantity = action.payload.quantity
            const totalPrice = (data.salePrice * action.payload.quantity) - data.discountAmount
            const gstAmount = getTaxAmount(data.gstType, data.gst, totalPrice)
            data.gstAmount = decimalControl(gstAmount)    
            if(state.isIgst){
                data.igst = gstAmount;
            }else{
                data.cgst = decimalControl(gstAmount / 2);
                data.sgst = decimalControl(gstAmount / 2);
            } 
            data.taxableAmount = decimalControl(data.gstType === 'INCLUSIVE' ? (totalPrice - gstAmount) : totalPrice)
            data.totalAmount = decimalControl(data.gstType === 'INCLUSIVE' ? totalPrice  : (totalPrice + gstAmount)) 
            calcPurchaseData(state)
            
        },

        discountUpdate:(state,action)=>{
            const dataIndex = state.selectedProductData.findIndex((obj)=> obj.id == action.payload.id) 
            let data = state.selectedProductData[dataIndex]    
            data.discountAmount = action.payload.discount
            const totalPrice = (data.salePrice * data.quantity) - action.payload.discount
            const gstAmount = getTaxAmount(data.gstType, data.gst, totalPrice)
            data.gstAmount = decimalControl(gstAmount)      
            if(state.isIgst){
                data.igst = gstAmount;
            }else{
                data.cgst = decimalControl(gstAmount / 2);
                data.sgst = decimalControl(gstAmount / 2);
            }   
            data.taxableAmount = decimalControl(data.gstType === 'INCLUSIVE' ? (totalPrice - gstAmount) : totalPrice)
            data.totalAmount = decimalControl(data.gstType === 'INCLUSIVE' ? totalPrice  : (totalPrice + gstAmount))
            calcPurchaseData(state)
        },

        batchIdUpdate:(state,action)=>{
            const dataIndex = state.selectedProductData.findIndex((obj)=> obj.id == action.payload.id) 
            let data = state.selectedProductData[dataIndex]  
            data.batchId = action.payload.batchId;
        },

        updateShippingCharges:(state,action)=>{
            state.purchaseData.shipping = action.payload
            calcPurchaseData(state)
        },
        updateAdditionalCharges:(state,action)=>{
            state.purchaseData.additionalCharges = action.payload
            calcPurchaseData(state)
        },

        updateRoundOff:(state,action)=>{
            state.purchaseData.roundOff = action.payload
        },

        openCreateBatchModel:(state, action)=>{            
            state.createBatch.variantId = action.payload;
            state.createBatch.openModel = true;
        },

        closeCreateBatchModel:(state, action)=>{            
            state.createBatch = initialState.createBatch;
        },
        updateIsIgst : (state,action) =>{   
            if(!state.isIgst && action.payload){
                state.selectedProductData.forEach((obj)=>{
                    obj.igst = obj.gstAmount;
                    obj.sgst = 0.00;
                    obj.cgst = 0.00;
                    return obj;
                })                
            }else if(state.isIgst && !action.payload) {
                state.selectedProductData.forEach((obj)=>{
                    obj.igst = 0.00;
                    obj.sgst = decimalControl(obj.gstAmount/2);
                    obj.cgst = decimalControl(obj.gstAmount/2);
                    return obj;
                })
            }            
            state.isIgst = action.payload
            calcPurchaseData(state)
        }
    },
});

export const { addSelectedProductData,setSelectedProductData,updateRoundOff,deleteSelectedProductData,clearSelectedProductData, updateIsIgst, quantityUpdate, discountUpdate, batchIdUpdate, updateShippingCharges,updateAdditionalCharges,openCreateBatchModel,closeCreateBatchModel} = purchase.actions;

export default purchase.reducer;