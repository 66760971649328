import axios from '../../config/axiosConfig'

class PaymentReceiptService {

    getAllPageable(page,size) {
        return axios.get(`/v1/pageable/payment?page=${page}&size=${size}`);
    }

    getAll(){
        return axios.get('/v1/payment');
    }

    create(data) {
        return axios.post('/v1/payment', data);
    }

    update(data) {
        return axios.put(`/v1/payment/${data.id}`, data);
    }
    
    delete(id) {
        return axios.delete(`/v1/payment/${id}`);
    }
}

export default new PaymentReceiptService();