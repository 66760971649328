import axios from '../../config/axiosConfig'

class CommonService{
    getMenu(){
        return axios.get('/v1/common/get-menu');
    }
    getAllRole(){
        return axios.get('/v1/common/get-all-role');
    }
}

export default new CommonService();