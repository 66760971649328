import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  bulkStockDataArr: [],
};

const bulkStock = createSlice({
  name: "bulkStock",
  initialState,
  reducers: {

    setBulkStock: (state, action) => {
      state.bulkStockDataArr.push({ id: nanoid(), ...action.payload });
    },

    setBulkStockOnUpdate: (state, action) => {
      state.bulkStockDataArr = action.payload;
    },

    clearBulkStock : (state, action) => {
      state.bulkStockDataArr = [];
    }
  },
});

export const { setBulkStock, setBulkStockOnUpdate,clearBulkStock } = bulkStock.actions;

export default bulkStock.reducer;
