import { Autocomplete, Button, Paper, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function NamedAutoComplete({ control, name, label, options, size, handelOnChange, handelOnBlur, error,required =false, noOptionsText,onInputChange,actionButton, errorMessage = " "}) {

  const PaperComponentCustom = options => {
    const { containerProps, children } = options;
    return (
      <Paper  {...containerProps}>
        {children}
        {actionButton && (
          <Button
            fullWidth  
            startIcon={actionButton?.icon}
            onMouseDown={(event)=>{event.preventDefault(); actionButton?.onClick()}}
            >
            {actionButton?.name}
          </Button>
         )} 
      </Paper> 
      );
    };

  return (
    <Controller
      control={control}          
      rules={{required}}
      name={name}
      render={({ field: { onChange, ...field } }) => (
        <Autocomplete          
          disablePortal
          value={(field.value ? options.find((option)=>{
            return option.id === field.value
          }):null )?? null}      
          PaperComponent={PaperComponentCustom}
          size={size}
          options={options}
          onInputChange={onInputChange}
          noOptionsText={noOptionsText ? noOptionsText:'No Options'}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          sx={{ width: '100%' }}
          onChange={(e, value,reason) => {       
            onChange(value?.id);
           if(handelOnChange) handelOnChange(value)
          }}
          onBlur={handelOnBlur}
          renderInput={(params) => ( 
            <TextField helperText={error?errorMessage : " "} FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}} error={error} {...params} label={label} InputLabelProps={{ shrink: true}}  />
          )}
        />
      )}
    />
  );
  
}


