import { FormControl, FormHelperText, InputLabel, OutlinedInput, Select } from "@mui/material";
import React from "react";

import { Controller } from "react-hook-form";

const CustomSelect = ({
  name,
  label,
  defaultValue,
  required=false,
  control,
  children,
  size,
  handelOnChange,
  error,
  errorMessage = " ",
  ...props
}) => {
  const labelId = `${name}-label`;

  return (
    <FormControl {...props} error={error} fullWidth>
      <InputLabel shrink size={size} id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        defaultValue={defaultValue ? defaultValue : ''}
        rules={{required}}
        control={control}
        render={({ field: { ref, onChange, ...field } }) => (
          <Select    
            input={<OutlinedInput notched label={label} />}        
            // InputLabelProps={{ shrink: true}} 
            value={field.value}
            labelId={labelId}
            label={label}
            size={size}
            fullWidth   
            onChange={(e) => {       
                onChange(e.target.value);
               if(handelOnChange) handelOnChange(e)
              }}                   
          >
            {children}
          </Select>
        )}
      />
      <FormHelperText sx={{margin:0,marginLeft:'2px'}} >{error? errorMessage:" "}</FormHelperText>
    </FormControl>
  );
};
export default CustomSelect;
