import axios from '../../config/axiosConfig'

class LocationService{
    getAllCountry(){
        return axios.get('/v1/country');
    } 

    getStateByCountry(id){
        return axios.get(`/v1/state/get-state-by-country/${id}`)
    }

    getDistrictByState(id){ 
        return axios.get(`/v1/district/get-district-by-state/${id}`)
    }
}

export default new LocationService();