import { useEffect } from 'react';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import TextBox from '../../ui/form/TextBox';

export default function GST(props) {  
    const {handleAdd, handleUpdate, row}   = props;
    const { register, handleSubmit, resetField, setValue, control, formState: { errors , isSubmitting} } = useForm();   

    const handelResetField = () => {
        resetField("name")
        resetField("percentage")
        resetField("description")
    };

    const onSubmit = async data => {    
        if(row['id']){                     
            await handleUpdate({...data,id:row.id})
        }else{
           await handleAdd(data)
        }       
    };

    useEffect(()=>{ 
        if(row['id']){      
        setValue("name",row.name)
        setValue("percentage",row.percentage)
        setValue("description",row.description)
        }else{
            handelResetField();
        }
         
    },[row])

    return (
        <div>            
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container spacing={2} padding={1}>
                    <Grid item xs={12}>
                    <TextBox error={errors['name']} control={control} required={true} label="Name" size='small' name='name' errorMessage='Enter the Percentage GST name' />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField type='number' error={errors['percentage']} {...register("percentage", { required: true })} sx={{ width: "100%" }} label="Percentage" size='small' percentage='name' />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField error={errors['description']} size='small' {...register("description")} sx={{ width: "100%" }} label="Description" name='description' />
                    </Grid>
                    <Grid   item  xs={12}   sx={{ textAlign: "end", bottom: 25, right: 50 }} >
                        <Button variant="contained" disabled={isSubmitting} type="submit" size="small" ><SaveIcon/>{row['id']?"Edit":"Submit"}</Button>
                        {/* <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                            Save
                        </LoadingButton> */}
                        {/* <Button variant="outlined" type="button" onClick={props.close} size="small">Cancel</Button> */}
                    </Grid>
                </Grid>
            </form>
               
        </div>
    );
}