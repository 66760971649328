import { useState } from "react";
import { useSnackbar } from "notistack";
import saleService from "../../service/api/saleService";
import { Card, CardContent } from "@mui/material";
import SaleForm from "../../components/forms/sale/SaleForm";
import printUtil from "../../util/printUtil";

export default function CreateSale(){
    const [row, setRow] = useState({})
    const { enqueueSnackbar } = useSnackbar();

    const addSale = async (data) => {
        return saleService.create(data).then((res) => {
            enqueueSnackbar("New Sale Added",{variant:'success'})                 
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Sale Already Exist",{variant:'warning'});               
            }
        })
    }

    const addSaleAndPrint = async (data) => {
        return saleService.createAndGetInvoice(data).then((res) => {
            enqueueSnackbar("New Sale Added",{variant:'success'})
            printUtil.printPdf([res.data])
        }).catch((error)=>{
            if(error.response.status===409){
                enqueueSnackbar("Sale Already Exist",{variant:'warning'});               
            }
        })
    }
    
    const updateSale = async (data) => {
        return saleService.update(data).then((res) => {
            enqueueSnackbar("Sale Updated",{variant:'success'})
        }).catch((error)=>{
            if(error.response.status===404){   
                enqueueSnackbar("Data NotFound to Update",{variant:'error'});              
            }else if(error.response.status === 409){
                enqueueSnackbar("Sale Already Exist",{variant:'warning'});             
            }
            
        })
    }   

    return(
        <>
        <h2 style={{marginBottom:0,}}>Create Sale</h2>
        <span style={{fontSize:14,marginBottom:2}}>Home / Dashboard</span>
        <Card>
            <CardContent>
                <SaleForm handleAdd={addSale} handleUpdate={updateSale} row={row} handleSaveAndPrint={addSaleAndPrint} />
            </CardContent>
        </Card>
        {/* <FullScreenDialog title={modelTitle} open={openModel} close={handleCloseModel}>
                    <ProductForm handleAdd={addProduct} handleUpdate={updateProduct} row={row}></ProductForm>
        </FullScreenDialog> */}
        </>
    )
}