import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Grid, IconButton, Paper, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedProductData, batchIdUpdate, deleteSelectedProductData, discountUpdate, openCreateBatchModel, quantityUpdate } from '../../redux/reducer/purchase';
import productBatchService from '../../service/api/productBatchService';
import productService from '../../service/api/productService';
import { decimalControl, getTaxAmount } from '../../util/numericUtil';
import ItemsDataTable from "../datatable/ItemsDataTable";
import ProductBatchForm from "../forms/product/ProductBatchForm";
import Model from "../model/Model";
import FullScreenDialog from '../model/FullScreenModel';
import ProductForm from '../forms/product/ProductForm';

export default function PurchaseSelectProduct({handelOpenModel}){      
    const [productSearchData, setProductSearchData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const {selectedProductData} = useSelector((state)=>state.purchase);
    const isIgst = useSelector((state)=>state.purchase.isIgst);
    const dispatch = useDispatch();
    const columns = [
        { 
          field: "id", 
          headerName: "ID", 
          filterable: false, 
          sortable: false ,
          width:'10',
          renderCell: (index) => index.api.getRowIndexRelativeToVisibleRows(index.row.id) + 1,},
        {
          field: "name",
          headerName: "Product Name",
          editable: false,
          width: 400,
          sortable: false,
        },
        {
          field: "batchNumber",
          headerName: "Batch",
          width: 250,
          renderCell: (params) => (
            <ProductBatchAutoComplete data={params.row} />
          ),
          sortable: false,
        },
        {
          field: "quantity",
          headerName: "Quantity",
          renderCell: (params) => (
            <TextField
              type="text"
              variant="standard"
              defaultValue={params.row.quantity}
              onBlur={(e) => {
                dispatch(quantityUpdate({quantity : e.target.value ,id:params.row.id}))                
              }}
            />
          ),
          sortable: false,
        },
        {
          field: "discount",
          headerName: "Disc.",
          renderCell: (params) => (
            <TextField
              type="number"
              variant="standard"
              defaultValue={0.0}
              onBlur={(e) => {
                dispatch(discountUpdate({discount:parseFloat(e.target.value),id: params.row.id}))
              }}
            />
          ),
          sortable: false,
        },
        {
          field: "mrp",
          headerName: "MRP",
          editable: false,
          sortable: false,
        },
        {
          field: "unitPrice",
          headerName: "Unit Price",
          editable: false,
          sortable: false,
        },
        {
          field: "salePrice",
          headerName: "Sale Price",
          editable: false,
          sortable: false,
        },
        {
          field: "gst",
          headerName: "GST(%)",
          editable: false,
          sortable: false,
        },
        {
          field: "gstAmount",
          headerName: "Tax Amt.",
          editable: false,
          sortable: false,
        },
        {
          field: "taxableAmount",
          headerName: "Taxable Amt.",
          editable: false,
          sortable: false,
        },       
        {
          field: "totalAmount",
          headerName: "Total",
          editable: false,
          sortable: false,
        },
        {
          field: "action",
          headerName: "Action",
          editable: false,
          renderCell: (params) => (
            <IconButton
              sx={{ color: "red" }}
              onClick={() => {
                dispatch(deleteSelectedProductData(params.row.id));
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        },
      ];
        
      const handleProductSearchOnChange = (e)=>{            
        if(e){
          setSearchInput(e.target.value)          
          productService.search(e.target.value,'PURCHASE').then(res=>{
              setProductSearchData(res.data)
          }).catch(errors=>{
  
          })          
        }else{
          setSearchInput('')          
        }        
    }

      const handelSelectProductOnchange = (id) => {        
        setSearchInput('')
        productService.getVariantById(id).then(res=>{
          const data = res?.data;
          if (data) {
            const totalUnitPrice = data.productVariants.unitPrice        
            const gstAmount = getTaxAmount(data.gstType, data.gst.percentage, totalUnitPrice);
            dispatch(addSelectedProductData({ 
              id:window.crypto.randomUUID(),
              variantId: data.productVariants.id,
              batchId: 0,
              name: data.name + " " + data.productVariants.name,
              quantity: 1,
              mrp:data.productVariants.mrp,
              salePrice:data.productVariants.salePrice,
              unitPrice:data.productVariants.unitPrice,
              gst: data.gst.percentage,
              gstType: data.gstType,
              gstAmount: decimalControl(gstAmount),
              igst: isIgst ? decimalControl(gstAmount) : 0.00,
              sgst: !isIgst ? decimalControl(gstAmount/2) : 0.00,
              cgst: !isIgst ? decimalControl(gstAmount/2) : 0.00,
              taxableAmount: decimalControl(data.gstType === 'INCLUSIVE' ? (totalUnitPrice - gstAmount) : totalUnitPrice),
              totalAmount: decimalControl(data.gstType === 'INCLUSIVE' ? totalUnitPrice  : (totalUnitPrice + gstAmount)),
              discount: 0.0,
              discountAmount: 0.0
            }))
            setProductSearchData([])
          }
        })

      };

      // This is for Add New Product Button Inside the AutoComplete dropdown
      const PaperComponentCustom = options => {
        const { containerProps, children } = options;
        return (
          <Paper  {...containerProps}>
            {children}            
              <Button
                fullWidth  
                startIcon={<AddCircleOutlineIcon />}
                onMouseDown={(event)=>{event.preventDefault(); handelOpenModel()}}
                >
                Add New Product
              </Button>
             
          </Paper> 
          );
        };

    return (
      <>
      <Grid container spacing={2} padding={0}>
        <Grid item xs={7}>
          <div style={{display:'flex'}}>
          <Autocomplete    
            PaperComponent={PaperComponentCustom}
            value={null}
            fullWidth
            options={productSearchData}          
            size="small"
            onChange={(e, value) => {
              handelSelectProductOnchange(value.id);
            }}
            inputValue={searchInput}
            getOptionLabel={(option) => option.name }
            onInputChange={handleProductSearchOnChange}
            renderInput={(params) => (
              <TextField {...params} label="Search Product" />
            )}
          />
          {/* <ProductSearch options={productSearchData}  handleProductSearchOnChange={handleProductSearchOnChange}/> */}

          {/* <IconButton color="primary" onClick={handelOpenModel} sx={{padding:0}} aria-label="add to shopping cart">
            <AddCircleOutlineIcon fontSize='large' />
          </IconButton> */}
          </div>
        </Grid>        
        <Grid item xs={12}>
          <ItemsDataTable columns={columns} rows={selectedProductData} />
        </Grid>
      </Grid>
    </>
    );
}

const ProductBatchAutoComplete = (props) => {
    const { data } = props;
    const {createBatch} = useSelector((state)=>state.purchase);
    const dispatch = useDispatch();
    const [productBatchData, setProductBatchData] = useState([]);
  
    useEffect(() => {    
      getBatchByVariant(data.variantId);
    }, [createBatch.variantId]);
  
    const getBatchByVariant = (id) => {
      productBatchService.getByVariant(id).then((res) => {
          setProductBatchData(res.data);
        }).catch((error) => {
          setProductBatchData([]);
        });
    };
  
    return (
      <>
        <Autocomplete          
          fullWidth
          options={productBatchData}
          noOptionsText={
            <Button variant="text" onClick={()=>{
              dispatch(openCreateBatchModel(data.variantId))
            }}>
              <AddCircleOutlineIcon /> Create New Batch
            </Button>
          }
          onChange={(e,value)=>{
            dispatch(batchIdUpdate({batchId:value?.id, id:data.id}))            
          }}
          size="small"
          getOptionLabel={(option) => option.batchNumber}
          renderInput={(params) => <TextField {...params} />}
        />        
      </>
    );  
};