import axios from '../../config/axiosConfig'

class inventoryService {

    getAllProductStock(page,size) {
        return axios.get(`/v1/inventory/get-product-stock?page=${page}&size=${size}`);
    }   

    addBulkStock(data){
        return axios.post('/v1/inventory/add-bulk-stock',data)
    }
}

export default new inventoryService();