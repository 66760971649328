import { TextField } from "@mui/material";
import moment from "moment-timezone";
import { Controller } from "react-hook-form";

export default function DateTimePicker({name,label,error,size,control,defaultValue,required,errorMessage=" "}){   
    return(      
        <Controller
        name={name}
        defaultValue={defaultValue ? defaultValue : ''}
        control={control}
        rules={{required}}
        render={({field: { onChange,  ...field}}) => (
          <TextField
            type="datetime-local"             
            value={(field.value ? moment(field.value).format('YYYY-MM-DDTHH:mm') : null)??null}
            InputLabelProps={{ shrink: true}}
            size={size}
            error={error}
            helperText={error?errorMessage : " "} FormHelperTextProps={{sx:{margin:0,marginLeft:'2px'}}}
            onChange={(e)=>{
              onChange(moment(e.target.value).format('YYYY-MM-DDTHH:mm:ssZ'))   
            }}
            onBlur={(e)=>{
              onChange(moment(e.target.value).format('YYYY-MM-DDTHH:mm:ssZ'))              
            }}            
            fullWidth
            label={label}
            variant="outlined"
          />
        )}
      />

    )
}